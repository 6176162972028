import { createFeatureSelector, createSelector } from '@ngrx/store';

import { EReducers } from '../app.state';
import { IStaffState } from './staff.state';

export const recruiterSelectorFeature = createFeatureSelector<IStaffState>(
  EReducers[EReducers.STAFF]
);

const staffAssociateSessionSelect = createSelector(
  recruiterSelectorFeature,
  (state: IStaffState) => state.staffAssociateSession
);

export const staffQuery = {
  staffAssociateSessionSelect,
};
