import { initialUserState, IUserState } from './users.state';
import { UserActions, EUserActions } from './users.actions';

export const UserReducers = (
  state: IUserState = initialUserState,
  action: UserActions
): IUserState => {
  switch (action.type) {
    case EUserActions.AddUserSuccess: {
      return {
        ...state,
        user: action.user
      };
    }

    case EUserActions.LoginSuccess: {
      return {
        ...state,
        user: action.user
      };
    }

    case EUserActions.LoggedOut: {
      // const role = localStorage.getItem('loginRole');
      localStorage.clear();
      // localStorage.setItem('loginRole', role);
      return state = {
        role: 'STUDENT',
        user: null,
        loggedInUser: null
      };
    }

    case EUserActions.LoggedInUser: {
      return {
        ...state,
        loggedInUser: action.loggedInUser
      };
    }

    case EUserActions.ChangeRole: {
      return {
        ...state,
        role: action.role
      };
    }

    default:
      return state;
  }
};
