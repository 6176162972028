import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader-full',
  template: `
  <div class="progress-loader" *ngIf="show">
    <div class="loading-spinner">
        <mat-spinner></mat-spinner>
        <span class="loading-message">Please wait...</span>
    </div>
  </div>

  <div *ngIf="spinnerShow">
  <div class="loading-spinner">
      <div *ngIf="spinnerShow" class="spinner-loader">Loading...</div>
  </div>
</div>
  `,
  styleUrls: ['./loader.component.scss']
})
export class LoaderFullComponent implements OnInit, OnDestroy {
  public show = false;
  public spinnerShow = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.loaderService.loaderFullState
        .subscribe((state) => {
          this.show = state;

          setTimeout(() => {
            this.show = false;
          }, 60000);
        }),
      this.loaderService.spinnerFullState
        .subscribe((state) => {
          this.spinnerShow = state;

          setTimeout(() => {
            this.spinnerShow = false;
          }, 60000);
        }),
    );
  }

  ngOnDestroy() {
    for (const i of this.subscriptions) {
      i.unsubscribe();
    }
  }
}
