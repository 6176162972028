<mat-form-field appearance="outline" class="w-100" *ngIf="requiredMessage !== 'null'">
    <input matInput type="text" [formControl]="inputControl" [matDatepicker]="dateOfBirthPicker"
        [placeholder]="placeholder" (focus)="dateOfBirthPicker.open()" [max]="testDateMaxDate"
        [min]="testDateMinDate" />
    <mat-datepicker-toggle matSuffix [for]="dateOfBirthPicker"></mat-datepicker-toggle>
    <mat-datepicker #dateOfBirthPicker startView="multi-year"
        (monthSelected)="setMonthAndYear($event, dateOfBirthPicker);dateOfBirthPicker.close();"
        panelClass="example-month-picker">
    </mat-datepicker>
    <mat-error *ngIf="!inputControl.valid && inputControl.errors?.required">
        {{requiredMessage}}
    </mat-error>
</mat-form-field>

<mat-form-field appearance="outline" class="w-100" *ngIf="requiredMessage === 'null'">
    <input matInput type="text" [formControl]="inputControl" [matDatepicker]="dateOfBirthPicker"
        [placeholder]="placeholder" (focus)="dateOfBirthPicker.open()" [max]="testDateMaxDate"
        [min]="testDateMinDate" />
    <mat-datepicker-toggle matSuffix [for]="dateOfBirthPicker"></mat-datepicker-toggle>
    <mat-datepicker #dateOfBirthPicker startView="multi-year"
        (monthSelected)="setMonthAndYear($event, dateOfBirthPicker);dateOfBirthPicker.close();"
        panelClass="example-month-picker">
    </mat-datepicker>
    <mat-error *ngIf="!inputControl.valid && inputControl.errors?.required">
        {{requiredMessage}}
    </mat-error>
</mat-form-field>
<!-- <mat-form-field appearance="outline" class="w-100">
    <input matInput type="text" [formControl]="inputControl" [matDatepicker]="dateOfBirthPicker"
        [placeholder]="placeholder" (focus)="dateOfBirthPicker.open()" [max]="testDateMaxDate" [min]="testDateMinDate" />
    <mat-datepicker-toggle matSuffix [for]="dateOfBirthPicker"></mat-datepicker-toggle>
    <mat-datepicker #dateOfBirthPicker startView="multi-year"
        (monthSelected)="setMonthAndYear($event, dateOfBirthPicker);dateOfBirthPicker.close();" panelClass="example-month-picker">
    </mat-datepicker>
    <mat-error *ngIf="!inputControl.valid && inputControl.errors?.required">
        {{requiredMessage}}
    </mat-error>
</mat-form-field> -->