import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './accordion';
import { DateFormatDirective, YearMonthFormatDirective } from './directives/date.directive';
import { AngularMaterialModule } from './modules/angular-material.module';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { MenuItems } from './models/menu-items';
import { CreateStudentComponent } from './components/create-student/create-student.component';
import { AddCustomDocComponent } from './components/add-custom-doc/add-custom-doc.component';
import { AutoCompleteCountryCodeComponent } from './components/auto-complete-country-code/auto-complete-country-code.component';
import { AutoCompleteCountryComponent } from './components/auto-complete-country/auto-complete-country.component';
import { AutoCompleteProgramComponent } from './components/auto-complete-program/auto-complete-program.component';
import { AutoCompleteUniversityComponent } from './components/auto-complete-university/auto-complete-university.component';
import { AutoCompleteTermsComponent } from './components/auto-complete-terms/auto-complete-terms.component';
import { MmyyyydateformatComponent } from './components/mmyyyydateformat/mmyyyydateformat.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SimpleNotificationsComponent } from './components/simple-notifications/simple-notifications.component';

@NgModule({
  declarations: [
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective,
  DateFormatDirective,
  YearMonthFormatDirective,
  OnlyNumberDirective,
  CreateStudentComponent,
  AddCustomDocComponent,
  AutoCompleteCountryCodeComponent,
  AutoCompleteCountryComponent,
  AutoCompleteProgramComponent,
  AutoCompleteUniversityComponent,
  AutoCompleteTermsComponent,
  MmyyyydateformatComponent,
  NotificationsComponent,
  SimpleNotificationsComponent,
  ],
  imports: [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  AngularMaterialModule,
  MatFormFieldModule,
  MatInputModule
  ],
  exports: [
  CommonModule,
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective,
  DateFormatDirective,
  YearMonthFormatDirective,
  OnlyNumberDirective,
  RouterModule,
  ReactiveFormsModule,
  FormsModule,
  FlexLayoutModule,
  AngularMaterialModule,
  AddCustomDocComponent,
  AutoCompleteCountryCodeComponent,
  AutoCompleteCountryComponent,
  AutoCompleteProgramComponent,
  AutoCompleteUniversityComponent,
  AutoCompleteTermsComponent,
  MmyyyydateformatComponent,
  NotificationsComponent,
  SimpleNotificationsComponent
  ],
  providers: [MenuItems]
  })
export class SharedModule { }
