import { RouterReducerState } from '@ngrx/router-store';

import { IUserState, initialUserState } from './users/users.state';
import { IRecruiterState } from './recruiter/recruiter.state';
import { IStudentManagementState } from './student-management/student-management.selectors';
import { IStaffState } from './staff/staff.state';

export interface IAppState {
  ROUTER?: RouterReducerState;
  USER: IUserState;
  RECRUITER?: IRecruiterState;
  STUDENT_MANAGEMENT?: IStudentManagementState;
  STAFF?: IStaffState;
}

export const initialAppState: IAppState = {
  USER: initialUserState,
};

export function getInitialState(): IAppState {
  return initialAppState;
}

export enum EReducers{
  ROUTER,
  USER,
  RECRUITER,
  STUDENT_MANAGEMENT,
  STAFF
}
