import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { IStaffSession, EStaffEndPoint } from '../model/staff.model';
import { staffQuery } from './staff.selectors';
import { ILogin } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  public baseUrl = environment.baseUrl;
  public staffLoginDetail: IStaffSession;

  constructor(
    private http: HttpClient,
    readonly store: Store
  ) {
    store.pipe(select(staffQuery.staffAssociateSessionSelect)).subscribe((staff: IStaffSession) => {
      this.staffLoginDetail = staff;
    });
  }

  public loginStaffAssociateFromService(requestBody: ILogin): Observable<IStaffSession> {
    const url: string = this.baseUrl + EStaffEndPoint.LOGIN_STAFF_ASSOCIATE;
    return this.http.post<IStaffSession>(url, requestBody);
  }
}
