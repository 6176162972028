import { Component, OnInit } from '@angular/core';
import {
  FormBuilder, FormControl, FormGroup, Validators
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StudentManagementService } from 'src/app/store/student-management/student-management.service';
import { EmailIdValidator, EmailIdValidatorFailure, EUserActions } from 'src/app/store/users/users.actions';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NoticeComponent } from '../notice/notice.component';
import { SharedService } from '../../services/shared.service';
import { REGEX } from '../../helpers/form.validator';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-create-student',
  templateUrl: './create-student.component.html',
  styleUrls: ['./create-student.component.scss']
  })
export class CreateStudentComponent implements OnInit {
  public form: FormGroup;
  public university: string;
  public requiredfieldApplyAs: boolean;
  public requiredfieldApplyFor: boolean;
  public ifFromUs : any;
  public applyAsStudent=['High School Graduate Student', 'Undergraduate Student', 'Graduate Student', 'Others'];
  public fromUsYesApplyFor=['Undergrad Transfer Program', 'Graduate Transfer Program', 'Second Masters', 'Others'];
  public fromUsNoApplyFor=['International Program', 'Others'];
  public UniversityListArray:any = [];
  public intakeListArray:any = [];
  public phoneCountryCode = [];
  public transferDobMaxDate = moment().subtract(11, 'years').format();
  public DobMaxDate = moment().subtract(16, 'years').format();
  options: any[] = [];
  filteredJSONDataOptionsphoneCountryCode: Observable<any[]>;
  filteredJSONDataOptionsemergencyPhoneCountryCode: Observable<any[]>;
  constructor(
    private fb: FormBuilder,
    private store: Store,
    private actions: Actions,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateStudentComponent>,
    private studentManagementService: StudentManagementService,
    private sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    this.university = localStorage.getItem('university') || 'HUB';

    this.form = this.fb.group({
      emailId: [null, Validators.compose([
        Validators.required,
        Validators.pattern(REGEX.EMAIL),
      ])],
      firstName: new FormControl('', [Validators.required, Validators.pattern(REGEX.CHARACTER)]),
      lastName: new FormControl('', [Validators.required, Validators.pattern(REGEX.CHARACTER)]),
      fromUS: new FormControl(true),
      ApplyingAs: new FormControl('', Validators.required),
      othersApplyingAs: new FormControl('', Validators.required),
      ApplyingFor: new FormControl('', Validators.required),
      othersApplyingFor: new FormControl('', Validators.required),
      phoneCountryCode: new FormControl('', Validators.required),
      phoneWithoutCode: new FormControl('', Validators.required),
      emergencyPhoneCountryCode: new FormControl(''),
      emergencyPhoneWithoutCode: new FormControl(''),
      intake: new FormControl(''),
      uniApplyingFor: new FormControl(''),
      dateOfBirth: new FormControl(''),
    });

    this.sharedService.getPhoneCountryCodeFromService().subscribe(response => {
      setTimeout(() => {
        for (let i = 0; i < response.length; i++) {
          const c = response[i];
          this.phoneCountryCode.push({
            name: c[0],
            iso2: c[1],
            id: c[2],
            priority: c[3] || 0,
            areaCodes: c[4] || null
          });
        }
      });
    }),
    // Email Id Validator Response
    this.actions.pipe(ofType(EUserActions.EmailIdValidatorFailure))
      .subscribe(({ error }: EmailIdValidatorFailure) => {
        this.openNoticeErrorMessageDialog(error);
        // this.responseMessage.message = error;
        // this.responseMessage.color = 'red';
      });
    this.getUniversityList();

    this.filteredJSONDataOptionsphoneCountryCode = this.form.get('phoneCountryCode').valueChanges.pipe(
      startWith(''),
      map(value => this.json_data_filter(value))
    );
    this.filteredJSONDataOptionsemergencyPhoneCountryCode = this.form.get('emergencyPhoneCountryCode').valueChanges.pipe(
      startWith(''),
      map(value => this.json_data_filter2(value))
    );
  }

  // applyingFor selection changes

  public onSelectionChange() {
    const applyAs = this.form.controls.ApplyingAs.value;
    const applyFor = this.form.controls.ApplyingFor.value;
    if (applyAs === 'Others') {
      this.requiredfieldApplyAs = true;
      this.form.controls.othersApplyingAs.setValidators([Validators.required]);
    } else {
      this.requiredfieldApplyAs = false;
      this.form.controls.othersApplyingAs.clearValidators();
      this.form.controls.othersApplyingAs.reset();
    }

    if (applyFor === 'Others') {
      this.requiredfieldApplyFor = true;
      this.form.controls.othersApplyingFor.setValidators([Validators.required]);
    } else {
      this.requiredfieldApplyFor = false;
      this.form.controls.othersApplyingFor.clearValidators();
      this.form.controls.othersApplyingFor.reset();
    }
  }

  private openNoticeErrorMessageDialog(error) {
    this.dialog.closeAll();
    this.dialog.open(NoticeComponent, {
      minWidth: '400px',
      maxWidth: '450px',
      // height: '250px',
      data: {
        header: error,
        message: null,
        type: 4
      },
      disableClose: false,
    });
  }
  // fromUs selection changes
  onfromUsSelectionChange() {
    this.form.controls.ApplyingFor.reset();
  }

  public onChangeFromUs() {
    this.ifFromUs = this.form.controls.fromUS.value;
  }

  validateEmailId(emailId) {
    this.store.dispatch(new EmailIdValidator({ emailId }));
  }
  onSubmit() {
    this.form.controls.phoneCountryCode.setValue(this.form.value.phoneCountryCode.replace('+', ''));
    this.form.controls.emergencyPhoneCountryCode.setValue(this.form.value.emergencyPhoneCountryCode.replace('+', ''));
    this.dialogRef.close(this.form.value);
  }
  getUniversityList() {
    this.studentManagementService.getUniversityListFromCRM().subscribe((Data:any) => {
      if (Data) {
        this.UniversityListArray = Data.universities.sort((a, b) => a.university.localeCompare(b.university));
      }
    });
  }
  onSelectedUniversity(Data:any) {
    console.log(this.form.value);

    if (Data.includes('University of the Cumberlands') || Data.includes('Alderson Broaddus University') || Data.includes('Campbellsville University')) {
      this.intakeListArray = ['Spring-1', 'Spring-2', 'Summer-1', 'Summer-2', 'Fall-1', 'Fall-2'];
    } else if (Data.includes('Judson University') || Data.includes('Faulkner University')) {
      this.intakeListArray = ['Spring', 'Summer', 'Fall'];
    } else if (Data.includes('St Francis College')) {
      this.intakeListArray = ['Spring-1', 'Spring-2', 'Summer', 'Fall-1', 'Fall-2'];
    } else {
      this.intakeListArray = ['Spring', 'Spring-1', 'Spring-2', 'Summer', 'Summer-1', 'Summer-2', 'Fall', 'Fall-1', 'Fall-2'];
    }
  }
  // JSON Data Filter
  private json_data_filter(value: string): string[] {
    const newList = [];
    this.phoneCountryCode.forEach(element => {
      if (element.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
        newList.push(element);
      }
    });
    return newList;
  }
  private json_data_filter2(value: string): string[] {
    const newList = [];
    this.phoneCountryCode.forEach(element => {
      if (element.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
        newList.push(element);
      }
    });
    return newList;
  }
  public onSelectState(type: any, value: string): void {
    this.form.get(type).patchValue(value);
    console.log('🚀 -----------------------------------------------------------------------------------------------------------🚀');
    console.log('🚀 ~ file: create-student.component.ts:194 ~ CreateStudentComponent ~ onSelectState ~ this.form:', this.form.value);
    console.log('🚀 -----------------------------------------------------------------------------------------------------------🚀');
  }
  // onKey(value) {
  //   this.selectedStates = this.search(value);
  //   }
}
