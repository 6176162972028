<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100%" fxFlex="100">

    <div fxLayoutAlign="space-between">
      <h2 class="m-b-0">Have questions?</h2>
      <button mat-mini-fab class="bg-danger text-white" (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <p>Please fill out form below and an admissions counselor will get back to you with an answer within 24 hours.
    </p>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div fxLayout="row wrap" fxFlexAlign="center" class="row">
        <div fxFlex="100" class="p-10">
          <mat-form-field>
            <mat-select placeholder="Query Regarding" formControlName="query" required>
              <mat-option value="Universities">Universities</mat-option>
              <mat-option value="Courses">Courses</mat-option>
              <mat-option value="Admissions">Admissions</mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls['query'].hasError('required') && form.controls['query'].touched">
              Please select your query
            </mat-error>
          </mat-form-field>
        </div>

        <!-- <div fxFlex.gt-sm="35" fxFlex="100" class="p-10">
          <mat-form-field>
            <mat-select placeholder="Location" formControlName="location" required>
              <mat-option value="Campbellsville">Campbellsville</mat-option>
              <mat-option value="MaryLand">MaryLand</mat-option>
              <mat-option value="Kentucky">Kentucky</mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls['location'].hasError('required') && form.controls['location'].touched">
              Please select your location
            </mat-error>
          </mat-form-field>
        </div> -->

        <!-- <div fxFlex.gt-sm="33.33" fxFlex="100" class="p-10">
                    <mat-form-field>
                        <input matInput placeholder="First Name" formControlName="firstname" required>
                    </mat-form-field>
                </div>
                <div fxFlex.gt-sm="33.33" fxFlex="100" class="p-10">
                        <mat-form-field>
                        <input matInput placeholder="Last Name" formControlName="lastname" required>
                    </mat-form-field>
                </div>

                <div fxFlex.gt-sm="33.33" fxFlex="100" class="p-10">   
                
                    <mat-form-field>
                    <input matInput placeholder="Email" formControlName="email" required>
                    </mat-form-field>
                </div> -->

        <div fxFlex="100" class="p-10">
          <mat-form-field>
            <mat-label>What can we help you with</mat-label>
            <textarea matInput formControlName="help" required></textarea>
            <mat-error *ngIf="form.controls['help'].hasError('required') && form.controls['help'].touched">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div style="margin-top: 20px;">
        <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid">Submit</button>
      </div>
    </form>
  </div>
</div>