<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container" [dir]="dir" [ngClass]="{
    'maroon': true,
    'minisidebar': minisidebar, 'boxed': boxed
  }">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <mat-toolbar color="primary" class="topbar telative">
    <!-- ============================================================== -->
    <!-- Logo - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="navbar-header">
      <a class="navbar-brand">
        <!-- Logo icon -->
        <b>
          <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
          <!-- Dark Logo icon -->
          <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo">
          <!-- Light Logo icon -->
          <!-- <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo"> -->

          <!-- <img src="assets/images/univ-hub-logo.png" alt="homepage" class="light-logo app-logo"> -->
          <!-- <img [src]="(university !== 'UWLA') ? 'assets/images/univ-hub-logo-only.png' : 'assets/images/uwla.png'"
            alt="homepage" class="light-logo app-logo"> -->
            <img [src]="(university !== 'UWLA') ? 'assets/images/uh-small-logo.png' : 'assets/images/uwla.png'"
            alt="homepage" class="light-logo app-logo" style="margin-top: 5px;height:auto">
        </b>
        <!--End Logo icon --> 
        <!-- Logo text -->
        <!-- fxShow="false" fxShow.gt-xs -->
        <span fxShow="false" fxShow.gt-xs>
          <!-- dark Logo text -->
          <!-- <span class="dark-logo">POC</span> -->
          <!-- Light Logo text -->
          <!-- <span *ngIf="university !== 'UWLA'" style="color: white; padding-left: 2px;">University HUB</span> -->
        </span>
      </a>
    </div>
    <!-- ============================================================== -->
    <!-- sidebar toggle -->
    <!-- ============================================================== -->
    <!-- <img fxFlex src='../../../assets/images/forward-arrow.png' alt=""> -->
    <div style="width: 24px;height: 24px;cursor: pointer;padding-left:10px;line-height: 38px;" (click)="snav.toggle() && clickEvent()"
    [style.paddingLeft.px]="mobileQuery.matches ? 0 : 10">
      <mat-icon *ngIf="!status" class="icon-font-large">arrow_back_ios</mat-icon>
      <mat-icon *ngIf="status" class="icon-font-large">arrow_forward_ios</mat-icon>
      <mat-icon *ngIf="!status" class="expand-mat-icon icon-font-large">arrow_back_ios</mat-icon>
      <mat-icon *ngIf="status" class="expand-mat-icon icon-font-large">arrow_forward_ios</mat-icon>
      <!-- <img *ngIf="status" fxFlex src='../../../assets/images/forward-arrow.png' alt="">
      <img *ngIf="!status" fxFlex src='../../../assets/images/backward-arrow.png' alt=""> -->
    </div>
    <div>
      <span>
        <span style="display: flex; align-items: center;" class="role-section">{{role | titlecase}}</span>
      </span>
    </div>
    <!-- <button mat-icon-button (click)="snav.toggle() && clickEvent()" value="sidebarclosed">
      <mat-icon>menu</mat-icon>
    </button> -->
    <!-- ============================================================== -->
    <!-- Search - style you can find in header.scss -->
    <!-- ============================================================== -->
    <!-- <button mat-icon-button class="srh-btn" (click)="showSearch = !showSearch">
      <mat-icon>search</mat-icon>
    </button> 
    <form class="app-search" [ngClass]="{'show-search': showSearch}">
      <input type="text" class="form-control" placeholder="Search &amp; enter">
      <a class="cl-srh-btn" (click)="showSearch = !showSearch">
        <i class="ti-close"></i>
      </a>
    </form>-->
    <span fxFlex></span>
    <!-- ============================================================== -->
    <!-- app header component - style you can find in header.scss / header.component.ts-->
    <!-- ============================================================== -->
    <app-header></app-header>
    <!-- ============================================================== -->
    <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->
    <!-- <button (click)="end.toggle()" mat-icon-button class="ml-xs overflow-visible">
      <mat-icon>settings</mat-icon>
    </button> -->
  </mat-toolbar>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    [ngClass]="{'minisidebar': minisidebar}">
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #snav id="snav" class="pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0"
      [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true"
      (close)="sidebarOpened = false">

      <app-sidebar class="app-sidebar"></app-sidebar>

      <div id="contact-sidenav" class="outline">
        <div fxLayout="row" fxLayoutGap="5px">
          <mat-icon matTooltip="Help">help</mat-icon>
          <div>
            <p>For Any Queries/Issues, <br> Please Contact Us At</p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="5px">
          <mat-icon matTooltip="Email">mail</mat-icon>
          <div>
            <p><b>{{ (university === 'UWLA') ? 'apalla@uwla.edu' : 'help@universityhub.com' }}</b></p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="5px">
          <mat-icon matTooltip="Phone">local_phone</mat-icon>
          <div>
            <p><b>(US) +1 (917) -900-1655</b></p>
          </div>

        </div>
        <!-- <p>India : +91 86868 10001</p> -->
      </div>
    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
      <div class="scroll">
        <mat-nav-list>

          <h3 mat-subheader class="text-uppercase font-weight-bold">Settings</h3>
          <mat-list-item>
            <mat-slide-toggle color="warn" (change)="dir = (dir == 'rtl' ? 'ltr' : 'rtl')">RTL</mat-slide-toggle>
          </mat-list-item>
          <mat-list-item>
            <mat-slide-toggle color="warn" [(ngModel)]="boxed">Boxed Layout</mat-slide-toggle>
          </mat-list-item>
          <!--<mat-list-item>
                    <mat-slide-toggle [(ngModel)]="minisidebar">Mini Sidebar</mat-slide-toggle>
                  </mat-list-item>    -->
          <mat-divider></mat-divider>
          <h3 mat-subheader class="text-uppercase font-weight-bold">Colors</h3>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="danger" (change)="green = blue = false" class="text-danger">Red
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="green" (change)="danger = blue = false" class="text-megna">Teal
              Green</mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="blue" (change)="green = danger = false" class="text-info">Blue
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="dark" (change)="green = blue = danger = false">Dark</mat-checkbox>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </mat-sidenav>

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''">

      <!-- <app-breadcrumb></app-breadcrumb> -->
      <div class="page-content">
        <router-outlet>
          <app-spinner></app-spinner>
        </router-outlet>
      </div>

    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>