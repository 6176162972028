import { ERole } from '../../store/model/user.model';

/* --------- Recruited Dashboard - Student Management - Apply Course -------- */

export const universitiesModel = [
  {
    universityName: 'UWLA',
    formCode: '200',
    courses: [
      {
        courseName: 'Regional Center Graduate (MSITM)',
        courseCode: 'MSITM'
      }
    ],
    applicationStatus: '',
    paymentStatus: '',
    showButtonText: 'Apply'
  },
  {
    universityName: 'CU',
    formCode: '210',
    courses: [
      {
        courseName: 'Computer Science',
        courseCode: 'CSC'
      }
    ],
    applicationStatus: '',
    paymentStatus: '',
    showButtonText: 'Apply'
  },
  {
    universityName: 'NPU',
    formCode: '220',
    courses: [
      {
        courseName: 'MBA',
        courseCode: 'MBA'
      }
    ],
    applicationStatus: '',
    paymentStatus: '',
    showButtonText: 'Apply'
  },
];

/* ------------------------------ Notice Dialog ----------------------------- */

export interface INotice {
  header: string;
  message: string,
  type: number;
  routerLink?: string;
}

export interface INoticeIcon {
  icon: string;
  color: string;
}

export interface IConfirmDialog {
  title: string;
  message: string;
  confirmButtonText: string;
  cancelButtonText: string;
  type: number;
}

export const noticeIcon: INoticeIcon[] = [
  {
    icon: 'info',
    color: '#0759de'
  },
  {
    icon: 'check_circle',
    color: '#3de300'
  },
  {
    icon: 'delete',
    color: '#ff0303'
  },
  {
    icon: 'warning',
    color: '#ff8103'
  },
  {
    icon: 'warning',
    color: 'red'
  }
];

/* ------------------------ Invite/Edit Student/Staff ------------------------ */
export interface IInvite {
  title: string;
  role?: string;
  phone?: string;
  isPhoneNeed?: boolean;
  isEdit?: boolean;
  firstName?: string;
  lastName?: string;
  emailId?: string;
}

/* ------------------------- Student Document Upload ------------------------ */
export interface IStudentDocument {
  formData: any,
  courseName: string;
  id: string;
  reupload: boolean;
}

/* ----------------------------- Recruiter Roles ---------------------------- */
export const recruiterRoles = [
  ERole[ERole.RECRUITER_FREELANCER],
  ERole[ERole.RECRUITER_MASTER],
  ERole[ERole.RECRUITER_ORGANIZATION],
  ERole[ERole.ASSOCIATE]
];
