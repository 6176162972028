import {
  Component, HostListener, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
import {
  map, startWith
} from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { FilterDialogBoxComponent } from 'src/app/shared/filter-dialog-box/filter-dialog-box.component';
import {
  GetAppliedApplications,
  GetExploreUniversity,
  GetSaveUniversityApplications,
  GetSaveUniversityApplicationsSuccess,
  GetShortlistedApplications,
  GetShortlistedApplicationsSuccess,
  GetStudentLoginDetail,
  GetStudentProfile,
  GetStudentSavedFavoriteUniversityList,
  SaveStudentFavoriteUniversityList,
  StudentDocuments
} from '../../../store/student-management/student-management.actions';
import { studentManagementQuery } from '../../../store/student-management/student-management.selectors';
import { userQuery } from '../../../store/users/users.selectors';
import { SharedService } from '../../../shared/services/shared.service';
import { FileUploadService } from '../../../shared/services/file-upload.service';
import { IUser } from '../../../store/model/user.model';
import { UniversityDetailsComponent } from './university-details/university-details.component';
import { IDocument, statusArrayFake } from '../student-application/student-application.model';

@Component({
  selector: 'app-explore-university',
  templateUrl: './explore-university.component.html',
  styleUrls: ['./explore-university.component.scss']
  })
export class ExploreUniversityComponent implements OnInit, OnDestroy {
  @ViewChild('selectCountry') selectCountry: MatSelect;
  @ViewChild('selectCourse') selectCourse: MatSelect;

  panelOpenState = false;
  courseAllSelected=false;
  countryAllSelected=false;
  public searchValue = [];
  public role : any;

  public universityPreferenceForm: FormGroup;
  private subscriptions: Subscription[] = [];
  public countryAutocompleteObservable: Observable<any[]>;
  public degreeAutocompleteObservable: Observable<any[]>;
  public courseAutocompleteObservable: Observable<any[]>;
  public englishProficiencyObservable: Observable<any[]>;
  public intakeObservable: Observable<any[]>;
  public universityData = [];
  public copyUniversityData = [];
  public universityCourseData = [];
  public countryBasedCourseData = [];

  public countryData = [];
  public degreeData = [];
  public courseData = [];
  public applicationFeesData = [];
  public tuitionFeesData = [];
  public livingCostData = [];
  public minimumGPAData = [];
  public englishProficiencyData = [];
  public intakeData = [];
  public filteredUniversity = [];
  public finalData = [];
  public FeesDataFiltered = [];
  public tuitionDataFiltered = [];
  public livingCostDataFiltered = [];
  public minimumGPADataFiltered = [];
  public FeesMultiSelect = [];
  public intakeFiltered=[];

  public isProfileCompleted:boolean;
  public user: IUser;
  public isDocumentUploadCompleted: boolean;
  public courseArray: IDocument[] = [];
  public isSeperateExploreUniversities:boolean;
  public innerWidth: number;
  public isImageLoading: Array<boolean> = [];

  public isMoreFilter = true;
  public greGmatRequirement: string;
  public isStemProgram: string;
  public isModeOfLearning: string;
  public isNoOfCampus: string;
  public favUniversityLists: Array<any> = [];
  public isSearchResult = false;
  public isFavoriteResult = false;
  public intakeLists = ['Spring', 'Summer', 'Fall'];

  constructor(
    private dialog: MatDialog,
    private store: Store,
    private sharedService: SharedService,
    private fileUploadService: FileUploadService,
    private http: HttpClient,
    private titleService: Title,
    private metaService: Meta,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.titleService.setTitle('Explore University');
    this.metaService.addTags([
      { name: 'keywords', content: 'University Hub App, Explore University, Explore Institutes' },
      { name: 'description', content: 'University Hub App Explore University' },
      { name: 'robots', content: 'index, follow' }
    ]);
    this.initForm();
    if (localStorage.getItem('recruiter-student-user')) {
      this.user = JSON.parse(localStorage.getItem('recruiter-student-user'));
      this.store.dispatch(new GetExploreUniversity(this.user?.salesforceId));
    } else {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.store.dispatch(new GetExploreUniversity(this.user?.salesforceId));
    }

    this.isSeperateExploreUniversities = !!localStorage.getItem('isSeperateExploreUniversities') || !!localStorage.getItem('isRecruiterExploreUniversities');
    setTimeout(() => {
      if (!localStorage.getItem('isSeperateExploreUniversities') && !localStorage.getItem('isRecruiterExploreUniversities')) {
        this.subscriptions.push(
          this.store.pipe(select(studentManagementQuery.exploreUniversity)).subscribe(data => {
            if (data) {
              this.universityData = data;
              this.copyUniversityData = data;
              this.universityCourseData = _.flatten(data.map(temp => temp.courseList));
              this.getUniqueDataFunction();
            }
          }),
          this.store.pipe(select(userQuery.userSelect)).subscribe((user) => {
            if (localStorage.getItem('recruiter-student-user')) {
              if (user) {
                this.user = user;
                this.isProfileCompleted = user.isProfileCompleted;
              } else {
                this.user = JSON.parse(localStorage.getItem('recruiter-student-user'));
              }
            } else {
              this.user = user;
              this.isProfileCompleted = user.isProfileCompleted;
              this.loadStudentDocuments();
              // this.sharedService.loadStudentDocuments();
            }
            this.store.dispatch(new GetStudentProfile(this.user?.salesforceId));
          }),
          this.store.pipe(select(studentManagementQuery.studentDocuments)).subscribe((documents) => {
            if (documents) {
              this.courseArray = _.cloneDeep(documents);
            }
          }),
          this.store.pipe(select(studentManagementQuery.studentProfile))
            .subscribe((data: any) => {
              if (data) {
                if (data.englishexamType === 'IELTS' || data.greExamDate || data.gmatExamDate) {
                  this.courseArray.forEach((temp, index) => {
                    if (temp.courseName.includes('IELTS')) {
                      this.courseArray[index].required = true;
                    }
                    if ((data.greExamDate || data.gmatExamDate) && (temp.courseName.includes('GMAT') || temp.courseName.includes('GRE'))) {
                      this.courseArray[index].required = true;
                    }
                  });
                } else {
                  this.courseArray.forEach((temp, index) => {
                    if (temp.courseName.includes('IELTS')) {
                      this.courseArray[index].required = false;
                    } else if (temp.courseName.includes('GMAT') || temp.courseName.includes('GRE')) {
                      this.courseArray[index].required = false;
                    }
                  });
                }
              }
            }),
          this.store.pipe(select(studentManagementQuery.savedStudentFavoriteUniversity)).subscribe(data => {
            if (data) {
              this.favUniversityLists = data;
            } else {
              this.favUniversityLists = [];
            }
            if (this.isFavoriteResult) {
              this.onClickShowFavoriteUniversity();
            }
          })
        );
        this.getUploadedDocumentList();
      } else {
        this.subscriptions.push(
          this.store.pipe(select(studentManagementQuery.exploreUniversity)).subscribe(data => {
            if (data) {
              this.universityData = data;
              this.copyUniversityData = data;
              this.universityCourseData = _.flatten(data.map(temp => temp.courseList));
              this.getUniqueDataFunction();
            }
          })
        );
      }

      if (!localStorage.getItem('isSeperateExploreUniversities') && !localStorage.getItem('isRecruiterExploreUniversities')) {
        if (localStorage.getItem('recruiter-student-user')) {
          this.store.dispatch(new GetSaveUniversityApplications(JSON.parse(localStorage.getItem('recruiter-student-user')).salesforceId));
          this.store.dispatch(new GetShortlistedApplications(JSON.parse(localStorage.getItem('recruiter-student-user')).salesforceId));
          this.store.dispatch(new GetAppliedApplications(JSON.parse(localStorage.getItem('recruiter-student-user')).salesforceId));
          this.store.dispatch(new GetStudentLoginDetail(JSON.parse(localStorage.getItem('recruiter-student-user')).salesforceId));
          this.store.dispatch(new GetStudentSavedFavoriteUniversityList(JSON.parse(localStorage.getItem('recruiter-student-user')).salesforceId));
        } else {
          this.store.dispatch(new GetSaveUniversityApplications(JSON.parse(localStorage.getItem('user')).salesforceId));
          this.store.dispatch(new GetShortlistedApplications(JSON.parse(localStorage.getItem('user')).salesforceId));
          this.store.dispatch(new GetAppliedApplications(JSON.parse(localStorage.getItem('user')).salesforceId));
          this.store.dispatch(new GetStudentLoginDetail(JSON.parse(localStorage.getItem('user')).salesforceId));
          this.store.dispatch(new GetStudentSavedFavoriteUniversityList(JSON.parse(localStorage.getItem('user')).salesforceId));
        }
      }
    });
  }

  // course/program MultiSelect Only Search Result
  courseSelection(event) {
    if (event.key === 'Enter') {
      this.multiSelection('course');
    } else {
      this.selectCourse?.options.forEach((item: MatOption) => item.deselect());
      this.courseAllSelected = false;
    }
  }

  async courseTuitionFeeFilter() {
    const degreeArray = this.universityPreferenceForm.get('degree').value;
    if (degreeArray?.length > 0) {
      // COURSE FILTER BASED ON DEGREE SELECTION
      setTimeout(() => {
        const filterCourse = _.filter(this.countryBasedCourseData && this.countryBasedCourseData?.length > 0
          ? this.countryBasedCourseData : this.universityCourseData,
        obj => degreeArray?.indexOf(obj?.degree) !== -1);
        this.courseData = _.uniq(_.map(_.orderBy(filterCourse, ['orderId'], ['asc']), 'course')).map(temp => ({ value: temp, selected: false }));
        this.courseAutocompleteObservable = this.universityPreferenceForm.get('degree')
          .valueChanges.pipe(
            startWith(''),
            map((value: string) => {
              this.courseData
                .forEach(option => {
                  option.selected = true;
                });
              return this.courseData;
            })
          );
        this.courseAutocompleteObservable.subscribe((res) => {
          this.searchValue = [];
          res.forEach(temp => (temp.selected === true ? this.searchValue.push(temp) : null));
        });
      });
      const tuitionFee = _.cloneDeep(this.universityData);
      const tuitionFilter = [];
      for await (const j of tuitionFee) {
        j.courseList = _.filter(j?.courseList,
          obj => (degreeArray?.indexOf(obj?.degree) !== -1));
        if (j?.courseList?.length !== 0) {
          tuitionFilter.push(j);
        }
      }
      // filter tuition fee based on degree
      const filterTuitionFee = _.without(
        _.uniq(
          _.map(tuitionFilter.slice().sort((a, b) => (Number(a.tuitionFeeRangeOrder) < Number(b.tuitionFeeRangeOrder) ? -1 : 1)), 'tuitionFeesRange')
        ), undefined
      );
      this.tuitionDataFiltered = [];
      Array.prototype.push.apply(this.tuitionDataFiltered, filterTuitionFee);
    } else {
      setTimeout(() => {
        this.onChangeCountrySelected(event);
      }, 800);
      // setTimeout(() => {
      //   // set all values to course field
      //   this.courseData = _.uniq(_.map(_.orderBy(this.universityCourseData, ['orderId'], ['asc']), 'course')).map(temp => ({ value: temp, selected: false }));
      //   this.courseAutocompleteObservable = this.universityPreferenceForm.get('courseFilter')
      //     .valueChanges.pipe(
      //       startWith(''),
      //       map((value: string) => {
      //         this.courseData
      //           .forEach(option => {
      //             option.selected = option?.value?.toLocaleLowerCase().includes(value?.toLowerCase());
      //           });
      //         return this.courseData;
      //       })
      //     );
      //   this.courseAutocompleteObservable.subscribe((res) => {
      //     this.searchValue = [];
      //     res.forEach(temp => (temp.selected === true ? this.searchValue.push(temp) : null));
      //   });
      // });

      // to  reset  tuition fee field
      // this.tuitionDataFiltered = [];
    }
  }

  // reset Course
  resetCourse() {
    this.universityPreferenceForm.controls.course.reset();
    this.courseAllSelected = false;
  }

  multiSelection(option : any) {
    if (option === 'country') {
      if (this.countryAllSelected) {
        this.selectCountry.options.forEach((item: MatOption) => item.select());
      } else {
        this.selectCountry.options.forEach((item: MatOption) => item.deselect());
      }
    } else if (option === 'course') {
      // this.courseAllSelected = !this.courseAllSelected;
      if (this.courseAllSelected) {
        this.searchValue.forEach((temp) => {
          this.selectCourse?.options?.forEach((item: MatOption) => {
            temp.value === item.value ? item.select() : null;
          });
        });
      } else {
        this.selectCourse?.options.forEach((item: MatOption) => item.deselect());
      }
    }
  }
  optionClick(option : any) {
    if (option === 'country') {
      let newStatus = true;
      this.selectCountry.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus = false;
        }
      });
      this.countryAllSelected = newStatus;
    } else if (option === 'course') {
      let newStatus = true;
      this.selectCourse.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus = false;
        }
      });
      this.courseAllSelected = newStatus;
    }
  }
  overallAutoCompleteSubscribe() {
    // this.countryAutocompleteObservable = this.universityPreferenceForm.get('country')
    //   .valueChanges.pipe(
    //     startWith(''),
    //     map(value => this._filterCountry(value))
    //   );

    // this.degreeAutocompleteObservable = this.universityPreferenceForm.get('degree')
    //   .valueChanges.pipe(
    //     startWith(''),
    //     map(value => this._filterDegree(value))
    //   );

    // this.courseAutocompleteObservable = this.universityPreferenceForm.get('course')
    //   .valueChanges.pipe(
    //     startWith(''),
    //     map(value => this._filterCourse(value))
    //   );

    this.countryAutocompleteObservable = this.universityPreferenceForm.get('countryFilter')
      .valueChanges.pipe(
        startWith(''),
        map((value: string) => {
          this.countryData
            .forEach(option => {
              option.selected = option?.value?.toLocaleLowerCase().includes(value?.toLowerCase());
            });
          return this.countryData;
        })
      );

    this.degreeAutocompleteObservable = this.universityPreferenceForm.get('degreeFilter')
      .valueChanges.pipe(
        startWith(''),
        map((value: string) => {
          this.degreeData
            .forEach(option => {
              option.selected = option?.value?.toLocaleLowerCase().includes(value?.toLowerCase());
            });
          return this.degreeData;
        })
      );

    this.courseAutocompleteObservable = this.universityPreferenceForm.get('courseFilter')
      .valueChanges.pipe(
        startWith(''),
        map((value: string) => {
          this.courseData
            .forEach(option => {
              option.selected = option?.value?.toLocaleLowerCase().includes(value?.toLowerCase());
            });
          return this.courseData;
        })
      );
    this.courseAutocompleteObservable.subscribe((res) => {
      this.searchValue = [];
      res.forEach(temp => (temp.selected === true ? this.searchValue.push(temp) : null));
    });

    this.englishProficiencyObservable = this.universityPreferenceForm.get('englishProficiencyFilter')
      .valueChanges.pipe(
        startWith(''),
        map((value: string) => {
          this.englishProficiencyData
            .forEach(option => {
              option.selected = option?.value?.toLocaleLowerCase().includes(value?.toLowerCase());
            });
          return this.englishProficiencyData;
        })
      );

    this.intakeObservable = this.universityPreferenceForm.get('intakeFilter')
      .valueChanges.pipe(
        startWith(''),
        map((value: string) => {
          this.intakeData
            .forEach(option => {
              option.selected = option?.value?.toLocaleLowerCase().includes(value?.toLowerCase());
            });
          return this.intakeData;
        })
      );
  }

  private _filterCountry(value: string) {
    const filterValue = value?.toLowerCase();
    return this.countryData.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterDegree(value: string) {
    const filterValue = value?.toLowerCase();
    return this.degreeData.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterCourse(value: string) {
    const filterValue = value?.toLowerCase();
    return this.courseData.filter(option => option.toLowerCase().includes(filterValue));
  }

  initForm() {
    this.innerWidth = window.innerWidth;
    this.universityPreferenceForm = new FormGroup({
      country: new FormControl(null),
      countryFilter: new FormControl(null),
      degree: new FormControl(null),
      degreeFilter: new FormControl(null),
      course: new FormControl(null),
      courseFilter: new FormControl(null),
      applicationFees: new FormControl(null),
      tuitionFees: new FormControl(null),
      livingCost: new FormControl(null),
      minimumGPA: new FormControl(null),
      englishProficiency: new FormControl(null),
      englishProficiencyFilter: new FormControl(null),
      intake: new FormControl(null),
      intakeFilter: new FormControl(null),
      isGreGmat: new FormControl(null),
      isSTEMProgram: new FormControl(null),
      modeOfLearning: new FormControl(null),
      noOfCampus: new FormControl(null)
    });
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  onChangeCountrySelected(event) {
    const country = this.universityPreferenceForm.get('country').value;

    if (country && country.length > 0) {
      this.degreeData = [];
      this.courseData = [];
      this.livingCostData = [];
      this.FeesDataFiltered = [];
      this.tuitionDataFiltered = [];
      this.livingCostDataFiltered = [];
      this.englishProficiencyData = [];
      setTimeout(() => {
        // country filter
        const filteredCountry = _.filter(this.universityData,
          obj => country?.indexOf(obj?.country) !== -1);
        // course filter
        this.countryBasedCourseData = _.flatten(filteredCountry.map(temp => temp.courseList));

        // degree filter based on country select
        this.degreeData = _.uniq(_.map(_.orderBy(this.countryBasedCourseData, ['orderId'], ['asc']), 'degree')).map(temp => ({ value: temp, selected: false }));
        // course filter based on country select
        this.courseData = _.uniq(_.map(_.orderBy(this.countryBasedCourseData, ['orderId'], ['asc']), 'course')).map(temp => ({ value: temp, selected: false }));
        // to filter englishProficiency
        this.englishProficiencyData = _.uniq(_.map(_.orderBy(filteredCountry, ['orderId'], ['asc']), 'tileLevelEnglishProficiency')).map(temp => ({ value: temp, selected: false }));

        this.applicationFeesData = _.without(
          _.uniq(
            _.map(
              this.universityData.filter(temp => country.indexOf(temp.country) !== -1).sort((a, b) => (Number(a.applicationFeeRangeOrder) < Number(b.applicationFeeRangeOrder) ? -1 : 1)), 'applicationFeeRange'
            )
          ), undefined
        );
        this.tuitionFeesData = _.without(
          _.uniq(
            _.map(
              this.universityData.filter(temp => country.indexOf(temp.country) !== -1).sort((a, b) => (Number(a.tuitionFeeRangeOrder) < Number(b.tuitionFeeRangeOrder) ? -1 : 1)), 'tuitionFeesRange'
            )
          ), undefined
        );
        this.livingCostData = _.without(
          _.uniq(
            _.map(
              this.universityData.filter(temp => country.indexOf(temp.country) !== -1).sort((a, b) => (Number(a.livingCostRangeOrder) < Number(b.livingCostRangeOrder) ? -1 : 1)), 'livingCostRange'
            )
          ), undefined
        );

        this.minimumGPAData = _.without(
          _.uniq(
            _.map(
              this.universityData.filter(temp => country.indexOf(temp.country) !== -1).sort((a, b) => (Number(a.gpaRangeOrder) < Number(b.gpaRangeOrder) ? -1 : 1)), 'gpaRange'
            )
          ), undefined
        );

        Array.prototype.push.apply(this.FeesDataFiltered, this.applicationFeesData);
        Array.prototype.push.apply(this.tuitionDataFiltered, this.tuitionFeesData);
        Array.prototype.push.apply(this.livingCostDataFiltered, this.livingCostData);

        this.overallAutoCompleteSubscribe();
      }, 800);
    } else if (event && event?.value?.length > 0) {
      this.FeesDataFiltered = [];
      this.tuitionDataFiltered = [];
      this.livingCostDataFiltered = [];

      if (event?.value?.length === 0) {
        this.universityPreferenceForm.get('applicationFees').setValue(null);
        this.universityPreferenceForm.get('tuitionFees').setValue(null);
        this.universityPreferenceForm.get('livingCost').setValue(null);
      } else {
        this.applicationFeesData = _.without(
          _.uniq(
            _.map(
              this.universityData.filter(temp => event?.value?.indexOf(temp.country) !== -1).sort((a, b) => (Number(a.applicationFeeRangeOrder) < Number(b.applicationFeeRangeOrder) ? -1 : 1)), 'applicationFeeRange'
            )
          ), undefined
        );

        this.tuitionFeesData = _.without(
          _.uniq(
            _.map(
              this.universityData.filter(temp => event?.value?.indexOf(temp.country) !== -1).sort((a, b) => (Number(a.tuitionFeeRangeOrder) < Number(b.tuitionFeeRangeOrder) ? -1 : 1)), 'tuitionFeesRange'
            )
          ), undefined
        );
        this.livingCostData = _.without(
          _.uniq(
            _.map(
              this.universityData.filter(temp => event?.value?.indexOf(temp.country) !== -1).sort((a, b) => (Number(a.livingCostRangeOrder) < Number(b.livingCostRangeOrder) ? -1 : 1)), 'livingCostRange'
            )
          ), undefined
        );
        Array.prototype.push.apply(this.FeesDataFiltered, this.applicationFeesData);
        Array.prototype.push.apply(this.tuitionDataFiltered, this.tuitionFeesData);
        Array.prototype.push.apply(this.livingCostDataFiltered, this.livingCostData);
      }
    } else {
      this.FeesDataFiltered = [];
      this.tuitionDataFiltered = [];
      this.livingCostDataFiltered = [];
      this.universityPreferenceForm.get('applicationFees').reset();
      this.universityPreferenceForm.get('tuitionFees').reset();
      this.universityPreferenceForm.get('livingCost').reset();
      this.universityPreferenceForm.get('degree').reset();
      setTimeout(() => {
        this.getUniqueDataFunction();
      }, 800);
    }
    // if (!this.universityPreferenceForm.get('degree').value && !this.universityPreferenceForm.get('course').value) {
    //   this.universityData = _.filter(this.universityData, ['country', event.option.value]);
    //   this.getUniqueDataFunction();
    // } else if (this.universityPreferenceForm.get('degree').value) {
    //   this.universityData = _.filter(this.copyUniversityData,
    //     obj => (obj.degree === this.universityPreferenceForm.get('degree').value && obj.country === event.option.value));
    //   this.getUniqueDataFunction();
    // } else if (this.universityPreferenceForm.get('course').value) {
    //   this.universityData = _.filter(this.copyUniversityData,
    //     obj => (obj.course === this.universityPreferenceForm.get('course').value && obj.country === event.option.value));
    //   this.getUniqueDataFunction();
    // } else {
    //   this.universityData = _.filter(this.copyUniversityData,
    //     obj => (obj.course === this.universityPreferenceForm.get('course').value && obj.country === event.option.value
    //       && obj.degree === this.universityPreferenceForm.get('degree').value));
    //   this.getUniqueDataFunction();
    // }
  }

  public async filterDialog() {
    let isEmpty = true;
    for await (const [key] of Object.entries(this.universityPreferenceForm.value)) {
      if (key === 'country' && this.universityPreferenceForm.value[key]
      && this.universityPreferenceForm.value[key].length > 0
       && !!this.universityPreferenceForm.controls.degree.value?.length
      ) {
        isEmpty = false;
      }
    }
    if (this.FeesDataFiltered.length > 0
        || !!this.universityPreferenceForm.controls.degree.value?.length
        || this.tuitionDataFiltered.length > 0
        || this.livingCostDataFiltered.length > 0) {
      isEmpty = false;
    }
    if (isEmpty) {
      const config: MatDialogConfig = {
        panelClass: 'dialog-responsive',
        data: {
          title: 'Filter',
          isPhoneNeed: true
        }
      };
      this.dialog.closeAll();
      const dialogRef = this.dialog.open(FilterDialogBoxComponent, config);

      dialogRef.afterClosed().subscribe(result => {
        const event = {
          value: result
        };
        this.onChangeCountrySelected(event);
      });
    }
  }

  onChangeDegreeSelected(event: MatAutocompleteSelectedEvent) {
    if (!this.universityPreferenceForm.get('country').value && !this.universityPreferenceForm.get('course').value) {
      this.universityData = _.filter(this.copyUniversityData, ['degree', event.option.value]);
      this.getUniqueDataFunction();
    } else if (this.universityPreferenceForm.get('country').value) {
      this.universityData = _.filter(this.copyUniversityData,
        obj => (obj.country === this.universityPreferenceForm.get('country').value && obj.degree === event.option.value));
      this.getUniqueDataFunction();
    } else if (this.universityPreferenceForm.get('course').value) {
      this.universityData = _.filter(this.copyUniversityData,
        obj => (obj.course === this.universityPreferenceForm.get('course').value && obj.degree === event.option.value));
      this.getUniqueDataFunction();
    } else {
      this.universityData = _.filter(this.copyUniversityData,
        obj => (obj.course === this.universityPreferenceForm.get('course').value && obj.degree === event.option.value
          && obj.country === this.universityPreferenceForm.get('country').value));
      this.getUniqueDataFunction();
    }
  }

  onChangeCourseSelected(event: MatAutocompleteSelectedEvent) {
    if (!this.universityPreferenceForm.get('country').value && !this.universityPreferenceForm.get('degree').value) {
      this.universityData = _.filter(this.universityData, ['course', event.option.value]);
      this.getUniqueDataFunction();
    } else if (this.universityPreferenceForm.get('country').value) {
      this.universityData = _.filter(this.copyUniversityData,
        obj => (obj.country === this.universityPreferenceForm.get('country').value && obj.course === event.option.value));
      this.getUniqueDataFunction();
    } else if (this.universityPreferenceForm.get('degree').value) {
      this.universityData = _.filter(this.copyUniversityData,
        obj => (obj.degree === this.universityPreferenceForm.get('degree').value && obj.course === event.option.value));
      this.getUniqueDataFunction();
    } else {
      this.universityData = _.filter(this.copyUniversityData,
        obj => (obj.degree === this.universityPreferenceForm.get('degree').value && obj.course === event.option.value
          && obj.country === this.universityPreferenceForm.get('country').value));
      this.getUniqueDataFunction();
    }
  }

  keyPressCountryInput(event) {
    if (event === '') {
      this.universityPreferenceForm.get('country').setValue(null);
      if (!this.universityPreferenceForm.get('degree').value && !this.universityPreferenceForm.get('course').value) {
        this.universityData = this.copyUniversityData;
        this.getUniqueDataFunction();
      } else if (this.universityPreferenceForm.get('degree').value && this.universityPreferenceForm.get('course').value) {
        this.universityData = _.filter(this.copyUniversityData,
          obj => (obj.degree === this.universityPreferenceForm.get('degree').value
            && obj.course === this.universityPreferenceForm.get('course').value));
        this.getUniqueDataFunction();
      } else if (this.universityPreferenceForm.get('degree').value) {
        this.universityData = _.filter(this.copyUniversityData, ['degree', this.universityPreferenceForm.get('degree').value]);
        this.getUniqueDataFunction();
      } else if (this.universityPreferenceForm.get('course').value) {
        this.universityData = _.filter(this.copyUniversityData, ['course', this.universityPreferenceForm.get('course').value]);
        this.getUniqueDataFunction();
      }
    }
  }

  keyPressCourseInput(event) {
    if (event === '') {
      this.universityPreferenceForm.get('course').setValue(null);
      if (!this.universityPreferenceForm.get('degree').value && !this.universityPreferenceForm.get('country').value) {
        this.universityData = this.copyUniversityData;
        this.getUniqueDataFunction();
      } else if (this.universityPreferenceForm.get('degree').value && this.universityPreferenceForm.get('country').value) {
        this.universityData = _.filter(this.copyUniversityData,
          obj => (obj.degree === this.universityPreferenceForm.get('degree').value
            && obj.country === this.universityPreferenceForm.get('country').value));
        this.getUniqueDataFunction();
      } else if (this.universityPreferenceForm.get('degree').value) {
        this.universityData = _.filter(this.copyUniversityData, ['degree', this.universityPreferenceForm.get('degree').value]);
        this.getUniqueDataFunction();
      } else if (this.universityPreferenceForm.get('country').value) {
        this.universityData = _.filter(this.copyUniversityData, ['country', this.universityPreferenceForm.get('country').value]);
        this.getUniqueDataFunction();
      }
    }
  }

  keyPressDegreeInput(event) {
    if (event === '') {
      this.universityPreferenceForm.get('degree').setValue(null);
      if (!this.universityPreferenceForm.get('country').value && !this.universityPreferenceForm.get('course').value) {
        this.universityData = this.copyUniversityData;
        this.getUniqueDataFunction();
      } else if (this.universityPreferenceForm.get('course').value && this.universityPreferenceForm.get('country').value) {
        this.universityData = _.filter(this.copyUniversityData,
          obj => (obj.course === this.universityPreferenceForm.get('course').value
            && obj.country === this.universityPreferenceForm.get('country').value));
        this.getUniqueDataFunction();
      } else if (this.universityPreferenceForm.get('course').value) {
        this.universityData = _.filter(this.copyUniversityData, ['course', this.universityPreferenceForm.get('course').value]);
        this.getUniqueDataFunction();
      } else if (this.universityPreferenceForm.get('country').value) {
        this.universityData = _.filter(this.copyUniversityData, ['country', this.universityPreferenceForm.get('country').value]);
        this.getUniqueDataFunction();
      }
    }
  }

  public getUniqueDataFunction() {
    this.countryData = _.uniq(_.map(_.orderBy(this.universityData, ['orderId'], ['asc']), 'country')).map(temp => ({ value: temp, selected: false }));
    this.degreeData = _.uniq(_.map(_.orderBy(this.universityCourseData, ['orderId'], ['asc']), 'degree')).map(temp => ({ value: temp, selected: false }));
    this.courseData = _.uniq(_.map(_.orderBy(this.universityCourseData, ['orderId'], ['asc']), 'course')).map(temp => ({ value: temp, selected: false }));
    console.log('🚀 ---------------------------------------------------------------------------------------------------------------------------------------🚀');
    console.log('🚀 ~ file: explore-university.component.ts:757 ~ ExploreUniversityComponent ~ getUniqueDataFunction ~ this.courseData:', this.courseData);
    console.log('🚀 ---------------------------------------------------------------------------------------------------------------------------------------🚀');
    this.englishProficiencyData = _.uniq(_.map(_.orderBy(this.universityData, ['orderId'], ['asc']), 'tileLevelEnglishProficiency')).map(temp => ({ value: temp, selected: false }));
    // this.intakeData = _.uniq(_.map(_.orderBy(this.universityData, ['orderId'], ['asc']), 'tileLevelIntake')).map(temp => ({ value: temp, selected: false }));
    this.intakeData = this.intakeLists.map(temp => ({ value: temp, selected: false }));
    this.applicationFeesData = _.without(
      _.uniq(
        _.map(
          this.universityData.slice().sort((a, b) => (Number(a.applicationFeeRangeOrder) < Number(b.applicationFeeRangeOrder) ? -1 : 1)), 'applicationFeeRange'
        )
      ), undefined
    );
    this.tuitionFeesData = _.without(
      _.uniq(
        _.map(this.universityData.slice().sort((a, b) => (Number(a.tuitionFeeRangeOrder) < Number(b.tuitionFeeRangeOrder) ? -1 : 1)), 'tuitionFeesRange')
      ), undefined
    );
    this.livingCostData = _.without(
      _.uniq(
        _.map(this.universityData.slice().sort((a, b) => (Number(a.livingCostRangeOrder) < Number(b.livingCostRangeOrder) ? -1 : 1)), 'livingCostRange')
      ), undefined
    );
    this.minimumGPAData = _.without(
      _.uniq(
        _.map(
          this.universityData.slice().sort((a, b) => (Number(a.gpaRangeOrder) < Number(b.gpaRangeOrder) ? -1 : 1)), 'gpaRange'
        )
      ), undefined
    );
    this.overallAutoCompleteSubscribe();
  }

  public onClickViewUniversities(temp) {
    this.finalData = _.cloneDeep(this.filteredUniversity);
    // if (this.universityPreferenceForm.get('degree').value
    //   && this.universityPreferenceForm.get('country').value
    //   && this.universityPreferenceForm.get('course').value) {
    //   this.finalData = _.filter(this.copyUniversityData,
    //     obj => (obj.university === temp.university
    //       && obj.degree === this.universityPreferenceForm.get('degree').value
    //       && obj.country === this.universityPreferenceForm.get('country').value
    //       && obj.course === this.universityPreferenceForm.get('course').value));
    // } else if (this.universityPreferenceForm.get('degree').value
    //   && this.universityPreferenceForm.get('country').value) {
    //   this.finalData = _.filter(this.copyUniversityData,
    //     obj => (obj.university === temp.university
    //       && obj.degree === this.universityPreferenceForm.get('degree').value
    //       && obj.country === this.universityPreferenceForm.get('country').value));
    // } else if (this.universityPreferenceForm.get('degree').value
    //   && this.universityPreferenceForm.get('course').value) {
    //   this.finalData = _.filter(this.copyUniversityData,
    //     obj => (obj.university === temp.university
    //       && obj.degree === this.universityPreferenceForm.get('degree').value
    //       && obj.course === this.universityPreferenceForm.get('course').value));
    // } else if (this.universityPreferenceForm.get('country').value
    //   && this.universityPreferenceForm.get('course').value) {
    //   this.finalData = _.filter(this.copyUniversityData,
    //     obj => (obj.university === temp.university
    //       && obj.country === this.universityPreferenceForm.get('country').value
    //       && obj.course === this.universityPreferenceForm.get('course').value));
    // } else if (this.universityPreferenceForm.get('degree').value) {
    //   this.finalData = _.filter(this.copyUniversityData,
    //     obj => (obj.university === temp.university
    //       && obj.degree === this.universityPreferenceForm.get('degree').value));
    // } else if (this.universityPreferenceForm.get('country').value) {
    //   this.finalData = _.filter(this.copyUniversityData,
    //     obj => (obj.university === temp.university
    //       && obj.country === this.universityPreferenceForm.get('country').value));
    // } else if (this.universityPreferenceForm.get('course').value) {
    //   this.finalData = _.filter(this.copyUniversityData,
    //     obj => (obj.university === temp.university
    //       && obj.course === this.universityPreferenceForm.get('course').value));
    // } else {
    //   this.finalData = _.filter(this.copyUniversityData,
    //     obj => (obj.university === temp.university));
    // }
    /*
    {
        data: this.finalData,
        universityName: temp.university,
        description: temp.description,
        website: temp.website,
        iconUrl: temp.iconUrl,
        universityAddress: temp.universityAddress,
        isProfileCompleted: this.isProfileCompleted,
        isDocumentUploadCompleted: this.isDocumentUploadCompleted,
        salesforceId: this.user?.salesforceId
      }
    */
    const config: MatDialogConfig = {
      width: '100%',
      minWidth: '95%',
      minHeight: 'calc(100vh - 90px)',
      height: 'auto',
      disableClose: true,
      data: {
        ...temp,
        isProfileCompleted: this.isProfileCompleted,
        isDocumentUploadCompleted: this.isDocumentUploadCompleted,
        salesforceId: this.user?.salesforceId
      }
    };
    this.dialog.closeAll();
    this.dialog.open(UniversityDetailsComponent, config);
  }

  public async onClickSearchUniversity() {
    this.loaderService.loaderFullSubject.next(true);
    setTimeout(async () => {
      this.filteredUniversity = [];
      this.isFavoriteResult = false;
      this.isSearchResult = false;
      if (this.greGmatRequirement || this.isStemProgram || this.isModeOfLearning || this.isNoOfCampus) {
        this.isSearchResult = true;
      }
      let isEmpty = true;
      for await (const [key] of Object.entries(this.universityPreferenceForm.value)) {
        if (this.universityPreferenceForm.value[key]) {
          isEmpty = false;
        }
      }
      // console.log('empty', isEmpty, this.copyUniversityData);

      if (isEmpty) {
        this.filteredUniversity = _.cloneDeep(this.copyUniversityData);
        this.loaderService.loaderFullSubject.next(false);
      } else {
        this.filteredUniversity = _.cloneDeep(this.copyUniversityData);
        // eslint-disable-next-line array-callback-return
        Object.keys(this.universityPreferenceForm.value).map((key) => {
          this.universityPreferenceForm.value[key] = this.universityPreferenceForm.value[key]?.length > 0
          || typeof (this.universityPreferenceForm.value[key]) === 'string'
            ? this.universityPreferenceForm.value[key] : null;
        });

        for await (const [key, value] of Object.entries(this.universityPreferenceForm.value)) {
          if (this.universityPreferenceForm.value[key]) {
            this.exploreFilterSwichCase(key, value);
          }
        }
        this.loaderService.loaderFullSubject.next(false);
        if (this.filteredUniversity.length === 0) {
          this.isSearchResult = true;
        }
      }
    }, 800);

    // if (this.universityPreferenceForm.get('degree').value
    //   && this.universityPreferenceForm.get('country').value
    //   && this.universityPreferenceForm.get('course').value) {
    //   this.filteredUniversity = _.filter(this.copyUniversityData,
    //     obj => (obj.degree === this.universityPreferenceForm.get('degree').value
    //       && obj.country === this.universityPreferenceForm.get('country').value
    //       && obj.course === this.universityPreferenceForm.get('course').value));
    // } else if (this.universityPreferenceForm.get('degree').value
    //   && this.universityPreferenceForm.get('country').value) {
    //   this.filteredUniversity = _.filter(this.copyUniversityData,
    //     obj => (obj.degree === this.universityPreferenceForm.get('degree').value
    //       && obj.country === this.universityPreferenceForm.get('country').value));
    // } else if (this.universityPreferenceForm.get('degree').value
    //   && this.universityPreferenceForm.get('course').value) {
    //   this.filteredUniversity = _.filter(this.copyUniversityData,
    //     obj => (obj.degree === this.universityPreferenceForm.get('degree').value
    //       && obj.course === this.universityPreferenceForm.get('course').value));
    // } else if (this.universityPreferenceForm.get('country').value
    //   && this.universityPreferenceForm.get('course').value) {
    //   this.filteredUniversity = _.filter(this.copyUniversityData,
    //     obj => (obj.country === this.universityPreferenceForm.get('country').value
    //       && obj.course === this.universityPreferenceForm.get('course').value));
    // } else if (this.universityPreferenceForm.get('degree').value) {
    //   this.filteredUniversity = _.filter(this.copyUniversityData,
    //     obj => (obj.degree === this.universityPreferenceForm.get('degree').value));
    // } else if (this.universityPreferenceForm.get('country').value) {
    //   this.filteredUniversity = _.filter(this.copyUniversityData,
    //     obj => (obj.country === this.universityPreferenceForm.get('country').value));
    // } else if (this.universityPreferenceForm.get('course').value) {
    //   this.filteredUniversity = _.filter(this.copyUniversityData,
    //     obj => (obj.course === this.universityPreferenceForm.get('course').value));
    // } else {
    //   this.filteredUniversity = _.cloneDeep(this.copyUniversityData);
    // }

    // this.filteredUniversity = _.uniqWith(
    //   this.filteredUniversity,
    //   (locationA, locationB) => locationA.university === locationB.university
    //     && locationA.country === locationB.country
    // );
    // console.log(this.filteredUniversity);
  }
  // public univer:any =[];
  public async exploreFilterSwichCase(key, value) {
    // const data = this.filteredUniversity.filter(obj => obj.university === 'Lakeland College');

    switch (key) {
      case 'country':
        this.filteredUniversity = _.filter(this.filteredUniversity,
          // obj => (value === obj?.country));
          obj => value.indexOf(obj?.country) !== -1);

        break;
      case 'degree':
        for await (const j of this.filteredUniversity) {
          j.courseList = _.filter(j.courseList,
            obj => (value.some(el => obj?.degree?.includes(el))));
        }
        this.filteredUniversity = _.filter(this.filteredUniversity,
          obj => (obj?.courseList?.length > 0));
        break;
      case 'course':
        for await (const j of this.filteredUniversity) {
          j.courseList = _.filter(j.courseList,
            obj => (value.some(el => obj?.course?.includes(el))));
        }
        this.filteredUniversity = _.filter(this.filteredUniversity,
          obj => (obj?.courseList?.length > 0));
        break;
      case 'applicationFees':
        this.filteredUniversity = _.filter(this.filteredUniversity,
          // obj => (obj?.applicationFeeRange === value));
          obj => value.indexOf(obj?.applicationFeeRange) !== -1);
        break;
      case 'tuitionFees':
        this.filteredUniversity = _.filter(this.filteredUniversity,
          // obj => (obj?.tuitionFeesRange === value));
          obj => value.indexOf(obj?.tuitionFeesRange) !== -1);
        break;
      case 'livingCost':
        this.filteredUniversity = _.filter(this.filteredUniversity,
          // obj => (obj?.livingCostRange === value));
          obj => value.indexOf(obj?.livingCostRange) !== -1);
        break;
      case 'englishProficiency':
        this.filteredUniversity = _.filter(this.filteredUniversity,
          obj => (value.some(el => obj?.tileLevelEnglishProficiency?.includes(el))));
        break;
      case 'intake':
        this.filteredUniversity = _.filter(this.filteredUniversity,
          obj => (value.indexOf(el => obj?.tileLevelIntake?.includes(el))));
        for await (const j of this.filteredUniversity) {
          if (j.tileLevelIntake.includes(';')) {
            const tileLevelIntakeArray = j.tileLevelIntake.split(';');
            const intakeArray = [];
            for await (const i of value) {
              for await (const k of tileLevelIntakeArray) {
                if (k.includes(i)) {
                  intakeArray.push(k);
                  break;
                }
              }
            }
            j.tileLevelIntake = intakeArray.join(';');
          } else {
            const tileLevelIntakeArray = j.tileLevelIntake.split(',');
            const intakeArray = [];
            for await (const i of value) {
              for await (const k of tileLevelIntakeArray) {
                if (k.includes(i)) {
                  intakeArray.push(k);
                  break;
                }
              }
            }
            j.tileLevelIntake = intakeArray.join(',');
          }
          // console.log('j.intake', j.tileLevelIntake);

          // this.intakeFiltered = j.tileLevelIntake.split(',');
          // console.log('split words', this.intakeFiltered[0]);
        }

        // obj => value.indexOf(obj?.tileLevelIntake) !== -1);
        // console.log('filter university', this.filteredUniversity);
        break;
      case 'minimumGPA':
        this.filteredUniversity = _.filter(this.filteredUniversity,
          // obj => (obj?.gpaRange === value));
          obj => value.indexOf(obj?.gpaRange) !== -1);

        break;
      case 'isGreGmat':
        // this.filteredUniversity = _.filter(this.filteredUniversity,
        //   obj => (obj?.isGreGmat === value));
        for await (const j of this.filteredUniversity) {
          j.courseList = _.filter(j.courseList,
            obj => (obj?.isGreGmat === value));
        }
        this.filteredUniversity = _.filter(this.filteredUniversity,
          obj => (obj?.courseList.length > 0));
        break;
      case 'isSTEMProgram':
        for await (const j of this.filteredUniversity) {
          j.courseList = _.filter(j.courseList,
            obj => (obj?.isSTEMProgram === value));
        }
        this.filteredUniversity = _.filter(this.filteredUniversity,
          obj => (obj?.courseList.length > 0));
        break;
      case 'modeOfLearning':
        // console.log('mode', value);
        this.filteredUniversity = _.filter(this.filteredUniversity,
          obj => (obj?.modeOfLearning.includes(value)));
        // obj => (value.some(el => obj?.modeOfLearning?.includes(el))));
        break;
      case 'noOfCampus':
        this.filteredUniversity = _.filter(this.filteredUniversity,
          obj => (obj?.tileLevelNumberOfCampus === value));
        break;
      default:
        break;
    }
  }

  public onClickResetUniversity() {
    this.loaderService.loaderFullSubject.next(true);
    setTimeout(() => {
      this.isSearchResult = false;
      this.isFavoriteResult = false;
      this.countryAllSelected = false;
      this.isMoreFilter = true;
      this.universityPreferenceForm.reset();
      this.filteredUniversity = [];
      this.isImageLoading = [];
      this.universityData = _.cloneDeep(this.copyUniversityData);
      this.greGmatRequirement = '';
      this.isStemProgram = '';
      this.isModeOfLearning = '';
      this.isNoOfCampus = '';
      this.loaderService.loaderFullSubject.next(false);
      this.getUniqueDataFunction();
    }, 800);
  }

  public onClickShowFavoriteUniversity() {
    this.countryAllSelected = false;
    this.isSearchResult = false;
    this.isFavoriteResult = true;
    this.isMoreFilter = true;
    this.universityPreferenceForm.reset();
    this.filteredUniversity = [];
    this.isImageLoading = [];
    this.universityData = _.cloneDeep(this.copyUniversityData);
    this.greGmatRequirement = '';
    this.isStemProgram = '';
    this.isModeOfLearning = '';
    this.isNoOfCampus = '';
    this.filteredUniversity = _.cloneDeep(this.copyUniversityData);
    this.filteredUniversity = this.filteredUniversity.filter(temp => this.favUniversityLists.includes(temp.universityId));
    console.log('🚀 ~ file: explore-university.component.ts:1103 ~ ExploreUniversityComponent ~ onClickShowFavoriteUniversity ~ this.filteredUniversity:', this.filteredUniversity);
    this.getUniqueDataFunction();
  }

  private getUploadedDocumentList() {
    this.subscriptions.push(
      this.fileUploadService.getUploadedDocumentListFromService(this.user.salesforceId).subscribe(async response => {
        if (response) {
          const rejectedDocuments: Array<string> = [];
          for await (const i of response.documentList) {
            const courseIndex = this.courseArray.findIndex(temp => temp.id === i.id);
            if (courseIndex > -1) {
              this.courseArray[courseIndex].status = i.status;
              this.courseArray[courseIndex].statusIcon = statusArrayFake.filter(
                obj => obj.toolTip === i.status
              )[0]?.icon;
              this.courseArray[courseIndex].statusIconColor = statusArrayFake.filter(
                obj => obj.toolTip === i.status
              )[0]?.bgColor;
              this.courseArray[courseIndex].downloadUrl = (i.status !== 'Reupload') ? i.downloadUrl : null;

              if (i.status === 'Reupload') {
                this.courseArray[courseIndex].uploadFile = [];
                rejectedDocuments.push(i.courseName);
              }
            }
          }
          const isFilled = this.courseArray.filter(temp => temp.required)
            .every(temp => temp.downloadUrl);
          this.isDocumentUploadCompleted = isFilled;
          this.sharedService.allDocumentsUploadedBehaviorSubject.next(isFilled);
        } else {
          this.isDocumentUploadCompleted = false;
        }
      })
    );
  }

  public removeTag(iconUrl) {
    if (iconUrl) {
      return iconUrl.replace('<p>', '').replace('</p>', '').replace('amp;', '');
    }
  }

  public getFormValue() {
    if (!this.universityPreferenceForm.get('country').value
    && !this.universityPreferenceForm.get('degree').value
    && !this.universityPreferenceForm.get('course').value
    && !this.universityPreferenceForm.get('applicationFees').value
    && !this.universityPreferenceForm.get('tuitionFees').value
    && !this.universityPreferenceForm.get('livingCost').value
    && !this.universityPreferenceForm.get('minimumGPA').value
    && !this.universityPreferenceForm.get('englishProficiency').value
    && !this.universityPreferenceForm.get('intake').value
    && !this.universityPreferenceForm.get('isGreGmat').value
    && !this.universityPreferenceForm.get('isSTEMProgram').value
    && !this.universityPreferenceForm.get('modeOfLearning').value
    && !this.universityPreferenceForm.get('noOfCampus').value
    && !(this.filteredUniversity.length > 0)
    ) {
      return true;
    }
    return false;
  }

  public onUniversityLogoLoaded(university) {
    this.isImageLoading[university] = true;
  }

  public loadStudentDocuments() {
    this.store.pipe(select(studentManagementQuery.studentDocuments)).subscribe(result => {
      if (!result) {
        let path = null;
        const university = localStorage.getItem('university') || 'HUB';

        if (university === 'HUB') {
          if (this.user?.fromUS) {
            path = 'assets/data/transferStudent-document.json';
          } else {
            path = 'assets/data/outside-country-hub.json';
          }
        } else if (university === 'UWLA') {
          if (this.user.fromUS) {
            path = 'assets/data/transferStudent-document.json';
          } else {
            path = 'assets/data/outside-country-uwla.json';
          }
        }

        this.readFileFromService(path).subscribe(result => {
          this.store.dispatch(new StudentDocuments(result));
        });
      }
    });
  }

  public readFileFromService(path: string): Observable<any> {
    return this.http.get<any>(path);
  }

  public onClickGreGmatRequirement(value: string) {
    if (this.universityPreferenceForm.get('isGreGmat').value === value) {
      this.greGmatRequirement = '';
      this.universityPreferenceForm.get('isGreGmat').setValue(null);
    } else {
      this.greGmatRequirement = value;
      this.universityPreferenceForm.get('isGreGmat').setValue(value);
    }
  }

  public onClickStemProgram(value: string) {
    if (this.universityPreferenceForm.get('isSTEMProgram').value === value) {
      this.isStemProgram = '';
      this.universityPreferenceForm.get('isSTEMProgram').setValue(null);
    } else {
      this.isStemProgram = value;
      this.universityPreferenceForm.get('isSTEMProgram').setValue(value);
    }
  }

  public onClickModeOfLearning(value: string) {
    if (this.universityPreferenceForm.get('modeOfLearning').value === value) {
      this.isModeOfLearning = '';
      this.universityPreferenceForm.get('modeOfLearning').setValue(null);
    } else {
      this.isModeOfLearning = value;
      this.universityPreferenceForm.get('modeOfLearning').setValue(value);
    }
  }

  public onClickNoOfCampus(value: string) {
    if (this.universityPreferenceForm.get('noOfCampus').value === value) {
      this.isNoOfCampus = '';
      this.universityPreferenceForm.get('noOfCampus').setValue(null);
    } else {
      this.isNoOfCampus = value;
      this.universityPreferenceForm.get('noOfCampus').setValue(value);
    }
  }

  public onClickLessAndMoreFilters() {
    this.isMoreFilter = !this.isMoreFilter;
    this.greGmatRequirement = '';
    this.isStemProgram = '';
    this.isModeOfLearning = '';
    this.isNoOfCampus = '';
    this.universityPreferenceForm.get('isGreGmat').setValue(null);
    this.universityPreferenceForm.get('isSTEMProgram').setValue(null);
    this.universityPreferenceForm.get('modeOfLearning').setValue(null);
    this.universityPreferenceForm.get('noOfCampus').setValue(null);
    this.universityPreferenceForm.get('applicationFees').setValue(null);
    this.universityPreferenceForm.get('tuitionFees').setValue(null);
    this.universityPreferenceForm.get('livingCost').setValue(null);
    this.universityPreferenceForm.get('minimumGPA').setValue(null);
    this.universityPreferenceForm.get('englishProficiency').setValue(null);
    this.universityPreferenceForm.get('englishProficiencyFilter').setValue('');
    this.universityPreferenceForm.get('intake').setValue(null);
    this.universityPreferenceForm.get('intakeFilter').setValue('');
  }

  public onCloseMatSelectPanel() {
    this.universityPreferenceForm.get('countryFilter').setValue('');
    this.universityPreferenceForm.get('degreeFilter').setValue('');
    this.universityPreferenceForm.get('courseFilter').setValue('');
    this.universityPreferenceForm.get('englishProficiencyFilter').setValue('');
    this.universityPreferenceForm.get('intakeFilter').setValue('');
  }

  public onChangeCountrySelection() {
    if (this.universityPreferenceForm.get('country').value.length > 1) {
      this.universityPreferenceForm.get('applicationFees').setValue(null);
      this.universityPreferenceForm.get('tuitionFees').setValue(null);
      this.universityPreferenceForm.get('livingCost').setValue(null);
    }
  }

  public onClickSaveAsFavorite(value) {
    let isFavorite;
    if (!this.favUniversityLists.includes(value.universityId)) {
      this.favUniversityLists = this.favUniversityLists.filter((item) => item !== value.universityId);
      this.favUniversityLists.push(value.universityId);
      isFavorite = true;
    } else {
      this.favUniversityLists = this.favUniversityLists.filter((item) => item !== value.universityId);
      isFavorite = false;
    }
    const requestBody = {
      contactId: this.user.salesforceId,
      favoriteUniversityList: this.favUniversityLists,
      isFavorite
    };

    this.store.dispatch(new SaveStudentFavoriteUniversityList(requestBody));
  }

  public isFavoriteUniversityOrNot(university) {
    return this.favUniversityLists.includes(university);
  }

  ngOnDestroy() {
    // unsubscribe all subscriptions
    localStorage.removeItem('recruiter-student-user');
    localStorage.removeItem('isSeperateExploreUniversities');
    localStorage.removeItem('isRecruiterExploreUniversities');
    this.store.dispatch(new GetSaveUniversityApplicationsSuccess(null));
    this.store.dispatch(new GetShortlistedApplicationsSuccess(null));

    for (const i of this.subscriptions) {
      i.unsubscribe();
    }
  }
}
