<div class="overall-container">
    <div class="menu-item" fxLayout="column" fxLayoutGap="5px"
        *ngFor="let menuitem of menuItems.getMenuitem();let i=index"
        [class.active-gray-selected]="currentPath === '/' + menuitem.state || (isSubMenuOpen[i] && menuitem.children?.length > 0)">
        <a mat-button fxLayout="row" color="primary" fxLayoutAlign="start center"
            [class.active-selected]="currentPath === '/' + menuitem.state || (isSubMenuOpen[i] && menuitem.children?.length > 0)"
            (click)="onClickMainMenu(['/' + menuitem.state],i)" style="justify-content: start;">
            <mat-icon class="menu-icon" style="margin-left: 6px;margin-right: 10px;height: 24px;width: 24px;line-height: 23px;font-size: 20px;">{{ menuitem.icon }}</mat-icon>

            <span class="menu-title">{{ menuitem.name }}</span>

            <mat-icon class="menu-icon" *ngIf="menuitem.type === 'sub'" style="height: 24px;width: 24px;order:3;font-size: 20px;margin-left: 10px;float: right;line-height: 23px;">
                {{isSubMenuOpen[i] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
            </mat-icon>
        </a>
        <ng-container *ngIf="isSubMenuOpen[i]">
            <div class="sub-menu-item" *ngFor="let childitem of menuitem.children;let j=index; let i = childitem">
                <a mat-button fxLayout="row" color="primary" [class.active-sub-selected]="currentPath === '/' + childitem.state || 
                    currentPath === childitem.state || isSubMenuActive[j]"
                    (click)="onClickSubmenu(['/' + childitem.state]); itemSelect[i]=j" style="justify-content: start;">
                    <mat-icon class="sub-menu-icon" style="margin-left: 10px;"></mat-icon>
                    <span class="sub-menu-title">{{ childitem.name}}</span>
                </a>
            </div>
        </ng-container>
    </div>
</div>