<div fxLayout="row wrap" style="padding: 20px;">
    <div fxFlex.gt-sm="100%" fxFlex="100">
  
      <div fxLayoutAlign="space-between">
        <h2 class="m-b-0">Add New Document</h2>
        <button mat-mini-fab class="bg-danger text-white" mat-dialog-close (click)="fileMulti = [];">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div>
        <div>
            <div class="form-group mr-2">
              <label for="exampleInputEmail1" style="font-size: 13px">Document Name</label>
              <!-- <input [(ngModel)]="customDocName" type="text" class="form-control"/> -->
              <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="customDocName">
              <option value="" disabled selected>
                Select Document
              </option>
              <option *ngFor="let item of customDocList" [value]="item.name" [disabled]="item.disabled">
                {{item?.name}}</option>
            </select>
            </div>
            <div class="d-flex" style="justify-content: flex-end;margin-top: 20px;">
              <div style="margin-right: 20px;">
                <input hidden type="file" #uploadFile2 (change)="uploadCustomDocument($event)" multiple />
                <button mat-raised-button type="button" color="primary" (click)="uploadFile2.click()">
                  <img class="img12" src="../../../assets/images/upload-w.svg" /> Select File
                </button>
              </div>
              <div>
                <button (click)="addCustomDocument()" type="button" mat-raised-button type="button" color="primary" [disabled]="fileMulti.length <= 0">
                  <mat-icon>add</mat-icon>Add
                </button>
              </div>
            </div>

          </div>

          <!-- <div *ngFor="let i of customFileList" class="d-flex add-docs-item align-items-center">
            <div class="">
              <img class="img12 mr-1 lh160" src="../../../assets/img/docs-r.svg" />
              {{i.docName | titlecase}}<br />
              <div class="add-docs-item-sub d-flex" style="margin-left: 20px">
                <div class="txt-oflow-lg">
                  {{i.fileName}}
                </div>
              </div>
            </div>
            <div class="ml-auto">
              <img class="img12" src="../../../assets/img/delete.svg" />
            </div>
          </div> -->

          <div class="p-3 text-center docUploadError"
            style="color: green; font-size: 14px; font-weight: bold">
            {{docUploadError}}
          </div>
      </div>
    </div>
</div>