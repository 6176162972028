import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { INotice, INoticeIcon, noticeIcon } from '../../models/shared.model';

@Component({
  selector: 'app-validation-alert',
  templateUrl: './validation-alert.component.html',
  styleUrls: ['./validation-alert.component.scss']
  })
export class ValidationAlertComponent implements OnInit {
  public header: string;
  public message: string;
  public status: INoticeIcon;
  public routerLink: string;

  constructor(
    private dialogRef: MatDialogRef<ValidationAlertComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: INotice
  ) {
    this.header = this.dialogData.header;
    this.message = this.dialogData.message;
    this.status = noticeIcon[this.dialogData.type];
    this.routerLink = this.dialogData.routerLink;
  }

  ngOnInit(): void {
  }
}
