import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ERole } from '../model/user.model';
import { IUserState } from './users.state';
import { EReducers } from '../app.state';

export const userSelectorFeature = createFeatureSelector<IUserState>(EReducers[EReducers.USER]);
export const isStudentLoggedIn = createSelector(
  userSelectorFeature, user => user.role === ERole[ERole.STUDENT]
);
export const userSelect = createSelector(userSelectorFeature, (state: IUserState) => state?.user);
export const roleSelector = createSelector(userSelectorFeature, (state: IUserState) => state?.role);
export const loggedInUser = createSelector(userSelectorFeature, (state: IUserState) => state?.loggedInUser);

export const userQuery = {
  roleSelector,
  isStudentLoggedIn,
  userSelect,
  loggedInUser
};
