export interface IUser {
  id?: number;
  phone?: string;
  emailId: string;
  firstName?: string;
  lastName?: string;
  ApplyingAs?:string;
  othersApplyingAs?:string;
  ApplyingFor?:string;
  othersApplyingFor?:string;
  transferStudent?:string;
  password: string;
  confirmPassword?: string;
  role?: string;
  accessToken?: string;
  refreshToken?: string;
  userId?: number;
  salesforceId?: string;
  applicationCompletion?: number;
  university?: string;
  isLock?: boolean;
  fromUS?: boolean;
  createdDate?: string;
  isProfileCompleted?: boolean;
  recruiterSalesforceId?: string;
  staffSalesforceId?: string;
  isDocumentCompleted?:boolean;
  phoneWithoutCode?:string,
  phoneCountryCode?:string,
  user_type?:string,
  dob?:string
}

export interface IUserHttp {
  users: IUser[];
}

export interface IChangePasswordRequest {
  existingPassword?: string;
  newPassword: string;
  confirmPassword?: string;
}

export interface INewPasswordRequest {
  newPassword: string;
  resetToken: string;
  role: string;
  purpose?: string;
  isCrmCreated?:boolean;
  emailId?:string;
}

export interface IRefreshTokenRequest {
  refreshToken: string;
  roleId: number;
}

export interface IForgotPasswordRequest {
  userName: string;
  university: string;
}

export interface IResponseMessage {
  message?: string;
  color?: string;
}

export interface ILogout {
  refreshToken: string;
  userId: string;
  roleId: string;
}

export interface IActivateStudent {
  emailId: string;
  accountActive: boolean;
}

export interface ILogin {
  emailId: string;
  password: string;
}

export enum EUserEndPoint {
  ADD_USER = 'session/register',
  LOGIN = 'session/login',
  LOGOUT = 'session/logout',
  CHANGE_PASSWORD_STUDENT = 'session/changepassword',
  FORGOT_PASSWORD_STUDENT = 'session/forgotpassword',
  NEW_PASSWORD_STUDENT = 'session/newpassword',
  CHANGE_PASSWORD_RECRUITER = 'session/recruiterchangepassword',
  FORGOT_PASSWORD_RECRUITER = 'session/recruiterforgotpassword',
  NEW_PASSWORD_RECRUITER = 'session/recruiternewpassword',
  REFRESH_TOKEN = 'session/refresh',
  ADD_RECRUITER = 'session/recruiterregister',
  ACTIVATE_STUDENT = 'session/activateStudent',
  NEW_PASSWORD_ASSOCIATE = 'recruiter/associateNewPassword',
  FORGOT_PASSWORD_ASSOCIATE = 'associate/associateForgotPassword',
  RESEND_LINK = 'session/resendLink',
  EMAIL_ID_VALIDATOR = 'session/emailIdValidator'
}

export enum ERole {
  ADMIN,
  STAFF,
  DUMMY,
  STUDENT,
  RECRUITER_FREELANCER,
  RECRUITER_ORGANIZATION,
  RECRUITER_MASTER,
  ASSOCIATE
}

export const Recruiter = (role: string) => {
  if (
    role === ERole[ERole.RECRUITER_FREELANCER]
    || role === ERole[ERole.RECRUITER_ORGANIZATION]
    || role === ERole[ERole.RECRUITER_MASTER]
    || role === 'RECRUITER'
  ) {
    return 'RECRUITER';
  }
  return null;
};
