import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-ask-question',
  templateUrl: './ask-question.component.html',
  styleUrls: ['./ask-question.component.scss']
  })
export class AskComponent implements OnInit {
  form: FormGroup;

  constructor(private _formBuilder: FormBuilder, private router: Router,
    public dialogRef: MatDialogRef<AskComponent>) { }

  ngOnInit() {
    const userDetail = JSON.parse(localStorage.getItem('user'));

    this.form = this._formBuilder.group({
      query: ['', Validators.required],
      // location: ['', Validators.required],
      help: ['', Validators.required],
      firstname: [userDetail?.firstName, Validators.required],
      lastname: [userDetail?.lastName, Validators.required],
      email: [userDetail?.emailId, Validators.required],
    });
  }

  onSubmit(): void {
    this.dialogRef.close(this.form.value);
  }
}
