import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public loaderSubject = new Subject<boolean>();
  public loaderState = this.loaderSubject.asObservable();

  public loaderFullSubject = new Subject<boolean>();
  public loaderFullState = this.loaderFullSubject.asObservable();

  public spinnerFullSubject = new Subject<boolean>();
  public spinnerFullState = this.spinnerFullSubject.asObservable();
}
