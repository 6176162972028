<mat-dialog-content>
    <div fxLayout="column" fxLayoutAlign="center center">
        <mat-icon [style.color]="status.color">{{status.icon}}</mat-icon>
        <p fxLayoutAlign="center center" class="m-b-10" id="header-size"> {{header}} </p>
        <p fxLayoutAlign="center center"> {{message}} </p>
    </div>

    <div fxLayoutAlign="center center" fxLayoutGap="12px" class="m-t-20">
        <button mat-raised-button class="bg-danger text-white" mat-dialog-close>
            Cancel
        </button>
        <button mat-raised-button class="bg-success text-white" [routerLink]=[routerLink] mat-dialog-close>
            Confirm
        </button>
    </div>
</mat-dialog-content>