import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ILockStudent } from '../../pages/recruiter/student-management/student-management.model';
import { ICreateStudent, IInviteStudent } from '../model/recruiter.model';
import {
  EStudentMangementEndPoints, IDashboardMetrics, IApplyCourseSuccess,
  ISendStudentNotification,
  ISwitchNotification,
  IUniversities,
  ICourses,
  IApplications,
  IApplyUniversityApplications,
  IShortlistedApplications,
  IPayNowLink,
  IProposedPaymentStatus
} from '../model/student-management.model';
import { IUser } from '../model/user.model';
import { userQuery } from '../users/users.selectors';

@Injectable({
  providedIn: 'root'
})
export class StudentManagementService {
  public baseUrl = `${environment.baseUrl}`;
  public baseUrlCRM = `${environment.baseUrlCRM}`;
  public user: IUser;

  constructor(
    private http: HttpClient,
    private store: Store
  ) {
    this.store.pipe(select(userQuery.userSelect)).subscribe((user: IUser) => {
      this.user = user;
    });
  }

  public isShortlistUniversityErrorBehaviorSubject = new BehaviorSubject<boolean>(false);
  public isShortlistUniversityErrorObservable = this.isShortlistUniversityErrorBehaviorSubject.asObservable();

  public inviteStudentFromService(requestBody: IInviteStudent): Observable<IInviteStudent> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.INVITE_STUDENT;
    return this.http.post<IInviteStudent>(url, requestBody);
  }

  public sendStudentProfileFromService(requestBody): Observable<any> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.STUDENT_PROFILE;
    return this.http.post<any>(url, requestBody);
  }

  public getStudentListFromService(id: number, roleId: number): Observable<any> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.STUDENT_LIST;
    const params = new HttpParams()
      .set('id', String(id))
      .set('roleId', String(roleId));
    return this.http.get<any>(url, { params });
  }

  public getInviteFailedListFromService(id: number, roleId: number, salesforceId:any): Observable<any> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.INVITE_FAILED_LIST;
    const params = new HttpParams()
      .set('id', String(id))
      .set('roleId', String(roleId))
      .set('salesforceId', String(salesforceId));
    return this.http.get<any>(url, { params });
  }

  public deleteFromFailedListFromService(id: number): Observable<any> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.DELETE_FROM_FAILED_LIST;
    const params = new HttpParams()
      .set('id', String(id));
    return this.http.get<any>(url, { params });
  }

  public getDashboardMetricsFromService(id: number, roleId: number): Observable<IDashboardMetrics[]> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.DASHBOARD_METRICS;
    const params = new HttpParams()
      .set('roleId', String(roleId))
      .set('id', String(id));
    return this.http.get<any>(url, { params });
  }

  public getDashboardMetricsFromServiceLeads(id: number, roleId: number, cardType: string): Observable<any[]> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.CARD_METRICS;
    const params = new HttpParams()
      .set('roleId', String(roleId))
      .set('id', String(id))
      .set('cardType', String(cardType));
    return this.http.get<any>(url, { params });
  }

  public getExploreUniversityFromService(contactId : any): Observable<any> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.GET_EXPLORE_UNIVERSITY;
    const params = new HttpParams()
      .set('contactId', contactId);
    return this.http.get<any>(url, { params });
  }

  public applyCourseFromService(formCode: string): Observable<IApplyCourseSuccess> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.APPLY_COURSE;
    const params = new HttpParams()
      .set('formCode', formCode)
      .set('contactId', this.user.salesforceId);

    return this.http.get<any>(url, { params });
  }

  public getProfilePercentageFromService(emailId: string): Observable<any> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.PROFILE_PROGRESS;
    const params = new HttpParams()
      .set('emailId', emailId);

    return this.http.get<any>(url, { params });
  }

  public getStudentLoginDetail(contactId: string): Observable<IUser> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.GET_STUDENT_LOGIN_DATA;
    const params = new HttpParams()
      .set('contactId', contactId);
    return this.http.get<any>(url, { params });
  }

  public getStudentNotificationFromService(): Observable<any> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.GET_STUDENT_NOTIFICATION;
    const params = new HttpParams()
      .set('studentId', String(this.user?.salesforceId));
    return this.http.get<any>(url, { params });
  }

  public switchNotificationFromService(enableNotification: boolean): Observable<any> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.SWITCH_NOTIFICATION;
    const requestBody: ISwitchNotification = {
      studentId: this.user.userId,
      enableNotification
    };

    return this.http.put<any>(url, requestBody);
  }

  public sendStudentNotificationFromService(requestBody: ISendStudentNotification) {
    const url: string = this.baseUrl + EStudentMangementEndPoints.SEND_STUDENT_NOTIFICATION;
    return this.http.post<any>(url, requestBody);
  }

  public getStudentProfileFormFromService(contactId) {
    const url: string = this.baseUrl + EStudentMangementEndPoints.GET_STUDENT_PROFILE;
    const params = new HttpParams()
      .set('contactId', contactId);

    return this.http.get<any>(url, { params });
  }

  public getApplicationStatusFormFromService() {
    const url: string = this.baseUrl + EStudentMangementEndPoints.GET_APPLICATION_STATUS;
    const params = new HttpParams()
      .set('contactId', String(this.user?.salesforceId));
    return this.http.get<any>(url, { params });
  }

  public lockStudentFromService(lockStudent: ILockStudent): Observable<any> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.LOCK_STUDENT;
    return this.http.put<any>(url, lockStudent);
  }

  public getDegreeListFromService(country: string, salesforceId:any) {
    const url: string = this.baseUrl + EStudentMangementEndPoints.GET_DEGREE_LIST;
    // const params = new HttpParams()
    //   .set('country', country);
    const requestData = {
      country,
      salesforceId,
    };
    return this.http.post<string[]>(url, requestData);
  }

  public getUniversityListFromService(country: string, degree: string, courseName: string, salesforceId:any) {
    const url: string = this.baseUrl + EStudentMangementEndPoints.GET_UNIVERSITY_LIST;
    // const params = new HttpParams()
    //   .set('country', country)
    //   .set('degree', degree)
    //   .set('courseName', courseName);
    const requestData = {
      country,
      degree,
      courseName,
      salesforceId: this.user?.salesforceId,
    };
    return this.http.post<IUniversities[]>(url, requestData);
  }
  public getUniversityListFromCRM() {
  // const url: string = 'https://crmtest.universityhub.com/api/v1/' + EStudentMangementEndPoints.GET_UNIVERSITY_LISTV2;
  const url: string = this.baseUrlCRM + EStudentMangementEndPoints.GET_UNIVERSITY_LISTV2;
   
    return this.http.get<any>(url,{
      headers:{
        'x-auth-token':'asseccque'
      }
    });
  }

  public getCoursesFromService(country: string, degree: string, salesforceId:any) {
    const url: string = this.baseUrl + EStudentMangementEndPoints.GET_COURSES;
    // const params = new HttpParams()
    //   .set('country', country)
    //   .set('degree', degree);
    const requestData = {
      country,
      degree,
      salesforceId,
    };
    return this.http.post<ICourses[]>(url, requestData);
  }

  public getStudentTimelineFromService() {
    const url = `${this.baseUrl}student/getStudentTimeline`;
    const params = new HttpParams()
      .set('contactId', this.user.salesforceId);

    return this.http.get<any>(url, { params });
  }

  public applyUniversityApplicationsFromService(applyUniversityApplications: IApplyUniversityApplications) {
    const url: string = this.baseUrl + EStudentMangementEndPoints.APPLY_UNIVERSITY_APPLICATIONS;
    return this.http.post<any>(url, applyUniversityApplications);
  }

  public saveUniversityApplicationsFromService(saveniversityApplications: IApplyUniversityApplications) {
    const url: string = this.baseUrl + EStudentMangementEndPoints.SAVE_UNIVERSITY_APPLICATIONS;
    return this.http.post<any>(url, saveniversityApplications);
  }

  public getAppliedApplicationsFromService(contactId) {
    const url: string = this.baseUrl + EStudentMangementEndPoints.GET_APPLIED_APPLICATIONS;
    const params = new HttpParams()
      .set('contactId', contactId);

    return this.http.get<IApplications[]>(url, { params });
  }

  public getShortlistedApplicationsFromService(contactId) {
    const url: string = this.baseUrl + EStudentMangementEndPoints.GET_SHORTLISTED_APPLICATIONS;
    const params = new HttpParams()
      .set('contactId', contactId);

    return this.http.get<IShortlistedApplications>(url, { params });
  }

  public acceptShortlistedApplicationsFromService(acceptShortlistedApplications: IApplyUniversityApplications) {
    const url: string = this.baseUrl + EStudentMangementEndPoints.ACCEPT_SHORTLISTED_APPLICATIONS;
    return this.http.post<any>(url, acceptShortlistedApplications);
  }

  public payNowFromService(data) {
    const url: string = this.baseUrl + EStudentMangementEndPoints.PAY_NOW;
    return this.http.post<IPayNowLink>(url, data);
  }

  public checkProposedPaymentStatus(contactId) {
    const url: string = this.baseUrl + EStudentMangementEndPoints.CHECK_PROPOSED_PAYMENT_STATUS;
    const params = new HttpParams()
      .set('contactId', contactId);

    return this.http.get<IProposedPaymentStatus>(url, { params });
  }

  public getSaveUniversityApplications(contactId) {
    const url: string = this.baseUrl + EStudentMangementEndPoints.GET_SAVE_UNIVERSITY_APPLICATION;
    const params = new HttpParams()
      .set('contactId', contactId);

    return this.http.get<any>(url, { params });
  }

  public getStudentApplicationStatusFromService(contactId) {
    const url: string = this.baseUrl + EStudentMangementEndPoints.GET_STUDENT_APPLICATION_STATUS;
    const params = new HttpParams()
      .set('contactId', contactId);

    return this.http.get<any>(url, { params });
  }

  public updateNotificaionFromService(data): Observable<any> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.UPDATE_STUDENT_NOTIFICATION;
    return this.http.post<any>(url, data);
  }

  public updateDocumentCompletionFromService(data): Observable<any> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.UPDATE_DOCUMENT_COMPLETETION;
    return this.http.post<any>(url, data);
  }

  public getStudentSavedFavoriteUniversityList(contactId: string): Observable<IUser> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.GET_STUDENT_FAVORITE_UNIVERSITY;
    const params = new HttpParams()
      .set('contactId', contactId);
    return this.http.get<any>(url, { params });
  }

  public saveStudentSavedFavoriteUniversityList(data): Observable<IUser> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.SAVE_STUDENT_FAVORITE_UNIVERSITY;
    return this.http.post<any>(url, data);
  }

  public createStudentFromService(requestBody: ICreateStudent): Observable<ICreateStudent> {
    const url: string = this.baseUrl + EStudentMangementEndPoints.CREATE_STUDENT;
    return this.http.post<ICreateStudent>(url, requestBody);
  }
}
