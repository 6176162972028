import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpRequest, HttpHandler, HttpEvent,
} from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import {
  Observable, throwError, BehaviorSubject
} from 'rxjs';
import {
  catchError, switchMap, finalize, filter, take, tap
} from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { UserService } from 'src/app/store/users/users.service';
import { IRefreshTokenRequest, EUserEndPoint } from 'src/app/store/model/user.model';
import { LoginSuccess } from 'src/app/store/users/users.actions';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private userService: UserService,
    private sharedService: SharedService,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401 && err.error.message === '401 Unauthorized'
        && !request.url.includes(EUserEndPoint.REFRESH_TOKEN)) {
        return this.handle401Error(request, next);
      }

      if (request.url.includes(EUserEndPoint.REFRESH_TOKEN)) {
        return this.logoutUser('Your session has expired. Please log in again');
      }

      const error = err.error?.errors || err.error?.message || 'Something went wrong';
      return throwError(error);
    }));
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    const user = this.sharedService.getUserFromLocalStorage();

    if (!user) {
      
      // this.router.navigate(['/authentication/login']);
      if (localStorage.getItem('loginRole') && (localStorage.getItem('loginRole') === 'STUDENT')) {
        this.router.navigate(['authentication/login']);
      } else if (localStorage.getItem('loginRole') && (localStorage.getItem('loginRole') === 'RECRUITER')) {
        this.router.navigate(['authentication/recruiter/login']);
      }
      return throwError('Your session has expired. Please log in again');
    }
    const refreshTokenRequest: IRefreshTokenRequest = {
      refreshToken: user?.refreshToken,
      roleId: user?.roleId
    };

    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);

      return this.userService.refreshToken(refreshTokenRequest).pipe(
        tap(({ accessToken }) => {
          if (accessToken) {
            this.tokenSubject.next(accessToken);

            user.accessToken = accessToken;
            localStorage.setItem('user', JSON.stringify(user));
            this.store.dispatch(new LoginSuccess(user.role, user));
          }
        }),
        catchError((error) => this.logoutUser(error)),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    }
    return this.tokenSubject.pipe(
      filter(accessToken => accessToken != null),
      take(1),
      switchMap(accessToken => {
        user.accessToken = accessToken;
        localStorage.setItem('user', JSON.stringify(user));
        this.store.dispatch(new LoginSuccess(user.role, user));
        return next.handle(this.getNewRequest(request));
      })
    );
  }

  getNewRequest(request): HttpRequest<any> {
    // if (request.method === 'GET') {
    //   return new HttpRequest(request.method, request.url);
    // }

    return new HttpRequest(request.method, request.url, {
      body: request.body,
      params: request.params
    });
  }

  logoutUser(error) {
    // localStorage.clear();
    // const role = localStorage.getItem('loginRole');
    localStorage.clear();
    // localStorage.setItem('loginRole', role || 'STUDENT');
    window.location.reload();
    return throwError(error);
  }
}
