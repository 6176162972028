import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import csc, {
  ICountry, IState
} from 'country-state-city';
import { Observable } from 'rxjs';
import {
  map
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
  })
export class CountryStateCityService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  public getAllCountries() {
    return csc.getAllCountries();
  }
  public getAllCountriesNew() {
    return csc.getAllCountries();
  }

  public getStatesOfCountry(countryId: string): IState[] {
    return csc.getStatesOfCountry(countryId);
  }

  public filterCountryByName(value) {
    const allCountries = csc.getAllCountries();
    const filteredObj = allCountries.filter(x => x.name.toUpperCase() == value.toUpperCase());
    if (filteredObj.length > 0) {
      return filteredObj;
    }
  }
}
