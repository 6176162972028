import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { MenuItems } from 'src/app/shared/models/menu-items';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['full.component.scss']
  })
export class FullComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList;
  dir = 'ltr';
  green: boolean;
  blue: boolean;
  dark: boolean;
  minisidebar: boolean;
  boxed: boolean;
  danger: boolean;
  showHide: boolean;
  url: string;
  sidebarOpened;
  status = false;
  public university: string;

  public showSearch = false;
  private _mobileQueryListener: () => void;
  public role: string;
  public user;
  options = {
    lastOnBottom: false,
    position: ['bottom', 'right'],
    timeOut: 20000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: false,
    maxLength: 10,
  };
  constructor(
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.university = localStorage.getItem('university') || 'HUB';
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user.role === 'STUDENT') {
      if (this.user?.transferStudent) {
        this.role = 'TRANSFER STUDENT';
      } else {
        this.role = 'INTERNATIONAL STUDENT';
      }
    } else if (this.user.role === 'RECRUITER_FREELANCER') {
      this.role = 'RECRUITER FREELANCER';
    } else if (this.user.role === 'RECRUITER_ORGANIZATION') {
      this.role = 'RECRUITER ORGANIZATION';
    } else if (this.user.role === 'ASSOCIATE') {
      this.role = 'RECRUITER ASSOCIATE';
    } else {
      this.role = '';
    }
  }

  clickEvent() {
    this.status = !this.status;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  // Mini sidebar
}
