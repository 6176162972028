import {
  Component, Inject, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { ShowSuccessMsg } from '../../../../store/app.actions';
import { IApplyUniversityApplications } from '../../../../store/model/student-management.model';
import {
  ApplyUniversityApplications, ApplyUniversityApplicationsFailure, EStudentManagementActions,

} from '../../../../store/student-management/student-management.actions';
import { studentManagementQuery } from '../../../../store/student-management/student-management.selectors';
import { StudentManagementService } from '../../../../store/student-management/student-management.service';
import { userQuery } from '../../../../store/users/users.selectors';
import { UniversitiesComponent } from '../../universities/universities.component';

@Component({
  selector: 'app-university-details',
  templateUrl: './university-details.component.html',
  styleUrls: ['./university-details.component.scss']
  })
export class UniversityDetailsComponent implements OnInit, OnDestroy {
  public universityDetailsDatasource = new MatTableDataSource([]);
  public universityDetailsColumns = [];

  @ViewChild(UniversitiesComponent) child: UniversitiesComponent;

  public universityData = [];
  public data = [];
  public user;
  public role:any;

  public isShortlist: Array<boolean> = [];
  public isShortlistIndex: number;
  private subscriptions: Subscription[] = [];
  public isApplied= true;
  public reachedMax = false;
  public successMsg = false;
  public deleteUniversityList : any;

  public universityName: string;
  public universityWebsite: string;
  public universityDescription: string;
  public universityAddress: string;
  public universityLogo: string;

  public finalData: IApplyUniversityApplications;
  public isExpanded: boolean;
  public universityAddressKeys = ['street', 'city', 'state', 'postalCode', 'country'];
  public isSeperateExploreUniversities:boolean;
  public topHeaderColumn = [];

  constructor(public dialogRef: MatDialogRef<UniversityDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    public router: Router,
    public studentService: StudentManagementService,
    private store: Store,
    private loaderService: LoaderService,
    private actions: Actions,
    private snackbar:MatSnackBar) { }

  ngOnInit(): void {
    this.isExpanded = false;
    this.role = localStorage.getItem('role');
    this.isSeperateExploreUniversities = !!localStorage.getItem('isSeperateExploreUniversities') || !!localStorage.getItem('isRecruiterExploreUniversities');
    if (!this.isSeperateExploreUniversities) {
      // this.store.dispatch(new GetSaveUniversityApplications(this.dialogData.salesforceId));
      // 'fullTimeTuitionFees',
      this.universityDetailsColumns = ['course', 'degree', 'internationalTuitionFees', 'STEMProgram', 'isGreGmat', 'action'];
      this.topHeaderColumn = ['dualCourse', 'dualDegree', 'tuitionFees', 'dualSTEMProgram', 'dualAction'];
    } else {
      // 'fullTimeTuitionFees'
      this.universityDetailsColumns = ['course', 'degree', 'internationalTuitionFees', 'STEMProgram', 'isGreGmat'];
      this.topHeaderColumn = ['dualCourse', 'dualDegree', 'tuitionFees', 'dualSTEMProgram'];
    }
    this.universityDetailsDatasource.data = this.dialogData?.courseList ? this.dialogData?.courseList : [];
    this.universityName = this.dialogData.university;
    this.universityWebsite = this.dialogData.website;
    this.universityDescription = this.dialogData.description;
    this.universityLogo = this.dialogData.iconUrl.replace('<p>', '').replace('</p>', '').replace('amp;', '');

    this.universityAddress = this.universityAddressKeys.filter(key => typeof this.dialogData.universityAddress[key] !== 'undefined')
      .map(key => this.dialogData.universityAddress[key]).join(', ');
    if (!this.isSeperateExploreUniversities) {
      this.subscriptions.push(
        this.store.pipe(select(studentManagementQuery.shortlistedApplications)).subscribe((result) => {
          if (result) {
            this.deleteUniversityList = result?.applications;
            if (this.successMsg) {
              this.snackbar.open('Shortlisted Successfully', '', {
                duration: 2000
              });
            }
            if (result?.applications?.length > 2) {
              this.reachedMax = true;
              for (let i = 0; i < this.dialogData?.courseList.length; i++) {
                for (let j = 0; j < result?.applications?.length; j++) {
                  if (this.dialogData.courseList[i].courseId === result?.applications[j]?.courseId) {
                    this.isShortlist[i] = true;
                  }
                }
              }
              this.loaderService.loaderFullSubject.next(false);
            } else {
              for (let i = 0; i < this.dialogData.courseList.length; i++) {
                for (let j = 0; j < result?.applications?.length; j++) {
                  if (this.dialogData.courseList[i].courseId === result?.applications[j].courseId) {
                    this.isShortlist[i] = true;
                  }
                }
              }

              this.reachedMax = false;
              this.universityData = result?.applications;
              this.loaderService.loaderFullSubject.next(false);
            }
          }
        }),
        this.actions
          .pipe(ofType(EStudentManagementActions.ApplyUniversityApplicationsFailure))
          .subscribe(({ error }: ApplyUniversityApplicationsFailure) => {
            this.loaderService.loaderFullSubject.next(false);
          }),
        // this.store.pipe(select(studentManagementQuery.saveUniversityApplications)).subscribe(async (result) => {
        //   if (result) {
        //     // console.log('save application', result);
        //     this.loaderService.loaderFullSubject.next(false);
        //     if (result.length > 6) {
        //       this.reachedMax = true;
        //       for (let i = 0; i < this.dialogData?.courseList.length; i++) {
        //         for (let j = 0; j < result.length; j++) {
        //           if (this.dialogData.courseList[i].courseId === result[j].courseId) {
        //             this.isShortlist[i] = true;
        //           }
        //         }
        //       }
        //     } else {
        //       for (let i = 0; i < this.dialogData.courseList.length; i++) {
        //         for (let j = 0; j < result.length; j++) {
        //           if (this.dialogData.courseList[i].courseId === result[j].courseId) {
        //             this.isShortlist[i] = true;
        //           }
        //         }
        //       }

        //       this.reachedMax = false;
        //       this.universityData = result;
        //     }
        //   }
        // }),

        this.store.pipe(select(userQuery.userSelect)).subscribe((user) => {
          if (user) {
            this.user = user;
          }
        }),
        this.store.pipe(select(studentManagementQuery.appliedApplications)).subscribe((applications) => {
          if (applications) {
            if (applications.length > 0) {
              this.isApplied = true;
            } else {
              this.isApplied = false;
            }
          }
        })
      );
    }
    setTimeout(() => {
      // this.store.dispatch(new GetShortlistedApplications(this.user?.salesforceId));
    }, 300);
  }

  public onClickShortListUniversity(element, index) {
    this.loaderService.loaderFullSubject.next(true);
    // (this.dialogData.isProfileCompleted && this.dialogData.isDocumentUploadCompleted)
    if (this.dialogData.isProfileCompleted) {
      this.isShortlistIndex = index;
      let applications = [];
      const contactId = this.user.salesforceId;
      // const isDraft = true;
      this.successMsg = true;
      this.universityData = this.universityData?.filter((temp) => temp.paid === false);
      this.universityData.forEach((temp) => {
        applications.push({
          country: temp.country,
          courseId: temp.courseId ? temp.courseId : null,
          courseName: temp.courseName,
          degree: temp.degree,
          id: temp.id,
          order: temp.order,
          universityId: temp.universityId ? temp.universityId : null,
          universityName: temp.universityName,
          courseFee: temp.courseFee,
          paid: temp.paid ? temp.paid : false,
        });
      });

      applications.push({
        country: this.dialogData.country,
        courseId: element.courseId ? element.courseId : null,
        courseName: element.course,
        degree: element.degree,
        id: null,
        order: this.universityData.length + 1,
        universityId: this.dialogData.universityId ? this.dialogData.universityId : null,
        universityName: this.dialogData.university,
        courseFee: this.dialogData.courseFee,
        paid: this.dialogData.paid ? this.dialogData.paid : false
      });
      applications = applications?.filter((temp) => temp.paid === false);
      const requestBody: IApplyUniversityApplications = {
        contactId,
        // isDraft,
        applications,
        deleteUniversity: this.deleteUniversityList
      };
      // this.store.dispatch(new SaveUniversityApplications(requestBody));
      this.store.dispatch(new ApplyUniversityApplications(requestBody));
    } else if (!this.dialogData.isProfileCompleted) {
      this.store.dispatch(new ShowSuccessMsg('Please submit your profile to shortlist your universities'));
    }
    // else if (!this.dialogData.isProfileCompleted || !this.dialogData.isDocumentUploadCompleted) {
    //   if (this.dialogData.isProfileCompleted && !this.dialogData.isDocumentUploadCompleted) {
    //     this.store.dispatch(new ShowSuccessMsg('Please upload the required documents to shortlist your universities'));
    //   } else if (!this.dialogData.isProfileCompleted && this.dialogData.isDocumentUploadCompleted) {
    //     this.store.dispatch(new ShowSuccessMsg('Please submit your profile to shortlist your universities'));
    //   } else {
    //     this.store.dispatch(new ShowSuccessMsg('Please submit your profile and upload the required documents to shortlist your universities'));
    //   }
    // }
  }

  ngOnDestroy() {
    // this.store.dispatch(new GetShortlistedApplicationsSuccess(null));

    // unsubscribe all subscriptions
    // this.store.dispatch(new GetSaveUniversityApplicationsSuccess(null));
    for (const i of this.subscriptions) {
      i.unsubscribe();
    }
  }
}
