import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { recruiterQuery } from './recruiter.selectors';
import {
  ERecruiterEndPoint,
  IRecruiterRequest,
  IRecruiterLogin,
  IRecruiterSession,
  IInviteRecruiter,
  IChangeRecruiterStatus,
  IChangeStaffStatus,
  IStaff,
  ISwitchNotification,
  IDeleteStaff,
  IBankingInformation
} from '../model/recruiter.model';

@Injectable({
  providedIn: 'root'
  })
export class RecruiterService {
  public baseUrl = environment.baseUrl;
  public recruiterLoginDetail: IRecruiterSession;

  constructor(
    private http: HttpClient,
    readonly store: Store
  ) {
    store.pipe(select(recruiterQuery.recruiterSessionSelect)).subscribe((recruiter: IRecruiterSession) => {
      this.recruiterLoginDetail = recruiter;
    });
  }

  public addRecruiter(requestBody: IRecruiterRequest): Observable<IRecruiterRequest> {
    const url: string = this.baseUrl + ERecruiterEndPoint.ADD_RECRUITER;
    return this.http.post<IRecruiterRequest>(url, requestBody);
  }

  public addRecruiterOrganization(requestBody: IRecruiterRequest): Observable<IRecruiterRequest> {
    const url: string = this.baseUrl + ERecruiterEndPoint.ADD_RECRUITER_ORGANIZATION;
    return this.http.post<IRecruiterRequest>(url, requestBody);
  }

  public registerInvitedRecruiterFreelancer(requestBody: IRecruiterRequest): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.REGISTER_INVITED_RECRUITER_FREELANCER;
    return this.http.post<any>(url, requestBody);
  }

  public loginRecruiterFromService(requestBody: IRecruiterLogin): Observable<IRecruiterSession> {
    const url: string = this.baseUrl + ERecruiterEndPoint.LOGIN_RECRUITER;
    return this.http.post<IRecruiterSession>(url, requestBody);
  }

  public getRecruiterProfileFromService(id: number): Observable<IRecruiterRequest> {
    const url: string = this.baseUrl + ERecruiterEndPoint.GET_RECRUITER_PROFILE + id;
    return this.http.get<any>(url);
  }

  public editRecruiterFreelancerProfileFromService(requestBody: IRecruiterRequest): Observable<IRecruiterRequest> {
    const url: string = this.baseUrl + ERecruiterEndPoint.EDIT_RECRUITER_FREELANCER_PROFILE;
    return this.http.post<IRecruiterRequest>(url, requestBody);
  }

  public editRecruiterOrganizationProfileFromService(requestBody: IRecruiterRequest): Observable<IRecruiterRequest> {
    const url: string = this.baseUrl + ERecruiterEndPoint.EDIT_RECRUITER_ORGANIZATION_PROFILE;
    return this.http.post<IRecruiterRequest>(url, requestBody);
  }

  public notifyStudentCompletionFromService(email: string) {
    const university = localStorage.getItem('university') || 'HUB';
    const url: string = this.baseUrl + ERecruiterEndPoint.NOTIFY_STUDENT_COMPLETION;
    const requestBody = {
      studentEmail: email,
      university
    };
    return this.http.post<any>(url, requestBody);
  }

  public getNotificationFromService(): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.GET_RECRUITER_NOTIFICATION;
    const params = new HttpParams()
      .set('recruiterId', String(this.recruiterLoginDetail?.userId));
    return this.http.get<any>(url, { params });
  }

  public getAssociateNotificationFromService(): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.GET_RECRUITER_ASSOCIATE_NOTIFICATION;
    const params = new HttpParams()
      .set('recruiterAssociateId', String(this.recruiterLoginDetail?.userId));
    return this.http.get<any>(url, { params });
  }

  public switchNotificationFromService(enableNotification: boolean): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.SWITCH_NOTIFICATION;
    const requestBody: ISwitchNotification = {
      recruiterId: this.recruiterLoginDetail.userId,
      enableNotification
    };

    return this.http.put<any>(url, requestBody);
  }

  public switchAssociateNotificationFromService(enableNotification: boolean): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.SWITCH_ASSOCIATE_NOTIFICATION;
    const requestBody: ISwitchNotification = {
      recruiterId: this.recruiterLoginDetail.userId,
      enableNotification
    };

    return this.http.put<any>(url, requestBody);
  }

  public inviteRecruiterFromService(requestBody: IInviteRecruiter): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.INVITE_RECRUITER;
    return this.http.post<IInviteRecruiter>(url, requestBody);
  }

  public getRecruiterListFromService(id: number): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.GET_RECRUITER_LIST;
    const params = new HttpParams()
      .set('id', String(id));
    return this.http.get<any>(url, { params });
  }

  public changeRecruiterStatusFromService(requestBody: IChangeRecruiterStatus) {
    const url: string = this.baseUrl + ERecruiterEndPoint.CHANGE_RECRUITER_STATUS;
    return this.http.post<any>(url, requestBody);
  }

  public inviteStaffFromService(requestBody: IStaff): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.INVITE_STAFF;
    return this.http.post<any>(url, requestBody);
  }

  public getStaffListFromService(recruiterId: number, recruiterSalesforceId: string): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.GET_STAFF_LIST;
    const params = new HttpParams()
      .set('recruiterId', String(recruiterId))
      .set('recruiterSalesforceId', String(recruiterSalesforceId));
    return this.http.get<any>(url, { params });
  }

  public changeStaffStatusFromService(requestBody: IChangeStaffStatus) {
    const url: string = this.baseUrl + ERecruiterEndPoint.CHANGE_STAFF_STATUS;
    return this.http.put<any>(url, requestBody);
  }

  public deleteStaffFromService(deleteStaff: IDeleteStaff) {
    const url: string = this.baseUrl + ERecruiterEndPoint.DELETE_STAFF;
    const params = new HttpParams()
      .set('id', String(deleteStaff.id))
      .set('salesforceId', String(deleteStaff.salesforceId));
    return this.http.delete<any>(url, { params });
  }

  public updateStaffProfileFromService(requestBody: IStaff) {
    const url: string = this.baseUrl + ERecruiterEndPoint.UPDATE_STAFF_PROFILE;
    return this.http.put<any>(url, requestBody);
  }

  public saveBankingInformationFromService(requestBody: IBankingInformation) {
    const url: string = this.baseUrl + ERecruiterEndPoint.SAVE_BANKING_INFORMATION;
    return this.http.post<any>(url, requestBody);
  }

  public getBankingInformationFromService() {
    const url: string = this.baseUrl + ERecruiterEndPoint.GET_BANKING_INFORMATION;
    const params = new HttpParams()
      .set('recruiterId', String(this.recruiterLoginDetail.userId))
      .set('recruiterSalesforceId', String(this.recruiterLoginDetail.salesforceId));

    return this.http.get<IBankingInformation>(url, { params });
  }

  public getCommissionPolicyFromService(): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.GET_COMMISSION_POLICY;
    return this.http.get<any>(url);
  }

  public updateNotificaionFromService(data): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.UPDATE_RECRUITER_NOTIFICATION;
    return this.http.post<any>(url, data);
  }

  public updateAssociateNotificaionFromService(data): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.UPDATE_RECRUITER_ASSOCIATE_NOTIFICATION;
    return this.http.post<any>(url, data);
  }

  public reAssignStudentFromService(data): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.RE_ASSIGN_STUDENT;
    return this.http.post<any>(url, data);
  }

  public getShortlistedApplicationsFromService(contactId) {
    const url: string = this.baseUrl + ERecruiterEndPoint.GET_RECRUITER_STUDENT_SHORTLISTED_APPLICATION;
    const params = new HttpParams()
      .set('contactId', contactId);

    return this.http.get<any>(url, { params });
  }

  public changeStudentCategoryListsFromService(data): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.CHANGE_STUDENT_CATEGORY;
    return this.http.post<any>(url, data);
  }

  public getAllSound(): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.LIST_ALL_SOUNDS;
    return this.http.get<any>(url);
  }

  public getSoundById(data): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.LIST_SOUNDS_ID + data;
    return this.http.get<any>(url, data);
  }

  public updateSound(data): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.UPDATE_RECRUITER_SOUND;
    return this.http.post<any>(url, data);
  }
  public notificationSubscribe(data): Observable<any> {
    const url: string = this.baseUrl + ERecruiterEndPoint.UPDATE_NOTIFICATION_SUBSCRIPTION;
    return this.http.post<any>(url, data);
  }
}
