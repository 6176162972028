import { IRecruiterState, initialRecruiterState } from './recruiter.state';
import { RecruiterActions, ERecruiterActions } from './recruiter.actions';

export const RecruiterReducers = (
  state: IRecruiterState = initialRecruiterState,
  action: RecruiterActions
): IRecruiterState => {
  switch (action.type) {
    case ERecruiterActions.AddRecruiterSuccess: {
      return {
        ...state,
        recruiterProfile: action.recruiterProfile
      };
    }

    case ERecruiterActions.LoginRecruiterSuccess: {
      return {
        ...state,
        recruiter: action.recruiterSession
      };
    }

    case ERecruiterActions.GetRecruiterListSuccess: {
      return {
        ...state,
        recruiterList: action.recruiterList
      };
    }

    case ERecruiterActions.GetStaffListSuccess: {
      return {
        ...state,
        staffList: action.staffList
      };
    }

    case ERecruiterActions.GetBankingInformationSuccess: {
      return {
        ...state,
        bankingInformation: action.bankingInformation
      };
    }

    case ERecruiterActions.GetCommissionPolicySuccess: {
      return {
        ...state,
        commissionPolicy: action.commissionPolicy
      };
    }

    case ERecruiterActions.GetRecruiterNotificationSuccess: {
      return {
        ...state,
        recruiterNotification: action.recruiterNotification
      };
    }

    case ERecruiterActions.GetRecruiterAssociateNotificationSuccess: {
      return {
        ...state,
        recruiterNotification: action.recruiterAssociateNotification
      };
    }

    case ERecruiterActions.getRecruiterStudentShorlistedApplicationSuccess: {
      return {
        ...state,
        getRecruiterStudentShorlistedApplication: action.recruiterStudentShorlistedApplication
      };
    }

    case ERecruiterActions.GetAllSoundSuccess: {
      return {
        ...state,
        soundList: action.soundList
      };
    }

    default:
      return state;
  }
};
