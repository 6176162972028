<!-- <div id="close-button">
  <button mat-icon-button color="warn" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content fxLayout="column" fxLayoutAlign="center center" class="h-100">
  <p fxLayoutAlign="center center" class="m-b-10" id="header-size">
    <mat-icon [style.color]="status.color" class="m-r-10">{{status.icon}}</mat-icon>
    <span>{{header}}</span>
  </p>
  <p class="text-center">{{message}}</p>
</div> -->
<mat-dialog-content>
  <div fxLayout="column" fxLayoutAlign="center center">
    <!-- <div id="icon-circle" [style.border-color]="status.color"> -->
    <mat-icon *ngIf="this.header !='Error'" [style.color]="status.color">{{status.icon}}</mat-icon>
    <!-- </div> -->
    <p fxLayoutAlign="center center" class="m-b-10" id="header-size"> {{header}} </p>
    <!-- <p fxLayoutAlign="center center" class="ta-justify" [innerHTML]="message"></p> -->
    <div class="ta-justify" [innerHTML]="message"></div>
  </div>

  <div fxLayoutAlign="center center" fxLayoutGap="12px" class="m-t-20">
    <button mat-raised-button class="bg-success text-white" mat-dialog-close>
      Okay
    </button>
  </div>
</mat-dialog-content>