import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FullComponent } from './full/full.component';
import { AppHeaderComponent } from './full/header/header.component';
import { SpinnerComponent } from '../shared/spinner.component';
import { AppBlankComponent } from './blank/blank.component';
import { AppSidebarComponent } from './full/sidebar/sidebar.component';
import { AppBreadcrumbComponent } from './full/breadcrumb/breadcrumb.component';
import { AskComponent } from '../shared/components/ask-question/ask-question.component';
import { SharedModule } from '../shared/shared.module';
import { NotificationSettingsComponent } from './full/header/notification-settings/notification-settings.component';

@NgModule({
  declarations: [
  FullComponent,
  AppHeaderComponent,
  SpinnerComponent,
  AppBlankComponent,
  AppSidebarComponent,
  AppBreadcrumbComponent,
  AskComponent,
  NotificationSettingsComponent
  ],
  imports: [
  CommonModule,
  SharedModule,
  ],
  exports:[]
  })
export class LayoutsModule { }
