<mat-form-field appearance="outline" class="w-100" *ngIf="requiredMessage === 'null'">
  <input matInput type="text" [matAutocomplete]="auto" matAutocompletePosition="below" [formControl]="inputControl"
    [placeholder]="placeholder" (blur)="onTouched()" (keydown)="onTouched()" (keyup)="onTouched()" />
  <mat-icon matSuffix style="color: #757575;">arrow_drop_down</mat-icon>
  <mat-error *ngIf="!inputControl.valid && inputControl.errors?.required">
    {{requiredMessage}}
  </mat-error>
  <mat-error *ngIf="inputControl.errors?.program && !inputControl.errors?.required">
    {{programMessage}}
  </mat-error>
</mat-form-field>

<mat-form-field appearance="outline" class="w-100" *ngIf="requiredMessage !== 'null'">
  <input matInput type="text" [matAutocomplete]="auto" matAutocompletePosition="below" [formControl]="inputControl"
    [placeholder]="placeholder" (blur)="onTouched()" required [disabled]="disabled" />
  <mat-icon matSuffix style="color: #757575;">arrow_drop_down</mat-icon>
  <mat-error *ngIf="!inputControl.valid && inputControl.errors?.required">
    {{requiredMessage}}
  </mat-error>
  <mat-error *ngIf="inputControl.errors?.program && !inputControl.errors?.required">
    {{programMessage}}
  </mat-error>
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn"
  (optionSelected)="onSelectOption($event)">
  <mat-option *ngIf="isSearching" disabled="true">
    <em>Searching...</em>
  </mat-option>

  <ng-container *ngIf="!isSearching && !isFullArrayOption">
    <mat-option *ngFor="let option of options" [value]="option.name" class="provided">
      <div><span style="font-size: 12px;">{{option?.long || ''}} {{ option.name?'('+option.name+')':''  }}</span></div>
    </mat-option>
  </ng-container>

  <ng-container *ngIf="isFullArrayOption">
    <mat-option *ngFor="let option of fullArrayOption" [value]="option?.name" [disabled]="option.disable">
      <!-- {{ option.name }} -->
      <div><span style="font-size: 12px;">{{option?.long || ''}} {{ option.name?'('+option.name+')':''  }}</span></div>
    </mat-option>
  </ng-container>

  <mat-option *ngIf="!isSearching && noResults && !isFullArrayOption" value="" disabled="true">
    <b>No results found</b>
  </mat-option>
</mat-autocomplete>