import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { Recruiter } from '../../store/model/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private router: Router) { }

  private userList = [
    {
      phone: '9899989887', password: 'password', email: 'admin@shortcode.tech', role: 'admin'
    },
    {
      phone: '8767857687', password: 'password', email: 'student@shortcode.tech', role: 'student'
    },
    {
      phone: '5435356566', password: 'password', email: 'employee@shortcode.tech', role: 'employee'
    },
  ];

  public userSubject = new BehaviorSubject<any>(this.userList.slice());

  public addUsers(user) {
    if (!this.userList.filter(us => us.email === user.email).length) {
      this.userList.push(user);
      this.userSubject.next(this.userList.slice());
    }
  }

  setLoggedInUser(loggedInUserData: { phone: number; email: string; role: string }) {
    localStorage.setItem('loginData', JSON.stringify(loggedInUserData));
    localStorage.setItem('role', loggedInUserData.role);
    localStorage.setItem('temp_role', loggedInUserData.role);
    if (loggedInUserData.role === 'STUDENT') {
      localStorage.setItem('loginRole', 'STUDENT');
    } else if (Recruiter(loggedInUserData.role) === 'RECRUITER') {
      localStorage.setItem('loginRole', 'RECRUITER');
    }
  }

  getLoggedInUser(): { phone: number; email: string; role: string } {
    const userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

    if (userData) {
      return userData;
    }
    // this.router.navigate(['authentication', 'login']);
    return null;
  }
}
