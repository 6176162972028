import { Action } from '@ngrx/store';

import {
  IInviteStudent,
  IRecruiterRequest,
  IRecruiterLogin,
  IRecruiterSession,
  IInviteRecruiter,
  IChangeRecruiterStatus,
  IChangeStaffStatus,
  IStaffList,
  IStaff,
  IDeleteStaff,
  IBankingInformation
} from '../model/recruiter.model';

export enum ERecruiterActions {
  AddRecruiter = '[User] AddRecruiter',
  AddRecruiterSuccess = '[User] AddRecruiter Success',
  AddRecruiterFailure = '[User] AddRecruiter Failure',
  AddRecruiterOrganization = '[User] AddRecruiterOrganization',
  AddRecruiterOrganizationSuccess = '[User] AddRecruiterOrganization Success',
  AddRecruiterOrganizationFailure = '[User] AddRecruiterOrganization Failure',
  LoginRecruiter = '[User] Login Recruiter',
  LoginRecruiterSuccess = '[User] Login Recruiter Success',
  LoginRecruiterFailure = '[User] Login Recruiter Failure',
  RecruiterProfile = '[User] Recruiter Profile',
  EditRecruiterFreelancerProfile = '[User] Edit Recruiter Freelancer Profile',
  EditRecruiterOrganizationProfile = '[User] Edit Recruiter Organization Profile',
  InviteRecruiter = '[User] Invite Recruiter',
  GetRecruiterList = '[User] Get Recruiter List',
  GetRecruiterListSuccess = '[User] Get Recruiter List Success',
  RegisterInvitedRecruiterFreelancer = '[User] Register Invited Recruiter Freelancer',
  ChangeRecruiterStatus = '[User] Change Recruiter Status',
  InviteStaff = '[User] Invite Staff',
  GetStaffList = '[User] Get Staff List',
  GetStaffListSuccess = '[User] Get Staff List Success',
  ChangeStaffStatus = '[User] Change Staff Status',
  DeleteStaff = '[User] Change Delete Status',
  UpdateStaffProfile = '[User] Update Staff Profile',
  SaveBankingInformation = '[User] Save Banking Information',
  GetBankingInformation = '[User] Get Banking Information',
  GetBankingInformationSuccess = '[User] Get Banking Information Success',
  GetCommissionPolicy = '[User] Get Commission Policy',
  GetCommissionPolicySuccess = '[User] Get Commission Policy Success',
  GetRecruiterNotification = '[Student] Get Recruiter Notification',
  GetRecruiterAssociateNotification = '[Student] Get Recruiter Associate Notification',
  GetRecruiterNotificationSuccess = '[Student] Get Recruiter Notification Success',
  GetRecruiterAssociateNotificationSuccess = '[Student] Get Recruiter Associate Notification Success',
  ReAssignStudent = '[Student] Re Assign Student',
  getRecruiterStudentShorlistedApplication = '[Student] Get Recruiter Student Shorlisted Application',
  getRecruiterStudentShorlistedApplicationSuccess = '[Student] Get Recruiter Student Shorlisted Application Success',
  ChangeStudentCategory = '[Student] Change Student Category',
  GetAllSound = '[Sound] Get All Sound',
  GetAllSoundSuccess = '[Sound] Get All Sound Success',
  GetSoundById = '[Sound] Get Sound By Id',
  GetSoundByIdSuccess = '[Sound] Get Sound By Id Success',
  UpdateSound = '[Sound] Update Sound',
  NotificationSubscibe = '[User] Notification Subscribe'
}

export class RecruiterAction implements Action {
  readonly type: string;
  recruiterProfile?: IRecruiterRequest;
  recruiterSession?: IRecruiterSession;
  inviteStudent?: IInviteStudent;
  inviteRecruiter?: IInviteRecruiter;
  recruiterList?: any;
  staffList?: IStaffList[];
  bankingInformation?: IBankingInformation;
  commissionPolicy?: any;
  soundList?: any;
}

export class AddRecruiter implements RecruiterAction {
  public readonly type = ERecruiterActions.AddRecruiter;
  constructor(public recruiterProfile: IRecruiterRequest) { }
}

export class AddRecruiterSuccess implements RecruiterAction {
  public readonly type = ERecruiterActions.AddRecruiterSuccess;
  constructor(public recruiterProfile: IRecruiterRequest) { }
}

export class AddRecruiterFailure implements RecruiterAction {
  readonly type = ERecruiterActions.AddRecruiterFailure;
  constructor(public error: any) { }
}

export class AddRecruiterOrganization implements RecruiterAction {
  public readonly type = ERecruiterActions.AddRecruiterOrganization;
  constructor(public recruiterProfile: IRecruiterRequest) { }
}

export class AddRecruiterOrganizationSuccess implements RecruiterAction {
  public readonly type = ERecruiterActions.AddRecruiterOrganizationSuccess;
  constructor(public recruiterProfile: IRecruiterRequest) { }
}

export class LoginRecruiter implements RecruiterAction {
  public readonly type = ERecruiterActions.LoginRecruiter;
  constructor(public payload: IRecruiterLogin) { }
}

export class LoginRecruiterSuccess implements RecruiterAction {
  public readonly type = ERecruiterActions.LoginRecruiterSuccess;
  constructor(public recruiterSession: IRecruiterSession, public role: string) { }
}

export class LoginRecruiterFailure implements RecruiterAction {
  readonly type = ERecruiterActions.LoginRecruiterFailure;
  constructor(public error: any) { }
}

export class RecruiterProfile implements RecruiterAction {
  public readonly type = ERecruiterActions.RecruiterProfile;
  constructor(public id: number) { }
}

export class EditRecruiterFreelancerProfile implements RecruiterAction {
  public readonly type = ERecruiterActions.EditRecruiterFreelancerProfile;
  constructor(public recruiterProfile: IRecruiterRequest) { }
}

export class EditRecruiterOrganizationProfile implements RecruiterAction {
  public readonly type = ERecruiterActions.EditRecruiterOrganizationProfile;
  constructor(public recruiterProfile: IRecruiterRequest) { }
}

export class InviteRecruiter implements RecruiterAction {
  public readonly type = ERecruiterActions.InviteRecruiter;
  constructor(public inviteRecruiter: IInviteRecruiter) { }
}

export class GetRecruiterList implements RecruiterAction {
  public readonly type = ERecruiterActions.GetRecruiterList;
  constructor(public id: number) { }
}

export class GetRecruiterListSuccess implements RecruiterAction {
  public readonly type = ERecruiterActions.GetRecruiterListSuccess;
  constructor(public recruiterList: any) { }
}

export class RegisterInvitedRecruiterFreelancer implements RecruiterAction {
  public readonly type = ERecruiterActions.RegisterInvitedRecruiterFreelancer;
  constructor(public recruiterProfile: IRecruiterRequest) { }
}

export class ChangeRecruiterStatus implements RecruiterAction {
  public readonly type = ERecruiterActions.ChangeRecruiterStatus;
  constructor(public changeStatus: IChangeRecruiterStatus) { }
}

export class InviteStaff implements RecruiterAction {
  public readonly type = ERecruiterActions.InviteStaff;
  constructor(public inviteStaff: IStaff) { }
}

export class GetStaffList implements RecruiterAction {
  public readonly type = ERecruiterActions.GetStaffList;
  constructor(public recruiterId: number, public recruiterSalesforceId: string) { }
}

export class GetStaffListSuccess implements RecruiterAction {
  public readonly type = ERecruiterActions.GetStaffListSuccess;
  constructor(public staffList: IStaffList[]) { }
}

export class ChangeStaffStatus implements RecruiterAction {
  public readonly type = ERecruiterActions.ChangeStaffStatus;
  constructor(public changeStatus: IChangeStaffStatus) { }
}

export class DeleteStaff implements RecruiterAction {
  public readonly type = ERecruiterActions.DeleteStaff;
  constructor(public deleteStaff: IDeleteStaff) { }
}

export class UpdateStaffProfile implements RecruiterAction {
  public readonly type = ERecruiterActions.UpdateStaffProfile;
  constructor(public updateStaff: IStaff) { }
}

export class SaveBankingInformation implements RecruiterAction {
  public readonly type = ERecruiterActions.SaveBankingInformation;
  constructor(public bankingInformation: IBankingInformation) { }
}

export class GetBankingInformation implements RecruiterAction {
  public readonly type = ERecruiterActions.GetBankingInformation;
  constructor() { }
}

export class GetBankingInformationSuccess implements RecruiterAction {
  public readonly type = ERecruiterActions.GetBankingInformationSuccess;
  constructor(public bankingInformation: IBankingInformation) { }
}

export class GetCommissionPolicy implements RecruiterAction {
  public readonly type = ERecruiterActions.GetCommissionPolicy;
  constructor() { }
}

export class GetCommissionPolicySuccess implements RecruiterAction {
  public readonly type = ERecruiterActions.GetCommissionPolicySuccess;
  constructor(public commissionPolicy: any) { }
}

export class GetRecruiterNotification implements RecruiterAction {
  public readonly type = ERecruiterActions.GetRecruiterNotification;
  constructor() { }
}

export class GetRecruiterAssociateNotification implements RecruiterAction {
  public readonly type = ERecruiterActions.GetRecruiterAssociateNotification;
  constructor() { }
}
export class GetRecruiterNotificationSuccess implements RecruiterAction {
  public readonly type = ERecruiterActions.GetRecruiterNotificationSuccess;
  constructor(public recruiterNotification: any) { }
}

export class GetRecruiterAssociateNotificationSuccess implements RecruiterAction {
  public readonly type = ERecruiterActions.GetRecruiterAssociateNotificationSuccess;
  constructor(public recruiterAssociateNotification: any) { }
}

export class ReAssignStudent implements RecruiterAction {
  public readonly type = ERecruiterActions.ReAssignStudent;
  constructor(public ReAssignStudentList: any) { }
}

export class GetRecruiterStudentShorlistedApplication implements RecruiterAction {
  public readonly type = ERecruiterActions.getRecruiterStudentShorlistedApplication;
  constructor(public recruiterSalesforceId: string) { }
}

export class GetRecruiterStudentShorlistedApplicationSuccess implements RecruiterAction {
  public readonly type = ERecruiterActions.getRecruiterStudentShorlistedApplicationSuccess;
  constructor(public recruiterStudentShorlistedApplication: any) { }
}

export class ChangeStudentCategory implements RecruiterAction {
  public readonly type = ERecruiterActions.ChangeStudentCategory;
  constructor(public changeStudentCategoryLists: any) { }
}

export class GetAllSound implements RecruiterAction {
  public readonly type = ERecruiterActions.GetAllSound;
  constructor() { }
}

export class GetAllSoundSuccess implements RecruiterAction {
  public readonly type = ERecruiterActions.GetAllSoundSuccess;
  constructor(public soundList: any) { }
}

export class GetSoundById implements RecruiterAction {
  public readonly type = ERecruiterActions.GetSoundById;
  constructor(public id: any) { }
}

export class GetSoundByIdSuccess implements RecruiterAction {
  public readonly type = ERecruiterActions.GetSoundByIdSuccess;
  constructor(public soundInfo: any) { }
}
export class UpdateSound implements RecruiterAction {
  public readonly type = ERecruiterActions.UpdateSound;
  constructor(public data: any) { }
}
export class NotificationSubscibe implements RecruiterAction {
  public readonly type = ERecruiterActions.NotificationSubscibe;
  constructor(public data: any) { }
}

export type RecruiterActions =
  | AddRecruiter
  | AddRecruiterSuccess
  | AddRecruiterFailure
  | AddRecruiterOrganization
  | AddRecruiterOrganizationSuccess
  | LoginRecruiter
  | LoginRecruiterSuccess
  | LoginRecruiterFailure
  | RecruiterProfile
  | EditRecruiterFreelancerProfile
  | InviteRecruiter
  | GetRecruiterList
  | RegisterInvitedRecruiterFreelancer
  | GetRecruiterListSuccess
  | ChangeRecruiterStatus
  | InviteStaff
  | GetStaffList
  | GetStaffListSuccess
  | ChangeStaffStatus
  | DeleteStaff
  | UpdateStaffProfile
  | SaveBankingInformation
  | GetBankingInformation
  | GetBankingInformationSuccess
  | GetCommissionPolicy
  | GetCommissionPolicySuccess
  | GetRecruiterNotification
  | GetRecruiterAssociateNotification
  | GetRecruiterNotificationSuccess
  | GetRecruiterAssociateNotificationSuccess
  | ReAssignStudent
  | GetRecruiterStudentShorlistedApplication
  | GetRecruiterStudentShorlistedApplicationSuccess
  | ChangeStudentCategory
  | GetAllSound
  | GetAllSoundSuccess
  | GetSoundById
  | GetSoundByIdSuccess
  | UpdateSound
  | NotificationSubscibe;
