import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IConfirmDialog, INoticeIcon, noticeIcon } from '../../models/shared.model';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
  })
export class ConfirmDialogComponent {
  public title: string;
  public message: string;
  public confirmButtonText: string;
  public cancelButtonText: string;
  public status: INoticeIcon;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: IConfirmDialog,
  ) {
    if (data) {
      this.title = data.title;
      this.message = data.message;
      this.confirmButtonText = data.confirmButtonText;
      this.cancelButtonText = data.cancelButtonText;
      this.status = noticeIcon[data.type];
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
