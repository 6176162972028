export const schoolAttendedRequiredModal = [
  // {
  //   display: 'block',
  //   requiredText: 'Enter the school attended for 4-year degree',
  //   levelOfEducation: ['1 Year Post Secondary Certificate', '2 Year UG Diploma',
  //     '3 Year UG Advanced Diploma', '3 Year Bachelor Degree', '4 Year Bachelor Degree']
  // },
  {
    display: 'block',
    requiredText: 'Enter the school attended for Grade 12*',
    levelOfEducation: ['Grade 12']
  },
  {
    display: 'block',
    requiredText: 'Enter the school attended for Grade 10* ',
    levelOfEducation: ['Grade 10']
  },
];

export interface IStudentApplicationForm {
  generalInformationFormGroup?: Object;
  educationSummaryFormGroup?: Object;
  attendedSchoolForm?: Object;
  testScoreFormGroup?: Object;
  greExamScoreFormGroup?: Object;
  gmatExamScoreFormGroup?: Object;
  backgroundInformation?: Object;
}

export const uploadDocumentsTableData = [
  {
    applicationId: 9872,
    schoolName: 'Sample School',
    programName: 'Master of Science - Education - International Teaching and Global Leadership Cohort',
    toApplication: '1'
  }
];

export const highLevelOfEducationFakeData = [
  /*
  {
    groupName: 'Primary',
   courses: ['Grade 1', 'Grade 2', 'Grade 3', 'Grade 4', 'Grade 5', 'Grade 6', 'Grade 7', 'Grade 8']
  },
  */
  {
    groupName: 'School',
    courses: ['Grade 10', 'Grade 12']
  },
  {
    groupName: 'Under Graduate',
    courses: ['1 Year Post Secondary Certificate', '2 Year UG Diploma',
      '3 Year UG Advanced Diploma', '3 Year Bachelor Degree', '4 Year Bachelor Degree']
  },
  {
    groupName: 'Post Graduate',
    courses: ['Postgraduate Certificate/Diploma', 'Doctorate Degree', 'Master Degree']
  },
];

export const highLevelKey = [
  {
    courses: ['Grade 10'],
    key: 'tenthGrade'
  },
  {
    courses: ['Grade 12'],
    key: 'twelthGrade'
  },
  {
    courses: ['1 Year Post Secondary Certificate'],
    key: 'oneYearPostSecCertificate'
  },
  {
    courses: ['2 Year UG Diploma'],
    key: 'twoYearUgDiploma'
  },
  {
    courses: ['3 Year UG Advanced Diploma'],
    key: 'threeYearUgAdvancedDiploma'
  },
  {
    courses: ['3 Year Bachelor Degree'],
    key: 'threeYearUgDegree'
  },
  {
    courses: ['4 Year Bachelor Degree'],
    key: 'fourYearUgDegree'
  },
  {
    courses: ['Postgraduate Certificate/Diploma'],
    key: 'pgCertificate'
  },
  {
    courses: ['Doctorate Degree'],
    key: 'doctorateDegree'
  },
  {
    courses: ['Master Degree'],
    key: 'masterDegree'
  }
];

export const degrees = ['One', 'Two', 'Three'];
export const employmentKeys = ['employment1', 'employment2', 'employment3'];

export const degreeKeys = ['tenthGrade', 'twelthGrade', 'degreeOne', 'degreeTwo', 'degreeThree'];

export const gradingSchemaFake = [
  'Letter grade: Division / class', 'Letter grade: F to AA/A+', 'Letter grade: Fail to Outstanding',
  'Letter grade: F to O', 'Scale 0-10, Scale 0-100', 'Other'
];

export const educationGradingSchemaFake = [
  'Percentage', 'Grade A to F', 'Division/class', 'GPA', 'Other'
];

export const gradeAverageFake = [
  {
    name: 'First division/First class',
    id: 1,

  },
  {
    name: 'Second class/Second Division',
    id: 2,

  },
  {
    name: 'Third class/Third division/Pass class',
    id: 3,

  },
  {
    name: 'Conceded pass/Grace marks',
    id: 4,
  },
  {
    name: 'Below minimum pass',
    id: 5,
  }
];

export const englishExamTypeFake = [
  {
    name: "I Don't have this",
    id: 1,

  },
  {
    name: 'TOEFL',
    id: 2,

  },
  {
    name: 'IELTS',
    id: 3,

  },
  {
    name: 'Duolingo English Test',
    id: 4,
  },
  {
    name: 'PTE',
    id: 5,
  }
];

export interface IStatus {
  count: number;
  icon: string;
  bgColor: string;
  toolTip: string;
}

export interface IDocument {
  required: boolean;
  status: string;
  statusIcon: string;
  statusIconColor: string;
  courseName: string;
  description: string;
  allowedDocs: string;
  docMessage: string;
  warningMessage: string;
  fileCount: number;
  uploadFile: Array<any>
  downloadUrl: string;
  multiple: boolean;
  id: string;
  uploadedTime?: string;
  fileName?: string;
}

export interface ITest {
  examType: string;
  details: {}
}

export const statusArrayFake = [
  {
    count: 0,
    icon: 'cancel_presentation',
    bgColor: 'rgb(255, 201, 173)',
    toolTip: 'Missing'
  },
  {
    count: 0,
    icon: 'assignment_turned_in',
    bgColor: 'rgb(41, 176, 228)',
    toolTip: 'Reviewing'
  },
  {
    count: 0,
    icon: 'file_upload',
    bgColor: 'rgb(255, 179, 181)',
    toolTip: 'Reupload'
  },
  {
    count: 0,
    icon: 'done_outline',
    bgColor: 'rgb(180, 244, 143)',
    toolTip: 'Approved'
  },
];

export const additionalExamData = [
  [{
    value: 'GRE',
    disable: false
  },
  {
    value: 'GMAT',
    disable: false
  }],
  [{
    value: 'GRE',
    disable: false
  },
  {
    value: 'GMAT',
    disable: false
  }]
];

export interface IAdditionalDocument {
  status: string;
  statusIcon: string;
  statusIconColor: string;
}

export const addtionalDocumentData = [{
  required: true,
  status: 'Missing',
  statusIcon: 'warning',
  statusIconColor: 'red',
  courseName: 'Addtional Documents',
  description: '',
  allowedDocs: '.pdf, .doc, .docx, .jpeg, .jpg',
  docMessage: 'Allowed docs format - pdf, doc/docx, jpeg/jpg',
  warningMessage: '',
  fileCount: 0,
  uploadFile: [],
  downloadUrl: null,
  multiple: false,
  id: ''
}];
