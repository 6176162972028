<!-- ---------------------- FAVOURITE UNIVERSITIES --------------------- -->
<mat-card appearance="outlined" class="example-card" *ngIf="filteredUniversity.length > 0">
  <mat-card-header fxLayout="column">
      <div fxFlex="100" *ngIf="filteredUniversity.length > 0">
        <small style="font-size: 13px;" *ngIf="role === 'STUDENT'"><span class="semi-bold">* Note:</span><span >Application fee may Vary. Please write to us at 'applications@universityhub.com'</span></small>
        <small style="font-size: 13px;" *ngIf="role !== 'STUDENT'"><span class="semi-bold">* Note:</span> Application fee may Vary. Please reach us at 'recruiters@universityhub.com'</small>
      </div>
    <mat-card-title fxLayoutAlign="center center">
     'Favorites'
    </mat-card-title>
  </mat-card-header>
  <mat-card-content fxLayout="column" fxLayout.gt-xs="row wrap">
    <ng-container *ngFor="let temp of filteredUniversity; let i=index">
      <div fxFlex.gt-md="50" fxFlex="100" fxLayoutGap="7px">
        <mat-card appearance="outlined" class="fancy-card m-10 cursor-pointer card-outline" fxFlex="100">
          <mat-card-header fxLayout.gt-xs="row" fxLayout="column" class="bg-w br-l-b"
            fxLayoutAlign="center start" fxLayoutAlign.gt-xs="space-between center">
            <mat-card-title class="header-lg" fxFlex>{{temp.university}}</mat-card-title>
            <div fxFlex.gt-xs="150px" fxFlex>
              <div style="width: 125px;">
                <div class="loader" *ngIf="!isImageLoading[i]">Loading...</div>
                <img class="university-logo" [src]="removeTag(temp.iconUrl)" [matTooltip]="temp.university"
                  alt="University Logo" (load)="onUniversityLogoLoaded(i)" />
              </div>
            </div>
          </mat-card-header>
          <mat-card-content class="p-12 bodyfont" >
            <div fxLayout="column" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px">
              <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign.gt-xs="space-between start"
                fxLayoutAlign="center start" class="app-content" fxLayoutGap="20px">
                <div fxLayout="row" fxLayoutGap="5px" fxFlex="50">
                    <mat-icon matTooltip="Location">location_on</mat-icon>
                      <div matTooltip="Location" style="word-break: break-all;">
                        <span class="semi-bold">Location: </span>
                        {{temp?.universityAddress?.city ? temp?.universityAddress?.city : 'N/A' + ' ,' 
                        + temp?.universityAddress?.state ? temp?.universityAddress?.state : 'N/A' + ' ,' 
                        + temp?.universityAddress?.country ? temp?.universityAddress?.country : 'N/A'}}
                      </div>
                </div>
                <div fxLayout="row" fxLayoutGap="5px" fxFlex="50">
                  <mat-icon matTooltip="Number of campus">account_balance</mat-icon>
                      <div matTooltip="Number of campus" style="word-break: break-all;">
                        <span class="semi-bold">Number of campus: </span>
                        {{temp.tileLevelNumberOfCampus ? temp.tileLevelNumberOfCampus : 'N/A'}}
                      </div>
                </div>
              </div>
              <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign.gt-xs="space-between start"
              fxLayoutAlign="center start" class="app-content" fxLayoutGap="20px">
                <div fxLayout="row" fxLayoutGap="5px" fxFlex="50">
                  <mat-icon matTooltip="Intake">school</mat-icon>
                    <div matTooltip="Intake" style="word-break: break-all;">
                      <span class="semi-bold">Intake: </span>{{temp.tileLevelIntake ? temp.tileLevelIntake : 'N/A'}}
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="5px" fxFlex="50">
                  <mat-icon matTooltip="Living cost">home</mat-icon>
                  <div matTooltip="Living cost" style="word-break: break-all;">
                    <span class="semi-bold">Living cost: </span>{{temp.tileLevelLivingCost ? temp.tileLevelLivingCost : 'N/A'}}
                  </div>
                </div>
              </div>
              <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign.gt-xs="space-between start"
              fxLayoutAlign="center start" class="app-content" fxLayoutGap="20px">
                <div fxLayout="row" fxLayoutGap="5px" fxFlex="50">
                  <mat-icon matTooltip="WES Evaluation or Others">assignment</mat-icon>
                  <div fxLayout="row">
                    <div matTooltip="WES Evaluation or Others">
                      <span class="semi-bold"> WES Evaluation or Others </span>
                      : {{temp.wesEvaluation ? temp.wesEvaluation : 'N/A'}}
                    </div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutGap="5px" fxFlex="50">
                  <div class="fontawesome-div" fxLayoutAlign="center center">
                    <i matTooltip="English Proficiency Required" class="fa fa-book fa-lg" fxLayoutAlign="center center"></i>
                  </div>
                  <div fxLayout="row">
                    <div matTooltip="English Proficiency Required">
                      <span class="semi-bold">English Proficiency Required</span>
                      :{{temp.tileLevelEnglishProficiency ? temp.tileLevelEnglishProficiency : 'N/A'}}
                    </div>
                  </div>
                </div>
              </div>
              <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign.gt-xs="space-between start"
              fxLayoutAlign="center start" class="app-content" fxLayoutGap="20px">
                <div fxLayout="row" fxLayoutGap="5px">
                  <mat-icon matTooltip="Application fee">
                    monetization_on
                  </mat-icon>
                  <div matTooltip="Application fee">
                    <span class="semi-bold">Application fee :</span> {{temp.tileLevelApplicationFees ? temp.tileLevelApplicationFees : 'N/A'}}
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
    <div class="p-text" *ngIf="filteredUniversity.length === 0" fxLayoutAlign="center center" fxFlex="100">
     'No favorite(s) found'
    </div>
  </mat-card-content>
</mat-card>
<!-- ---------------------  Admin Proposed Universities ---------------------- -->
<mat-card appearance="outlined" *ngIf="appliedApplicationsDatasource.data.length > 0 && false" class="testing-card">
  <mat-card-header>
    <mat-card-title>{{ (isSavedAsDraft) ? 'Saved' : 'Shortlisted'}} Universities</mat-card-title>
  </mat-card-header>

  <mat-card-content class="p-t-0">
    <div class="responsive-table responsive-first-table">

      <table #appliedApplicationsTableRef mat-table [dataSource]="appliedApplicationsDatasource"
        class="mat-elevation-z8" cdkDropList [cdkDropListData]="appliedApplicationsDatasource"
        (cdkDropListDropped)="drapDropTable($event)">

        <!-- ORDER OF PREFERENCE -->
        <ng-container matColumnDef="order">
          <th mat-header-cell *matHeaderCellDef> {{ 'ORDER' | titlecase }} </th>
          <td mat-cell *matCellDef="let element">
            {{ element.order }}
          </td>
        </ng-container>

        <!-- UNIVERSITY NAME -->
        <ng-container matColumnDef="universityName">
          <th mat-header-cell *matHeaderCellDef> {{'UNIVERSITY' | titlecase}} </th>
          <td mat-cell *matCellDef="let element">
            {{ element.universityName }}
          </td>
        </ng-container>

        <!-- COURSE NAME -->
        <ng-container matColumnDef="courseName">
          <th mat-header-cell *matHeaderCellDef> {{'COURSE' | titlecase}} </th>
          <td mat-cell *matCellDef="let element">
            {{ element.courseName }}
          </td>
        </ng-container>

        <!-- COUNTRY -->
        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef> {{'COUNTRY' | titlecase}} </th>
          <td mat-cell *matCellDef="let element">
            {{ element.country }}
          </td>
        </ng-container>

        <!-- DEGREE -->
        <ng-container matColumnDef="degree">
          <th mat-header-cell *matHeaderCellDef> {{'DEGREE' | titlecase}} </th>
          <td mat-cell *matCellDef="let element">
            {{ element.degree }}
          </td>
        </ng-container>

        <!-- ACTION -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> {{'ACTION' | titlecase}} </th>
          <td mat-cell *matCellDef="let element; index as i">
            <div fxLayout="row" fxLayoutGap="6px">
              <button mat-mini-fab color="primary" (click)="onClickEditSavedUniversity(i)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-mini-fab class="bg-danger text-white" (click)="onClickDeleteAppliedUniversity(i)">
                <mat-icon>delete</mat-icon>
              </button>
              <button mat-mini-fab cdkDragHandle class="bg-white text-dark">
                <mat-icon>drag_indicator</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="appliedApplicationsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: appliedApplicationsColumns;" cdkDrag
          [cdkDragDisabled]="!isSavedAsDraft" [cdkDragData]="row" class="drag-custom-placeholder"></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>

<!-- -------------------------------- Form (to search university) --------------------------------- -->
<mat-card appearance="outlined" *ngIf="shortlistedApplicationsDatasource.data.length <= 0 && isProfileCompleted && false " class="testing-card">
  <mat-card-header>
    <mat-card-title>
      {{ (isSavedAsDraft) ? 'Add/Edit' : 'Shortlisted' }} Universities (Order of Preference)
    </mat-card-title>
  </mat-card-header>

  <mat-card-content class="p-t-0">
    <ng-template [ngIf]="!isSavedAsDraft">
      <p class="app-content">*You can shortlist upto 7 Universities. We will propose you 3 eligible Universities</p><br />
    </ng-template>

    <div cdkDropList (cdkDropListDropped)="drop($event)" class="example-list">
      <div *ngFor="let control of universityFormArray.controls; index as i" cdkDrag
        class="m-b-18">
        <form fxLayout.gt-md="row" fxLayout="column" fxLayoutGap="18px" [formGroup]="control">

          <!-- Count -->
          <p *ngIf="!isSavedAsDraft" fxLayoutAlign.gt-md="center center">{{ i+1 }}.</p> &nbsp;

          <!-- Country -->
          <mat-form-field fxFlex>
            <input type="text" placeholder="Search Country" matInput formControlName="country"
              [matAutocomplete]="countryAutocomplete" (focus)="onFocusOutCountry($event, i)"
              (blur)="onBlurCountry($event.target.value, i)" (focusout) = "onChangeCountry($event, i)"  > 

            <mat-autocomplete #countryAutocomplete="matAutocomplete">
              <mat-option *ngFor="let option of countriesObservable[i] | async" [value]="option.country">
                {{option.country}}
              </mat-option>
            </mat-autocomplete>

            <mat-error *ngIf="control.controls['country'].hasError('required') &&
                                    control.controls['country'].touched">
              Please Select Country
            </mat-error>
          </mat-form-field>

          <!-- Degree -->
          <mat-form-field fxFlex>
            <input type="text" placeholder="Search Degree" matInput formControlName="degree"
              [matAutocomplete]="degreeAutocomplete"  (blur)="onBlurDegree($event.target.value, i)"
              (focus)="(degreesObservable[i]) ? onFocusOutDegree($event, i) : null" 
              (focusout) = "onChangeDegree($event, i)"
              [readonly]="!control.controls['country'].value"> 

            <mat-hint *ngIf="!control.controls['degree'].value">
              Please select country before selecting degree
            </mat-hint>

            <mat-autocomplete #degreeAutocomplete="matAutocomplete">
              <mat-option *ngFor="let option of degreesObservable[i] | async" [value]="option">
                {{option}}
              </mat-option>
            </mat-autocomplete>

            <mat-error *ngIf="control.controls['degree'].hasError('required') &&
                                    control.controls['degree'].touched">
              Please Select Degree
            </mat-error>
          </mat-form-field>

          <!-- Course -->
          <mat-form-field fxFlex>
            <input type="text" placeholder="Search Course" matInput formControlName="course"
              [matAutocomplete]="courseAutocomplete"
              (focus)="(courseListObservable[i]) ? onFocusOutCourse($event, i) : null"
              (blur)="onBlurCourse($event.target.value, i)" (focusout) = "onChangeCourse($event, i)"
               [readonly]="!courseList[i]" >

            <mat-autocomplete #courseAutocomplete="matAutocomplete" [displayWith]="courseDisplayFn" class="width-300px">
              <mat-option *ngFor="let course of courseListObservable[i] | async" [value]="course"
                [matTooltip]="course.courseName">
                {{course.courseName}}
              </mat-option>
            </mat-autocomplete>

            <mat-error *ngIf="control.controls['course'].hasError('required') &&
                                    control.controls['course'].touched">
              Please Select Course
            </mat-error>
          </mat-form-field>

          <!-- University -->
          <mat-form-field fxFlex>
            <input type="text" placeholder="Search University" matInput formControlName="university"
              [matAutocomplete]="universityAutocomplete"
              (focusout)="(universityListObservable[i]) ? onFocusOutUniversity($event, i) : null"
              [readonly]="!universityList[i]">

            <mat-autocomplete #universityAutocomplete="matAutocomplete" [displayWith]="universityDisplayFn"
              class="width-300px" (optionSelected)='onUniversityOptionSelected(i)'>
              <mat-option *ngFor="let university of universityListObservable[i] | async" [value]="university"
                [matTooltip]="university.universityName">
                {{university.universityName}}
              </mat-option>
            </mat-autocomplete>

            <mat-error *ngIf="control.controls['university'].hasError('required') &&
                                    control.controls['university'].touched">
              Please Select University
            </mat-error>
          </mat-form-field>

          <!-- Controls -->
          <div *ngIf="!isSavedAsDraft" fxFlex fxLayout="row">
            <button type="button" mat-mini-fab cdkDragHandle class="bg-white text-dark"
              matTooltip="Rearrange University Order">
              <mat-icon>drag_indicator</mat-icon>
            </button>&nbsp;

            <!-- <ng-template [ngIf]="universityFormArray.length < 2">
              <button type="button" mat-mini-fab class="bg-danger text-white" [disabled]="control.invalid"  (click)="onClickClearTheForm(i)" matTooltip="Clear">
                <mat-icon>clear</mat-icon>
              </button>
            </ng-template> -->

            <!-- [disabled]="universityFormArray.length <= 1" -->
            <button type="button" mat-mini-fab (click)="onClickRemoveUniversity(i)" matTooltip="Remove"
            class="bg-danger text-white" >
              <mat-icon>close</mat-icon>
            </button>&nbsp;
            <ng-template [ngIf]="universityFormArray.length - 1 === i">
              <button type="button" mat-mini-fab color="accent" (click)="onClickAddUniversity()"
                matTooltip="Add" [disabled]="universityFormArray.length > 6 || control.invalid || isDuplicateCourse.includes(true)">
                <mat-icon>add</mat-icon>
              </button>
            </ng-template>
          </div>

          <div *ngIf="isSavedAsDraft" fxFlex fxLayout="row">
            <button mat-mini-fab class="bg-success text-white" (click)="onClickAddUniversityIntoTable()"
              [disabled]="appliedApplicationsDatasource.data.length > 6 || control.invalid" matTooltip="Save">
              <mat-icon>check</mat-icon>
            </button>&nbsp;

            <button type="button" mat-mini-fab class="bg-danger text-white" matTooltip="Clear">
              <mat-icon>clear</mat-icon>
            </button>
          </div>

        </form>
      </div>
      <p *ngIf="isShorlistedInvalidCourse" class="text-danger"><small>*Some of the universities shortlisted above, are not valid</small></p><br />
      <p *ngIf="!isDocumentUploadCompleted" class="text-danger"><small>Please upload the required documents to submit your universities*</small></p><br />
    </div>

  </mat-card-content>

  <mat-card-actions *ngIf="!isSavedAsDraft" align="end" class="p-r-15 p-b-15">
    <!-- [disabled]="user?.isLock || universityFormArray.invalid" -->
    <!-- [disabled]="universityFormArray.invalid" -->
    <!-- <button mat-flat-button color="primary" class="m-l-15" (click)="onClickSubmit(true)"
      [disabled]="(universityFormArray.invalid && universityFormArray.touched) || isDuplicateCourse.includes(true)">
      Save As Draft
    </button> -->

    <button mat-flat-button class="bg-success text-white m-l-15" (click)="onClickSubmit(false)"
      [disabled]="user?.isLock || universityFormArray.invalid || isShorlistedInvalidCourse 
      || !(isProfileCompleted && isDocumentUploadCompleted) || isDuplicateCourse.includes(true)">
      Submit
    </button>
  </mat-card-actions>
</mat-card>

<!-- SHOW MESSAGE -->
<mat-card appearance="outlined" *ngIf="appliedApplicationsDatasource.data.length <= 0 && !isProfileCompleted" class="testing-card">
  <mat-card-header>
    <mat-card-title></mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <!-- <h4> {{(isProfileCompleted && !isDocumentUploadCompleted) ? 
      'Please upload the required documents to shortlist your universities. To upload document please' :
      ((!isProfileCompleted && isDocumentUploadCompleted) ? 'Please submit your profile to shortlist your universities. To fill profile form please' :
      'Please submit your profile and upload the required documents to shortlist your universities')}}
      <a [routerLink]="(isProfileCompleted && !isDocumentUploadCompleted) ? ['/documents'] : ['/student-profile']" (click)="onClickLocalStorage()">click here</a>
    </h4> -->
    <div class="bodyfont"> Please submit your profile to shortlist your universities.</div>
    <div class="bodyfont"> Please submit your profile to shortlist your universities. To fill profile form please
      <a routerLink="/student-profile" (click)="onClickLocalStorage()">click here</a>
    </div>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" *ngIf="showMsg && isProfileCompleted && !isRecruiterPart" class="testing-card">
  <mat-card-header>
    <mat-card-title></mat-card-title> 
  </mat-card-header>

  <mat-card-content>
    <h4> Please  shortlist  universities From Explore Institute. <a routerLink= "/explore-universities">Click Here</a></h4>
  </mat-card-content>

</mat-card>
<!-- Recruiter Message -->
<mat-card appearance="outlined" *ngIf="showMsg && isProfileCompleted && isRecruiterPart" class="testing-card">
  <mat-card-header>
    <mat-card-title></mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <h4> Please  shortlist  universities From Explore Institute. <a routerLink="/student-management/student_explore_institutes">Click Here</a></h4>
  </mat-card-content>

</mat-card>
<!-- ---------------------Applied/Saved Universities  --------------------- -->

<mat-card appearance="outlined" *ngIf="shortlistedApplicationsDatasource.data.length >= 0 && !isEdit && !showMsg" class="testing-card">
  <mat-card-header fxLayoutAlign="space-between center">
    <mat-card-title>Shortlisted Universities </mat-card-title>
    <button mat-raised-button color="primary" *ngIf="!hideEdit" (click)="editUniversity()">Edit</button>
 <!-- <mat-card-title>Recommended Universities</mat-card-title> -->
</mat-card-header>

  
  

  <mat-card-content *ngIf="shortlistedApplicationsDatasource.data.length <= 0">
    <!-- <h4>Your Application is under process!</h4> -->
    <!-- <h4 class="app-sub-heading">Your application is under process. University HUB team will get back to you shortly after reviewing your application</h4> -->
  </mat-card-content>

  <ng-container *ngIf="shortlistedApplicationsDatasource.data.length > 0">
    <mat-card-content class="p-t-0">
      <div class="responsive-table responsive-second-table">

        <table mat-table [dataSource]="shortlistedApplicationsDatasource"
          class="mat-elevation-z8">
          
          <!-- ORDER OF PREFERENCE -->
          <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef> {{'ORDER' | titlecase}} </th>
            <td mat-cell *matCellDef="let element;let i = index;">
              <div fxLayout="row" fxLayoutGap="5px">
                <div fxFlex="20">
                  <div *ngIf="!(isProposedChecklistDisabled && !finalCheckListed[i])">
                    <mat-checkbox *ngIf="!element?.accepted" value="{{ i }}"
                    (change)="checkCheckBoxvalue($event, i)">
                   </mat-checkbox>
                  </div>
                </div>
                <div>{{ element.order }}</div>
              </div>              
            </td>
          </ng-container>

          <!-- UNIVERSITY NAME -->
          <ng-container matColumnDef="universityName">
            <th mat-header-cell *matHeaderCellDef> {{'UNIVERSITY' | titlecase}} </th>
            <td mat-cell *matCellDef="let element">
              {{ element.universityName }}
            </td>
          </ng-container>

          <!-- COURSE NAME -->
          <ng-container matColumnDef="courseName">
            <th mat-header-cell *matHeaderCellDef> {{'COURSE' | titlecase}} </th>
            <td mat-cell *matCellDef="let element">
              {{ element.courseName }}
            </td>
          </ng-container>

          <!-- COUNTRY -->
          <ng-container matColumnDef="country">
            <th style="padding-left: 25px !important" mat-header-cell *matHeaderCellDef> {{'COUNTRY' | titlecase}} </th>
            <td style="padding-left: 25px !important" mat-cell *matCellDef="let element">
              {{ element.country }}
            </td>
          </ng-container>

          <!-- DEGREE -->
          <ng-container matColumnDef="degree">
            <th mat-header-cell *matHeaderCellDef> {{'DEGREE' | titlecase}} </th>
            <td mat-cell *matCellDef="let element">
              {{ element.degree }}
            </td>
          </ng-container>

          <!-- Application Fee -->
          <ng-container matColumnDef="applicationFee">
            <th mat-header-cell *matHeaderCellDef> {{'APPLICATION FEE' | titlecase}} </th>
            <td mat-cell *matCellDef="let element">
              {{ element.fee !== null ? '$' + element.fee : '-' }}
              <!-- <div fxLayout="row" fxLayoutGap="5px" *ngIf="isRecruiterPart">
                <div fxFlex>{{ element.fee !== null ? '$' + element.fee : '-' }}</div>
              </div> -->
              <!-- <div fxLayout="row" fxLayoutGap="5px" *ngIf="!isRecruiterPart">
                  <div fxFlex>{{ element.fee !== null ? '$' + element.fee : '-' }}</div>
                  <span *ngIf="element.fee !== null"
                  [ngClass]="(element.fee !== 0 && !element.paid) ? 'label label-danger cursor-pointer' : 'label label-success-clr'"
                    (click)="(element.fee !== 0 && !element.paid) ? onClickProceedToPay() : ''">
                    {{element.fee === 0 ? 'Applied' : (element.paid ? 'Paid & Applied' : 'Proceed to pay')}}
                  </span>
              </div> -->
            </td>
          </ng-container>
          <!-- *ngIf="element?.accepted" -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <div fxLayout="row" fxLayoutGap="5px" >
                  <span *ngIf="element.fee !== null"
                  [ngClass]="element.paid ? 'label label-success-clr' : ' '">
                    {{ element.paid ? 'Paid & Applied' : ''}}
                  </span>
                  <!-- <span *ngIf="element.fee !== null"
                  [ngClass]="!element.paid ? 'label label-danger cursor-pointer' : 'label label-success-clr'"
                    (click)="!element.paid ? onClickProceedToPay() : ''">
                    {{ element.paid ? 'Paid & Applied' : 'Proceed to pay'}}
                  </span> -->
                  <!-- {{element.fee === 0 ? 'Applied' : (element.paid ? 'Paid & Applied' : 'Proceed to pay')}} -->
                  <!-- [ngClass]="(element.fee !== 0 && !element.paid) ? 'label label-danger cursor-pointer' : 'label label-success-clr'" -->
                  <!-- (click)="(element.fee !== 0 && !element.paid) ? onClickProceedToPay() : ''"> -->


              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="shortlistedApplicationsColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: shortlistedApplicationsColumns;"></tr>
        </table>
      </div>

      <!-- <ul class="font-14" *ngIf="!isRecruiterPart">
        <li *ngIf="!proposedPaymentStatus?.accepted">Please accept any of the proposed universities to proceed ahead</li>
        <li *ngIf="proposedPaymentStatus?.accepted">You have already accepted the above proposed universities</li>
        <li *ngIf="proposedPaymentStatus?.paid">You have already paid for the proposed university applications</li>
      </ul> -->
    </mat-card-content>
    <p *ngIf="!isDocumentUploadCompleted" class="text-danger"><small>Please upload the required documents to submit your universities*</small></p><br />

    <mat-card-actions align="end" class="p-r-15 p-b-15">
      <!-- *ngIf="!proposedPaymentStatus?.accepted" -->
      <button mat-flat-button class="bg-success text-white m-l-15"
        (click)="onClickAcceptShortlistedUniversities()" [disabled]="user?.isLock || !isDocumentUploadCompleted || !isCourseSelected ">
        PAY {{getSelectedCount() ? '('+getSelectedCount()+')' : ''}}
      </button>

      <!-- <a *ngIf="proposedPaymentStatus?.accepted && !proposedPaymentStatus?.paid" mat-flat-button color="primary"
        class="m-l-15" (click)="onClickProceedToPay()">
        Please proceed to pay
      </a> -->
    </mat-card-actions>
  </ng-container>
</mat-card>

<!-- edit shortlisted University -->

<mat-card appearance="outlined" *ngIf="isEdit" class="testing-card">
  <mat-card-header>
    <mat-card-title>
      {{ (isEdit) ? 'Add/Edit' : 'Shortlisted' }} Universities (Order of Preference)
    </mat-card-title>
  </mat-card-header>

  <mat-card-content class="p-t-0">
    <ng-template [ngIf]="!isSavedAsDraft">
      <p class="app-content">*You can shortlist upto 3 Universities.</p><br />
    </ng-template>

    <div cdkDropList (cdkDropListDropped)="drop($event)"  class="example-list">
      <div *ngFor="let control of universityFormArray.controls; index as i" cdkDrag
        class="m-b-18">
       
        <form fxLayout.gt-md="row" fxLayout="column" fxLayoutGap="18px" [formGroup]="control">

          <!-- Count -->
          <p *ngIf="!isSavedAsDraft" fxLayoutAlign.gt-md="center center">{{ i+1 }}.</p> &nbsp;

          <!-- Country[readonly]="shortListedList[i].paid? true :false" -->
          <mat-form-field  fxFlex>
            <input type="text" [ngClass]="{'textColor':control.controls['paid'].value}"  [readonly]="control.controls['paid'].value"   placeholder="Search Country" matInput formControlName="country"
              [matAutocomplete]="countryAutocomplete"  (focus)="control.controls['paid'].value ? null :onFocusOutCountry($event, i)"
              (blur)="onBlurCountry($event.target.value, i)" (focusout) = "onChangeCountry($event, i)"  > 

            <mat-autocomplete #countryAutocomplete="matAutocomplete">
              <mat-option *ngFor="let option of countriesObservable[i] | async" [value]="option.country">
                {{option.country}}
              </mat-option> 
            </mat-autocomplete>

            <mat-error *ngIf="control.controls['country'].hasError('required') &&
                                    control.controls['country'].touched">
              Please Select Country
            </mat-error>
          </mat-form-field>
          
          <!-- Degree -->
          <mat-form-field fxFlex>
            <input type="text" [ngClass]="{'textColor':control.controls['paid'].value}" [readonly]="control.controls['paid'].value" 
             placeholder="Search Degree" matInput formControlName="degree"
              [matAutocomplete]="degreeAutocomplete"  (blur)="onBlurDegree($event.target.value, i)"
              (focus)="control.controls['paid'].value ? null : (degreesObservable[i]) ? onFocusOutDegree($event, i) : null" 
              (focusout) = "onChangeDegree($event, i)"
              > 

            <mat-hint *ngIf="!control.controls['degree'].value">
              Please select country before selecting degree
            </mat-hint>

            <mat-autocomplete #degreeAutocomplete="matAutocomplete">
              <mat-option *ngFor="let option of degreesObservable[i] | async" [value]="option">
                {{option}}
              </mat-option>
            </mat-autocomplete>

            <mat-error *ngIf="control.controls['degree'].hasError('required') &&
                                    control.controls['degree'].touched">
              Please Select Degree
            </mat-error>
          </mat-form-field>

          <!-- Course -->
          <mat-form-field fxFlex>
            <input type="text" [ngClass]="{'textColor':control.controls['paid'].value}" [readonly]="control.controls['paid'].value" placeholder="Search Course" matInput formControlName="course"
              [matAutocomplete]="courseAutocomplete"
              (focus)="control.controls['paid'].value ? null : (courseListObservable[i]) ? onFocusOutCourse($event, i) : null"
              (blur)="onBlurCourse($event.target.value, i)" (focusout) = "onChangeCourse($event, i)"
               [readonly]="!courseList[i]" >

            <mat-autocomplete #courseAutocomplete="matAutocomplete" [displayWith]="courseDisplayFn" class="width-300px">
              <mat-option *ngFor="let course of courseListObservable[i] | async" [value]="course"
                [matTooltip]="course.courseName">
                {{course.courseName}}
              </mat-option>
            </mat-autocomplete>

            <mat-error *ngIf="control.controls['course'].hasError('required') &&
                                    control.controls['course'].touched">
              Please Select Course
            </mat-error>
          </mat-form-field>

          <!-- University -->
          <mat-form-field fxFlex>
            <input type="text" [ngClass]="{'textColor':control.controls['paid'].value}" [readonly]="control.controls['paid'].value"  placeholder="Search University" matInput formControlName="university"
              [matAutocomplete]="universityAutocomplete"
              (focusout)="control.controls['paid'].value ? null : (universityListObservable[i]) ? onFocusOutUniversity($event, i) : null"
              [readonly]="!universityList[i]">

            <mat-autocomplete #universityAutocomplete="matAutocomplete" [displayWith]="universityDisplayFn"
              class="width-300px" (optionSelected)='onUniversityOptionSelected(i)'>
              <mat-option *ngFor="let university of universityListObservable[i] | async" [value]="university"
                [matTooltip]="university.universityName">
                {{university.universityName}}
              </mat-option>
            </mat-autocomplete>

            <mat-error *ngIf="control.controls['university'].hasError('required') &&
                                    control.controls['university'].touched">
              Please Select University
            </mat-error>
          </mat-form-field>
       

          <!-- Controls -->
          <div *ngIf="!isSavedAsDraft" fxFlex fxLayout="row">
            <button  *ngIf="control.controls['paid'].value"mat-raised-button color="primary" mat-mini-fab 
            matTooltip="paid" >
           Paid
          </button>&nbsp;
            <button  type="button" mat-mini-fab cdkDragHandle  class="bg-white text-dark"
              matTooltip="Rearrange University Order" >
              <mat-icon>drag_indicator</mat-icon>
            </button>&nbsp;

            <!-- <ng-template [ngIf]="universityFormArray.length < 2">
              <button type="button" mat-mini-fab class="bg-danger text-white" [disabled]="control.invalid"  (click)="onClickClearTheForm(i)" matTooltip="Clear">
                <mat-icon>clear</mat-icon>
              </button>
            </ng-template> -->

            <!-- [disabled]="universityFormArray.length <= 1" -->
            <button *ngIf="!control.controls['paid'].value"   type="button" mat-mini-fab (click)="onClickRemoveUniversity(i)" matTooltip="Remove"
            class="bg-danger text-white" >
              <mat-icon>close</mat-icon>
            </button>&nbsp;
            <ng-template [ngIf]="universityFormArray.length - 1 === i">
              <button type="button" mat-mini-fab color="accent" (click)="onClickAddUniversity()"
                matTooltip="Add" [disabled]="universityFormArray.length > 2 || control.invalid || isDuplicateCourse.includes(true)">
                <mat-icon>add</mat-icon>
              </button>
            </ng-template>
          </div>

          <div *ngIf="isSavedAsDraft" fxFlex fxLayout="row">
            <button mat-mini-fab class="bg-success text-white" (click)="onClickAddUniversityIntoTable()"
              [disabled]="appliedApplicationsDatasource.data.length > 6 || control.invalid" matTooltip="Save">
              <mat-icon>check</mat-icon>
            </button>&nbsp;

            <button type="button" mat-mini-fab class="bg-danger text-white" matTooltip="Clear">
              <mat-icon>clear</mat-icon>
            </button>
          </div>

        </form>
      </div>
      <p *ngIf="isShorlistedInvalidCourse" class="text-danger"><small>*Some of the universities shortlisted above, are not valid</small></p><br />
      <p *ngIf="!isDocumentUploadCompleted" class="text-danger"><small>Please upload the required documents to submit your universities*</small></p><br />
    </div>

  </mat-card-content>

  <mat-card-actions *ngIf="!isSavedAsDraft" align="end" class="p-r-15 p-b-15">
    <!-- [disabled]="user?.isLock || universityFormArray.invalid" -->
    <!-- [disabled]="universityFormArray.invalid" -->
    <!-- <button mat-flat-button color="primary" class="m-l-15" (click)="onClickSubmit(true)"
      [disabled]="(universityFormArray.invalid && universityFormArray.touched) || isDuplicateCourse.includes(true)">
      Save As Draft
    </button> -->

    <button mat-flat-button class="bg-success text-white m-l-15" (click)="onClickSubmit(false)"
      [disabled]="user?.isLock || universityFormArray.invalid || isShorlistedInvalidCourse 
      || !(isProfileCompleted && isDocumentUploadCompleted) || isDuplicateCourse.includes(true)">
      Submit
    </button>
  </mat-card-actions>
</mat-card>