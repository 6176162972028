<!-- <div fxFlex="100">

	<div fxLayoutAlign="space-between center">
		<h4 class="m-0">Create Student</h4>

		<button mat-icon-button color="primary" mat-dialog-close>
			<mat-icon>close</mat-icon>
		  </button>
	</div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div fxLayout="row wrap">
            <div class="p-l-10" fxFlex="100">
                <mat-form-field floatLabel="auto" style="width: 100%">
                    <input matInput placeholder="Email" type="email" [formControl]="form.controls['emailId']"
                       (blur)="!form.controls['emailId'].hasError('pattern') && form.controls['emailId'].value ? 
					              validateEmailId($event.target.value) : null "
                        required>
                    <mat-error
                        *ngIf="form.controls['emailId'].hasError('required') && form.controls['emailId'].touched">
                        Please enter email
                    </mat-error>
                    <mat-error
                        *ngIf="form.controls['emailId'].hasError('pattern') && form.controls['emailId'].touched">
                        Invalid email address
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="50" fxFlex="100">
                <mat-form-field floatLabel="auto" style="width: 100%">
                    <input matInput type="text" placeholder="First Name" [formControl]="form.controls['firstName']"
                        required>
                    <mat-error
                        *ngIf="form.controls['firstName'].hasError('required') && form.controls['firstName'].touched">
                         Please enter First Name
                    </mat-error>
                    <mat-error
                        *ngIf="form.controls['firstName'].hasError('pattern') && form.controls['firstName'].touched">
                        Enter Valid First Name
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="50" fxFlex="100">
                <mat-form-field floatLabel="auto" style="width: 100%">
                    <input matInput type="text" placeholder="Last Name" [formControl]="form.controls['lastName']"
                        required>
                    <mat-error
                        *ngIf="form.controls['lastName'].hasError('required') && form.controls['lastName'].touched">
                        Please enter Last Name
                    </mat-error>
                    <mat-error
                        *ngIf="form.controls['lastName'].hasError('pattern') && form.controls['lastName'].touched">
                        Enter Valid Last Name
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="50" fxFlex="100">
              <mat-form-field floatLabel="auto">
                <mat-label>Date of Birth</mat-label>
                <input required (focus)="dateOfBirthPicker.open()" formControlName="dateOfBirth" matInput 
                 style="cursor: pointer;" [matDatepicker]="dateOfBirthPicker" [max]="transferDobMaxDate"
                 placeholder="Enter Date of Birth" readonly>
                <mat-datepicker-toggle matSuffix [for]="dateOfBirthPicker"></mat-datepicker-toggle>
                <mat-datepicker #dateOfBirthPicker></mat-datepicker>
                <mat-error *ngIf="form.controls['dateOfBirth'].hasError('required') && 
                    form.controls['dateOfBirth'].touched">Please enter Date of
                    birth
                </mat-error>
            </mat-form-field>
            </div>
            <div fxFlex.gt-sm="50" fxFlex="100">
                <mat-form-field floatLabel="auto" style="width: 100%">
                    <mat-label>
                        Are you applying from {{ (university !== 'HUB') ? "USA" : "US?" }}
                    </mat-label>
                    <mat-select [formControl]="form.controls['fromUS']" required>
                        <mat-option [value]="true"> Yes </mat-option>
                        <mat-option [value]="false"> No </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="50" fxFlex="100">
                <mat-form-field floatLabel="auto" style="width: 100%">
                  <mat-select placeholder="Applying as" [formControl]="form.controls['ApplyingAs']" required
                  disableOptionCentering panelClass="myPanelClass" (selectionChange) = "onSelectionChange()">
                  <mat-option *ngFor="let student of applyAsStudent" [value]="student">
                    {{student}}
                  </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.controls['ApplyingAs'].hasError('required') && form.controls['ApplyingAs'].touched">
                    Please Select 
                  </mat-error> 
                </mat-form-field>
              </div>
              <div fxFlex.gt-sm="50" fxFlex="100" *ngIf="requiredfieldApplyAs">
                <mat-form-field floatLabel="auto" style="width: 100%">
                  <input matInput type="text" required placeholder="Please Specify" maxlength="40" [formControl]="form.controls['othersApplyingAs']">
                  <mat-error *ngIf="form.controls['othersApplyingAs'].hasError('required') && form.controls['othersApplyingAs'].touched">
                     Please Specify (Max 40 Alphanumeric)
                  </mat-error> 
                </mat-form-field>
              </div>
               <div fxFlex.gt-sm="50" fxFlex="100">
                <mat-form-field floatLabel="auto" style="width: 100%" (click)= "onChangeFromUs()">
                  <mat-select placeholder="Applying For" [formControl]="form.controls['ApplyingFor']" required
                  disableOptionCentering panelClass="myPanelClass" (selectionChange) = "onSelectionChange()">
                  <mat-option *ngFor="let student of ifFromUs ? fromUsYesApplyFor : fromUsNoApplyFor" [value]="student">
                    {{student}}
                  </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.controls['ApplyingFor'].hasError('required') && form.controls['ApplyingFor'].touched">
                    Please Select 
                  </mat-error> 
                </mat-form-field>
              </div>
              <div class="p-l-10" fxFlex="100" fxLayout="column">
                <div fxLayout="row" id="phone-input-container" fxFlex="100">
                  <mat-form-field floatLabel="auto" fxFlex="20">
                    <mat-label>Country Code</mat-label>
                    <mat-select formControlName="phoneCountryCode"
                      disableOptionCentering panelClass="myPanelClass">
                          <mat-select-trigger>
                              +{{ form.controls['phoneCountryCode'].value }}
                          </mat-select-trigger>

                          <mat-option fxLayout="row" *ngFor="let country of phoneCountryCode"
                              [value]="country.id">
                              {{country.name}} (+{{country.id}})
                           </mat-option>
                      </mat-select>
                    <mat-error *ngIf="form.controls['phoneCountryCode'].hasError('required') && form.controls['phoneCountryCode'].touched">
                      Please enter Country Code
                    </mat-error>
                  </mat-form-field>
                  <div fxFlex.gt-sm="80" fxFlex="100">
                    <mat-form-field floatLabel="auto" fxFlex="100" class="p-l-10">
                    <mat-label>Phone Number</mat-label>
                    <input matInput placeholder="Phone Number" type="text" [formControl]="form.controls['phoneWithoutCode']"
                    required >
                    <mat-error *ngIf="form.controls['phoneWithoutCode'].hasError('required') && form.controls['phoneWithoutCode'].touched">
                      Please enter phone number
                    </mat-error>
                    <mat-error *ngIf="form.controls['phoneWithoutCode'].hasError('pattern') && form.controls['phoneWithoutCode'].touched">
                      Invalid phone number
                    </mat-error>
                    </mat-form-field>
                    
                    
                  </div>
                </div>
            </div>
              <div fxFlex.gt-sm="50" fxFlex="100" *ngIf="requiredfieldApplyFor">
                <mat-form-field floatLabel="auto" style="width: 100%">
                  <input matInput type="text" required placeholder="Please Specify" maxlength="40" [formControl]="form.controls['othersApplyingFor']">
                  <mat-error *ngIf="form.controls['othersApplyingFor'].hasError('required') && form.controls['othersApplyingFor'].touched">
                    Please Specify (Max 40 Alphanumeric)
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="p-l-10" fxFlex="100">
                <div fxLayout="row" id="phone-input-container" fxFlex="100">
                  <mat-form-field floatLabel="auto" id="phone-country-input" 
                  fxFlex="20">
                  <mat-label>Emergency Country Code</mat-label>
                      <mat-select formControlName="emergencyPhoneCountryCode"
                      disableOptionCentering panelClass="myPanelClass">
                          <mat-select-trigger>
                              +{{ form.controls['emergencyPhoneCountryCode'].value }}
                          </mat-select-trigger>

                          <mat-option fxLayout="row" *ngFor="let country of phoneCountryCode"
                              [value]="country.id">
                              {{country.name}} (+{{country.id}})
                           </mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.controls['emergencyPhoneCountryCode'].hasError('required') && 
                  form.controls['emergencyPhoneCountryCode'].touched">
                          Please Select Emergency Country Code
                      </mat-error>
                  </mat-form-field>

                  <mat-form-field floatLabel="auto" class="p-l-10" fxFlex="80">
                          <mat-label>Emergency Contact Number</mat-label>
                          <input type="text" matInput placeholder=" " autocomplete="off"
                              formControlName="emergencyPhoneWithoutCode" OnlyNumberDirective OnlyNumber="true" />
                              <mat-error *ngIf="form.controls['emergencyPhoneWithoutCode'].hasError('required') && 
                              form.controls['emergencyPhoneWithoutCode'].touched">
                              Please enter contact number</mat-error>
                          <mat-error *ngIf="form.controls['emergencyPhoneWithoutCode'].hasError('pattern') && 
                          form.controls['emergencyPhoneWithoutCode'].touched">
                              Invalid contact number</mat-error>
                  </mat-form-field>
              </div>
              </div>

              <div fxFlex.gt-sm="50" fxFlex="100">
                <mat-form-field floatLabel="auto">
                  <mat-label>University applying for</mat-label>
                  <mat-select formControlName="uniApplyingFor" (selectionChange)="onSelectedUniversity($event.value)">
                      <mat-option [value]="null" selected>
                      Please select university name
                    </mat-option>
                      <mat-option *ngFor="let item of UniversityListArray" [value]="item.university"
                      [matTooltip]="item.university">
                      {{item.university}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.controls['uniApplyingFor'].hasError('required') && 
                      form.controls['uniApplyingFor'].touched">
                      Enter university name
                          </mat-error>
              </mat-form-field>
              </div>
              <div fxFlex.gt-sm="50" fxFlex="100">
                <mat-form-field floatLabel="auto">
                  <mat-label>Select Intake</mat-label>
                  <mat-select formControlName="intake">
                      <mat-option [value]="null" selected>
                      Please select Intake
                    </mat-option>
                      <mat-option *ngFor="let item of intakeListArray" [value]="item"
                      [matTooltip]="item">
                      {{item}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.controls['intake'].hasError('required') && 
                      form.controls['intake'].touched">
                      Please select Intake
                          </mat-error>
              </mat-form-field>
              </div>
            <button mat-raised-button color="primary" class="btn-block btn-lg m-t-20 m-b-20" type="submit"
                [disabled]="!form.valid">Create account</button>
        </div>
    </form>
</div> -->

<div fxFlex="100">

  <div fxLayoutAlign="space-between center">
    <h4 class="m-0">Create Student</h4>

    <button mat-icon-button color="primary" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div fxLayout="row wrap">
      <!-- Email -->
      <div fxFlex="100">
        <mat-form-field floatLabel="auto" style="width: 100%">
          <input matInput placeholder="Email" type="email" [formControl]="form.controls['emailId']" required>
          <mat-error *ngIf="form.controls['emailId'].hasError('required') && form.controls['emailId'].touched">
            Please enter email
          </mat-error>
          <mat-error *ngIf="form.controls['emailId'].hasError('pattern') && form.controls['emailId'].touched">
            Invalid email address
          </mat-error>
        </mat-form-field>
      </div>

      <!-- First Name -->
      <div fxFlex.gt-sm="50" fxFlex="100">
        <mat-form-field floatLabel="auto" style="width: 100%;padding-right:30px; ">
          <input matInput type="text" placeholder="First Name" [formControl]="form.controls['firstName']" required>
          <mat-error *ngIf="form.controls['firstName'].hasError('required') && form.controls['firstName'].touched">
            Please enter First Name
          </mat-error>
          <mat-error *ngIf="form.controls['firstName'].hasError('pattern') && form.controls['firstName'].touched">
            Enter Valid First Name
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Last Name -->
      <div fxFlex.gt-sm="50" fxFlex="100">
        <mat-form-field floatLabel="auto" style="width: 100%">
          <input matInput type="text" placeholder="Last Name" [formControl]="form.controls['lastName']" required>
          <mat-error *ngIf="form.controls['lastName'].hasError('required') && form.controls['lastName'].touched">
            Please enter Last Name
          </mat-error>
          <mat-error *ngIf="form.controls['lastName'].hasError('pattern') && form.controls['lastName'].touched">
            Enter Valid Last Name
          </mat-error>
        </mat-form-field>
      </div>

      <!-- dob -->
      <div fxFlex.gt-sm="50" fxFlex="100">
        <mat-form-field floatLabel="auto" style="padding-right:30px;">
          <mat-label>Date of Birth</mat-label>
          <input required (focus)="dateOfBirthPicker.open()" formControlName="dateOfBirth" matInput
            style="cursor: pointer;" [matDatepicker]="dateOfBirthPicker" [max]="transferDobMaxDate"
            placeholder="Enter Date of Birth" readonly>
          <mat-datepicker-toggle matSuffix [for]="dateOfBirthPicker"></mat-datepicker-toggle>
          <mat-datepicker #dateOfBirthPicker></mat-datepicker>
          <mat-error *ngIf="form.controls['dateOfBirth'].hasError('required') && 
                    form.controls['dateOfBirth'].touched">Please enter Date of
            birth
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Are you applying from US? -->
      <div fxFlex.gt-sm="50" fxFlex="100">
        <mat-form-field floatLabel="auto" style="width: 100%">
          <mat-label>
            Are you applying from {{ (university !== 'HUB') ? "USA" : "US?" }}
          </mat-label>
          <mat-select [formControl]="form.controls['fromUS']" required>
            <mat-option [value]="true"> Yes </mat-option>
            <mat-option [value]="false"> No </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Applying As -->
      <div fxFlex.gt-sm="50" fxFlex="100">
        <mat-form-field floatLabel="auto" style="width: 100%; padding-right:30px;">
          <mat-select placeholder="Applying as" [formControl]="form.controls['ApplyingAs']" required
            disableOptionCentering panelClass="myPanelClass" (selectionChange)="onSelectionChange()">
            <mat-option *ngFor="let student of applyAsStudent" [value]="student">
              {{student}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['ApplyingAs'].hasError('required') && form.controls['ApplyingAs'].touched">
            Please Select
          </mat-error>
        </mat-form-field>
      </div>

      <!-- applying as student => others -->
      <div fxFlex.gt-sm="50" fxFlex="100" *ngIf="requiredfieldApplyAs">
        <mat-form-field floatLabel="auto" style="width: 100%">
          <input matInput type="text" required placeholder="Please Specify" maxlength="40"
            [formControl]="form.controls['othersApplyingAs']">
          <mat-error
            *ngIf="form.controls['othersApplyingAs'].hasError('required') && form.controls['othersApplyingAs'].touched">
            Please Specify (Max 40 Alphanumeric)
          </mat-error>
        </mat-form-field>
      </div>
      <!-- Applying For -->
      <div fxFlex.gt-sm="50" fxFlex="100">
        <mat-form-field floatLabel="auto" style="width: 100%" (click)="onChangeFromUs()">
          <mat-select placeholder="Applying For" [formControl]="form.controls['ApplyingFor']" required
            disableOptionCentering panelClass="myPanelClass" (selectionChange)="onSelectionChange()">
            <mat-option *ngFor="let student of ifFromUs ? fromUsYesApplyFor : fromUsNoApplyFor" [value]="student">
              {{student}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['ApplyingFor'].hasError('required') && form.controls['ApplyingFor'].touched">
            Please Select
          </mat-error>
        </mat-form-field>
      </div>

      <!-- CONTACT NUMBER -->
      <div fxFlex="100" fxLayout="column">
        <div fxLayout="row" id="phone-input-container" fxFlex="100">
          <mat-form-field floatLabel="auto" fxFlex="50">
            <mat-label>Country Code</mat-label>
            <!-- <mat-select formControlName="phoneCountryCode"
                      disableOptionCentering panelClass="myPanelClass">
                          <mat-select-trigger>
                              +{{ form.controls['phoneCountryCode'].value }}
                          </mat-select-trigger>

                          <mat-option fxLayout="row" *ngFor="let country of phoneCountryCode"
                              [value]="country.id">
                              {{country.name}} (+{{country.id}})
                           </mat-option>
                      </mat-select> -->
            <input type="text" placeholder="Country Code" matInput formControlName="phoneCountryCode"
              [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredJSONDataOptionsphoneCountryCode | async" [value]="option.id"
                (onSelectionChange)="onSelectState('phoneCountryCode',option.id)">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-error
              *ngIf="form.controls['phoneCountryCode'].hasError('required') && form.controls['phoneCountryCode'].touched">
              Please enter Country Code
            </mat-error>
          </mat-form-field>
          <div fxFlex="50">
            <mat-form-field floatLabel="auto" fxFlex="100" class="p-l-10">
              <mat-label>Phone Number</mat-label>
              <input matInput placeholder="Phone Number" type="text" [formControl]="form.controls['phoneWithoutCode']"
                required>
              <mat-error
                *ngIf="form.controls['phoneWithoutCode'].hasError('required') && form.controls['phoneWithoutCode'].touched">
                Please enter phone number
              </mat-error>
              <mat-error
                *ngIf="form.controls['phoneWithoutCode'].hasError('pattern') && form.controls['phoneWithoutCode'].touched">
                Invalid phone number
              </mat-error>
            </mat-form-field>


          </div>
        </div>
      </div>

      <!-- applying For student => others -->
      <div fxFlex.gt-sm="50" fxFlex="100" *ngIf="requiredfieldApplyFor">
        <mat-form-field floatLabel="auto" style="width: 100%">
          <input matInput type="text" required placeholder="Please Specify" maxlength="40"
            [formControl]="form.controls['othersApplyingFor']">
          <mat-error
            *ngIf="form.controls['othersApplyingFor'].hasError('required') && form.controls['othersApplyingFor'].touched">
            Please Specify (Max 40 Alphanumeric)
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Emergency Contact Number -->
      <div fxFlex="100">
        <div fxLayout="row" id="phone-input-container" fxFlex="100">
          <mat-form-field floatLabel="auto" id="phone-country-input" fxFlex="50">
            <mat-label>Emergency Country Code</mat-label>
            <!-- <mat-select formControlName="emergencyPhoneCountryCode" disableOptionCentering panelClass="myPanelClass">
              <mat-select-trigger>
                +{{ form.controls['emergencyPhoneCountryCode'].value }}
              </mat-select-trigger>

              <mat-option fxLayout="row" *ngFor="let country of phoneCountryCode" [value]="country.id">
                {{country.name}} (+{{country.id}})
              </mat-option>
            </mat-select> -->
            <input type="text" placeholder="Country Code" matInput formControlName="emergencyPhoneCountryCode"
              [matAutocomplete]="autoemergency">
            <mat-autocomplete autoActiveFirstOption #autoemergency="matAutocomplete">
              <mat-option *ngFor="let option of filteredJSONDataOptionsemergencyPhoneCountryCode | async" [value]="option.id"
                (onSelectionChange)="onSelectState('emergencyPhoneCountryCode',option.id)">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.controls['emergencyPhoneCountryCode'].hasError('required') && 
                  form.controls['emergencyPhoneCountryCode'].touched">
              Please Select Emergency Country Code
            </mat-error>
          </mat-form-field>

          <mat-form-field floatLabel="auto" class="p-l-10" fxFlex="50">
            <mat-label>Emergency Contact Number</mat-label>
            <input type="text" matInput placeholder=" " autocomplete="off" formControlName="emergencyPhoneWithoutCode"
              OnlyNumberDirective OnlyNumber="true" />
            <mat-error *ngIf="form.controls['emergencyPhoneWithoutCode'].hasError('required') && 
                              form.controls['emergencyPhoneWithoutCode'].touched">
              Please enter contact number</mat-error>
            <mat-error *ngIf="form.controls['emergencyPhoneWithoutCode'].hasError('pattern') && 
                          form.controls['emergencyPhoneWithoutCode'].touched">
              Invalid contact number</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- University Applying For -->
      <div fxFlex.gt-sm="50" fxFlex="100">
        <mat-form-field floatLabel="auto" style="padding-right:30px;">
          <mat-label>University applying for</mat-label>
          <mat-select formControlName="uniApplyingFor" (selectionChange)="onSelectedUniversity($event.value)" multiple>
            <mat-option [value]="null" selected disabled>
              Please select university name
            </mat-option>
            <mat-option *ngFor="let item of UniversityListArray" [value]="item.university"
              [matTooltip]="item.university">
              {{item.university}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['uniApplyingFor'].hasError('required') && 
                      form.controls['uniApplyingFor'].touched">
            Enter university name
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Intake According to university -->
      <div fxFlex.gt-sm="50" fxFlex="100">
        <mat-form-field floatLabel="auto">
          <!-- <input matInput type="text" placeholder="- -" 
                  formControlName="intake" autocomplete="off"> -->
          <mat-label>Select Intake</mat-label>
          <mat-select formControlName="intake">
            <mat-option [value]="null" selected>
              Please select Intake
            </mat-option>
            <mat-option *ngFor="let item of intakeListArray" [value]="item" [matTooltip]="item">
              {{item}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['intake'].hasError('required') && 
                      form.controls['intake'].touched">
            Please select Intake
          </mat-error>
        </mat-form-field>
      </div>
      <button mat-raised-button color="primary" class="btn-block btn-lg m-t-20 m-b-20" type="submit"
        [disabled]="!form.valid">Create account</button>
    </div>
  </form>
</div>