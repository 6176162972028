import { Action } from '@ngrx/store';

export enum AppActionTypes {
  HandleError = '[App] Handle Error',
  ShowErrorMsg = '[App] Show Error Msg',
  ShowSuccessMsg = '[App] Show Success Msg',
  NoAction = '[App] No Action',
  RedirectLogin = '[App] Redirect Login',
  ShortlistedShowErrorMsg = '[App] Shortlisted Show Error Msg',
}

export class ShowErrorMessage implements Action {
  readonly type = AppActionTypes.ShowErrorMsg;
  constructor(public errorMsg: string) { }
}

export class ShortlistedShowErrorMsg implements Action {
  readonly type = AppActionTypes.ShortlistedShowErrorMsg;
  constructor(public errorMsg: boolean) { }
}

export class ShowSuccessMsg implements Action {
  readonly type = AppActionTypes.ShowSuccessMsg;
  constructor(public message: string) { }
}

export class HandleError implements Action {
  readonly type = AppActionTypes.HandleError;
  constructor(public error: any) { }
}

export class NoAction implements Action {
  readonly type = AppActionTypes.NoAction;
}

export class RedirectLogin implements Action {
  readonly type = AppActionTypes.RedirectLogin;
}

export type AppActions =
    | HandleError
    | ShowSuccessMsg
    | ShowErrorMessage
    | NoAction
    | RedirectLogin;
