import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store, select } from '@ngrx/store';

import { environment } from '../../../environments/environment';
import { userQuery } from '../../store/users/users.selectors';
import { IUser } from '../../store/model/user.model';
import { IStudentDocument } from '../models/shared.model';

@Injectable({
  providedIn: 'root'
  })
export class FileUploadService {
  public baseUrl: string = environment.baseUrl;
  public baseUrlCRM: string = environment.baseUrlCRM;
  public baseUrlCRMTwillio: string = environment.baseUrlCRMTwillio;
  public user: IUser;

  constructor(
    private httpClient: HttpClient,
    private store: Store,
  ) {
    this.store.pipe(select(userQuery.userSelect)).subscribe((user) => {
      this.user = user;
    });
  }

  public uploadDocumentFromService(requestBody: any, contactId, recContactId, docType) {
    const url = `${this.baseUrlCRM}wp/university/addStudentDcmts`;

    const payload = {
      ...requestBody,
      contactId,
      courseName: requestBody.courseName,
      recContactId,
      reupload: String(requestBody.reupload),
      required: docType
    };

    return this.httpClient.post<any>(url, payload, {
      headers: {
        'x-auth-token': 'accessque'
      },
      reportProgress: true,
      observe: 'events'
    });
  }
  public uploadDocumentFromServiceV2(requestBody: any) {
    const url = `${this.baseUrlCRM}wp/university/addStudentDcmts`;

    return this.httpClient.post<any>(url, requestBody, {
      headers: {
        'x-auth-token': 'asseccque'
      },

    });
  }

  public uploadRecruiterDocumentFromService(requestBody: IStudentDocument, contactId) {
    const url = `${this.baseUrl}recruiter/uploadRecruiterDocument`;
    const params = new HttpParams()
      .set('contactId', contactId)
      .set('courseName', requestBody.courseName)
      .set('id', requestBody.id)
      .set('reupload', String(requestBody.reupload));

    return this.httpClient.post<any>(url, requestBody.formData, {
      params,
      reportProgress: true,
      observe: 'events'
    });
  }

  public deleteDocumentFromService(contactId, id?: string) {
    const url = `${this.baseUrl}student/deleteStudentDocument`;
    const params = new HttpParams()
      .set('contactId', contactId)
      .set('id', id);

    return this.httpClient.delete<any>(url, { params });
  }

  public getUploadedDocumentListFromService(contactId) {
    const url = `${this.baseUrl}student/getUploadedDocumentList`;
    const params = new HttpParams()
      .set('contactId', contactId);

    return this.httpClient.get<any>(url, { params });
  }

  public getUploadedRecruiterDocumentListFromService(contactId) {
    const url = `${this.baseUrl}recruiter/getUploadedRecruiterDocumentList`;
    const params = new HttpParams()
      .set('contactId', contactId);

    return this.httpClient.get<any>(url, { params });
  }

  public getUploadedDocumentStudentProfileFromService() {
    const url = `${this.baseUrl}student/getUploadedDocumentStudentProfile`;
    const params = new HttpParams()
      .set('contactId', this.user.salesforceId);

    return this.httpClient.get<any>(url, { params });
  }

  public uploadDocumentSampleFromService(formData) {
    const url = `${this.baseUrl}recruiter/fileUpload`;

    return this.httpClient.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public uploadCsvFile(formData) {
    const url = `${this.baseUrl}recruiter/csv`;
    return this.httpClient.post<any>(url, formData);
  }
  public uploadDocument1(data) {
    const url = `${this.baseUrlCRM}UploadDocumentLeads`;

    return this.httpClient.post<any>(url, data, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }
  postDocumentComment(data) {
    return this.httpClient.post<any>(`${this.baseUrlCRM}campbellsville/addComments`, data, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }
  uploadCustomDocument(data) {
    return this.httpClient.post<any>(`${this.baseUrlCRM}addCustomDocUploadLeadsWebApp`, data, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }
  sendSms(data, projectId) {
    // return this.http.post(`${this.baseApiUrl}`+projectId+`/messaging-adapter/sendSMS`,data);
    return this.httpClient.post<any>(`${this.baseUrlCRMTwillio}${projectId}/messaging-adapter/sendSMS`, data, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }
  getSms(id) {
    return this.httpClient.get<any>(`${this.baseUrlCRM}communication/uHubSMS/${id}`, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }
  uploadFileAndGetUrl(data) {
    return this.httpClient.post<any>(`${this.baseUrlCRM}uploadFileAndGetUrl`, data, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }
  deleteCustomDocument(data) {
    return this.httpClient.post(
      `${this.baseUrlCRM}deleteCustomDocNameLeads`, data
    );
  }
  uploadFileAndGetLink(data) {
    const url = `${this.baseUrlCRM}UploadDocumentV2`;
    return this.httpClient.post(url, data);
  }

  getAllUniversity() {
    const url = `${this.baseUrlCRM}/universitryhub/listOfUniversities`;
    return this.httpClient.get(url);
  }
  sendNotification(data) {
    const url = `${this.baseUrlCRM}/wp/university/recruiter/sendNotification`;
    return this.httpClient.post(url, data, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }
  markReadByGUID(data) {
    return this.httpClient.post(`${this.baseUrlCRM}/notifications/markReadByGUID`, data, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }

  recruiterUnreadCount(data) {
    return this.httpClient.post(`${this.baseUrlCRM}/notifications/unreadCount`, data, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }
  updateTransferCustomDoc(data) {
    const url = `${this.baseUrlCRM}/uhub/updateTransferCustomDoc`;
    return this.httpClient.post(url, data, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }

  getUniversityInfoByName(data) {
    // /programsDocsByUniversity
    const url = `${this.baseUrlCRM}/programsDocsByUniversity`;
    return this.httpClient.post(url, data, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }
  getDocNameByDocId(data) {
    // /programsDocsByUniversity
    const url = `${this.baseUrlCRM}/getDocNameByDocId`;
    return this.httpClient.post(url, data, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }
  getCustomDocListNew(projectId) {
    return this.httpClient.get(`${this.baseUrlCRM}customDocList/listbyProject/${projectId}`, {
      headers: {
        'x-auth-token': 'asseccque'
      }
    });
  }
}
