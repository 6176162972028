import { IStaffState, initialStaffState } from './staff.state';
import { StaffActions, EStaffActions } from './staff.actions';

export const StaffReducers = (
  state: IStaffState = initialStaffState,
  action: StaffActions
): IStaffState => {
  switch (action.type) {
    case EStaffActions.LoginStaffAssociateSuccess: {
      return {
        ...state,
        staffAssociateSession: action.staffAssociateSession
      };
    }

    default:
      return state;
  }
};
