import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder, FormControl, FormGroup, Validators
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-filter-dialog-box',
  templateUrl: './filter-dialog-box.component.html',
  styleUrls: ['./filter-dialog-box.component.scss']
  })
export class FilterDialogBoxComponent implements OnInit {
  @ViewChild('select') select: MatSelect;

  allSelected=false;
  public currencyFilter: any=[{
    currency: 'USD($)',
    countries: ['USA'],

  },
  {
    currency: 'CAD(C$)',
    countries: ['CANADA'],

  },
  {
    currency: 'GBP(£)',
    countries: ['UK'],

  }
  ];
  public countryData=[];
  public countryAutocompleteObservable: Observable<any[]>;
  public form: FormGroup;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef< FilterDialogBoxComponent>
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({

      country: new FormControl('', Validators.required),

    });
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  onSubmit() {
    // const countryArray = this.form.value.country.map(x => x.map(x => x));
    let countryArray = [];
    this.form.value.country.forEach(element => {
      countryArray = countryArray.concat(element);
    });

    this.dialogRef.close(countryArray);
  }
}
