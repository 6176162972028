import {
  Component, OnInit, OnDestroy, ViewChild
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { MatTableDataSource, MatTable } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';

import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
  startWith, map
} from 'rxjs/operators';
import * as _ from 'lodash';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ShowSuccessMsg } from 'src/app/store/app.actions';
import { moveItemInFormArray } from '../../../shared/helpers/move-form-array';
import { IUser } from '../../../store/model/user.model';
import { userQuery } from '../../../store/users/users.selectors';
import { studentManagementQuery } from '../../../store/student-management/student-management.selectors';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import {
  IUniversities,
  ICourses,
  IApplyUniversityApplications,
  IApplications,
  IProposedPaymentStatus,
} from '../../../store/model/student-management.model';
import {
  GetUniversityList,
  GetUniversityListV2,
  GetCourseList,
  ApplyUniversityApplications,
  GetShortlistedApplications,
  GetAppliedApplications,
  // AcceptShortlistedApplications,
  UniversityProposedPaymentStatus,
  GetDegreeList,
  SaveUniversityApplications,
  GetSaveUniversityApplications,
  GetDegreeListSuccess,
  GetCourseListSuccess,
  GetUniversityListSuccess,
  GetUniversityListSuccessV2,
  GetSaveUniversityApplicationsSuccess,
  GetStudentLoginDetail,
  StudentDocuments,
  GetAppliedApplicationsSuccess,
  GetShortlistedApplicationsSuccess,
  UniversityProposedPaymentStatusSuccess,
  GetStudentProfile,
  GetStudentSavedFavoriteUniversityList,
  SaveStudentFavoriteUniversityList,
  GetExploreUniversity
} from '../../../store/student-management/student-management.actions';
import { SharedService } from '../../../shared/services/shared.service';
import { StudentManagementService } from '../../../store/student-management/student-management.service';
import { FileUploadService } from '../../../shared/services/file-upload.service';
import { ValidationAlertComponent } from '../../../shared/components/validation-alert/validation-alert.component';
import {
  countriesDegrees,
  ICountryDegree,
  proposedPaymentStatus
} from './universities.model';
import { IDocument, statusArrayFake } from '../student-application/student-application.model';

@Component({
  selector: 'app-universities',
  templateUrl: './universities.component.html',
  styleUrls: ['./universities.component.scss']
  })
export class UniversitiesComponent implements OnInit, OnDestroy {
  public user: IUser;
  public university: string;

  // * Variables to store incoming data
  // public countriesDegrees: ICountryDegree[][] = _.cloneDeep(countriesDegrees);
  public countriesDegrees: ICountryDegree[][] = [];
  public degreeList: string[][] = [];
  public courseList: ICourses[][] = [];
  public universityList: IUniversities[][] = [];

  // * FormArray
  public universityFormArray = new FormArray([]);

  // * Observables for Autocomplete
  public countriesObservable: Observable<ICountryDegree[]>[] = [];
  public degreesObservable: Observable<string[]>[] = [];
  public courseListObservable: Observable<ICourses[]>[] = [];
  public universityListObservable: Observable<IUniversities[]>[] = [];

  // * FormArray index to load data
  private countryIndex: number;
  private degreeIndex: number;
  private courseIndex: number;
  // * FormArray Edit Index
  private editIndex: number;
  // edit university
  public isEdit = false;
  public hideEdit = false;
  public deleteUniversityList: any;
  // shotListed University module with Edit Button
  public showMsg = true;
  // * Applied Universities DataTable
  public appliedApplicationsDatasource = new MatTableDataSource<IApplications>([]);
  public appliedApplicationsColumns = ['order', 'universityName', 'courseName', 'country', 'degree', 'action'];

  // * Admin Shortlisted Universities DataTable
  public shortlistedApplicationsDatasource = new MatTableDataSource([]);
  public shortlistedApplicationsColumns = ['order', 'universityName', 'courseName', 'country', 'degree', 'applicationFee', 'status'];

  //  * Array Created for the Final Checlist;
  public finalCheckListed: Array<Boolean>[] = [];
  public finalCheckListToSubmit: any[] = [];

  public proposedPaymentStatus: IProposedPaymentStatus = _.cloneDeep(proposedPaymentStatus);

  public isSavedAsDraft = false;
  @ViewChild('appliedApplicationsTableRef') appliedApplicationsTableRef: MatTable<IApplications>;

  public savedUniversityData = [];
  private subscriptions: Subscription[] = [];
  private isSaved: Array<boolean> = [];

  private degreeObservable: Subscription;
  private courseObservable: Subscription;
  private universityObservable: Subscription;
  public isAllDocumentsUploaded: Observable<boolean>;
  public isProfileCompleted: boolean;
  public isDocumentUploadCompleted: boolean;
  public courseArray: IDocument[] = [];

  public variousCountry: any[] = [];
  public variousDegree: any[] = [];
  public variousCourse: any[] = [];

  public isCourseSelected: boolean;
  public isShorlistedInvalidCourse: boolean;

  public isRecruiterPart: boolean;
  public isRecruiterNavigate = false;
  public isProposedChecklistDisabled: boolean;
  public isDuplicateCourse: Array<boolean> = [];
  public favUniversityLists: Array<any> = [];
  public filteredUniversity = [];
  public role: any;
  public isImageLoading: Array<boolean> = [];
  public copyUniversityData = [];
  public universityData = [];

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private dialog: MatDialog,
    public studentService: StudentManagementService,
    public sharedService: SharedService,
    public fileUploadService: FileUploadService,
    public router: Router,
    public http: HttpClient
  ) { }

  ngOnInit(): void {
    this.university = localStorage.getItem('university') || 'HUB';
    this.role = localStorage.getItem('role');
    this.isProfileCompleted = true;
    this.isDocumentUploadCompleted = true;
    this.isCourseSelected = false;
    this.isRecruiterNavigate = false;
    this.isAllDocumentsUploaded = this.sharedService.allDocumentsUploadedObservable;
    this.sharedService.isEditableUniversityBehaviorSubject.next(false);
    // Pushing all subscriptions to unsubscribe in onDestroy()
    this.subscriptions.push(
      this.store.pipe(select(userQuery.userSelect)).subscribe((user) => {
        if (localStorage.getItem('recruiter-student-user')) {
          this.isRecruiterPart = true;
          this.user = JSON.parse(localStorage.getItem('recruiter-student-user'));
          // if (user) {
          //   this.user = user;
          // } else {
          //   this.user = JSON.parse(localStorage.getItem('recruiter-student-user'));
          // }
        } else {
          this.isRecruiterPart = false;
          this.user = user;
        }
        this.isProfileCompleted = this.user?.isProfileCompleted;
        // this.sharedService.loadStudentDocuments();
        this.loadStudentDocuments();
      }),

      this.store.pipe(select(studentManagementQuery.studentDocuments)).subscribe((documents) => {
        if (documents) {
          this.courseArray = _.cloneDeep(documents);
        }
      }),

      // this.store.pipe(select(studentManagementQuery.degreeList)).subscribe(response => {
      //   if (response) {
      //     this.degreeList.splice(this.countryIndex, 1, response);
      //     this.manageDegreeAutocomplete(this.countryIndex);
      //     console.log(this.savedUniversityData, this.editIndex, this.courseIndex);
      //     // console.log(this.editIndex, this.savedUniversityData[this.editIndex]);

      //     if (this.isSaved[this.editIndex]) {
      //       this.universityFormArray.at(this.editIndex).get('degree').setValue(this.savedUniversityData[this.editIndex].degree);
      //     }
      //     // if (this.isSavedAsDraft && this.editIndex) {
      //     //   this.universityFormArray.at(0).get('degree').setValue(
      //     //     this.appliedApplicationsDatasource.data[this.editIndex].degree
      //     //   );
      //     // }
      //   }
      // }),

      // this.store.pipe(select(studentManagementQuery.courseList)).subscribe(response => {
      //   if (response) {
      //     this.courseList.splice(this.degreeIndex, 1, response);
      //     this.manageCourseAutocomplete(this.degreeIndex);

      //     if (this.isSaved[this.editIndex]) {
      //       this.universityFormArray.at(this.editIndex).get('course').setValue({
      //         courseId: this.savedUniversityData[this.editIndex].courseId,
      //         courseName: this.savedUniversityData[this.editIndex].courseName
      //       });
      //     }
      //     // if (this.isSavedAsDraft && this.editIndex) {
      //     //   this.universityFormArray.at(0).get('course').setValue({
      //     //     courseId: this.appliedApplicationsDatasource.data[this.editIndex].courseId,
      //     //     courseName: this.appliedApplicationsDatasource.data[this.editIndex].courseName
      //     //   });
      //     // }
      //   }
      // }),

      // this.store.pipe(select(studentManagementQuery.universityList)).subscribe((response) => {
      //   if (response) {
      //     // this.universityList.forEach((item, i) => { if (item == 3452) a[i] = 1010; });
      //     // this.universityList.splice(this.universityFormArray.length - 1, 0, response);
      //     this.universityList.splice(this.courseIndex, 1, response);
      //     this.manageUniversityAutocomplete(this.courseIndex);
      //     if (this.isSaved[this.editIndex]) {
      //       this.universityFormArray.at(this.editIndex).get('university').setValue({
      //         universityId: this.savedUniversityData[this.editIndex].universityId,
      //         universityName: this.savedUniversityData[this.editIndex].universityName
      //       });
      //     }
      //     // if (this.isSavedAsDraft && this.editIndex) {
      //     //   this.universityFormArray.at(0).get('university').setValue({
      //     //     universityId: this.appliedApplicationsDatasource.data[this.editIndex].universityId,
      //     //     universityName: this.appliedApplicationsDatasource.data[this.editIndex].universityName
      //     //   });
      //     // }
      //   }
      // }),

      this.store.pipe(select(studentManagementQuery.appliedApplications)).subscribe((applications) => {
        if (applications) {
          this.sharedService.isEditableUniversityDialogBehaviorSubject.next(null);
          this.sharedService.isEditableUniversityBehaviorSubject.next(false);
          applications = _.sortBy(applications, 'order');
          this.appliedApplicationsDatasource.data = applications;

          if (!this.isSavedAsDraft) {
            this.appliedApplicationsColumns = this.appliedApplicationsColumns
              .filter(temp => temp !== 'action');
          }
        }
      }),

      this.store.pipe(select(studentManagementQuery.shortlistedApplications)).subscribe((result) => {
        if (result) {
          this.isEdit = false;
          this.showMsg = false;

          this.isCourseSelected = false;
          this.finalCheckListed = [];
          const applications = _.sortBy(result?.applications, 'order');
          this.deleteUniversityList = applications;
          this.shortlistedApplicationsDatasource.data = applications;
          if (this.shortlistedApplicationsDatasource.data.filter((temp) => (temp.paid === true)).length >= 3) { // to hide Edit button
            this.hideEdit = true;
          }
          // this.shortlistedApplicationsDatasource.data = applications.filter(
          //   obj => !(obj && Object.keys(obj).length === 0 && obj.constructor === Object) // to remove empty object from array of object
          // );

          if (this.shortlistedApplicationsDatasource.data.filter(temp => temp.accepted).length >= 3) {
            this.isProposedChecklistDisabled = true;
          } else {
            this.isProposedChecklistDisabled = false;
          }
          this.proposedPaymentStatus.accepted = result.accepted;

          if (result.accepted) {
            // this.shortlistedApplicationsColumns.splice(5, 1, 'applicationFee');
            this.shortlistedApplicationsColumns.splice(6, 1, 'status');
            // if (!this.isRecruiterPart) {
            //   this.shortlistedApplicationsColumns.splice(6, 1, 'status');
            // }
          }
        }
      }),

      this.store.pipe(select(studentManagementQuery.proposedPaymentStatus)).subscribe((result) => {
        if (result) {
          this.proposedPaymentStatus.paid = result.paid;
        }
      }),

      this.store.pipe(select(studentManagementQuery.saveUniversityApplications)).subscribe(async (result) => {
        if (result) {
          this.sharedService.isEditableUniversityBehaviorSubject.next(false);
          this.sharedService.isEditableUniversityDialogBehaviorSubject.next(null);
          this.countriesDegrees = [];
          this.universityFormArray = new FormArray([]);
          if (result.length > 0) {
            this.universityFormArray.removeAt(0);
            this.savedUniversityData = result;
            for await (const i of result) {
              this.onClickAddUniversityIntoFormArray();
              this.editIndex = await this.savedUniversityData.findIndex(temp => temp.id === i.id);
              this.universityFormArray.at(this.editIndex).get('country').setValue(i.country);
              this.universityFormArray.at(this.editIndex).get('id').setValue(i.id);
              this.universityFormArray.at(this.editIndex).get('course').setValue({
                courseId: i.courseId,
                courseName: i.courseName
              });
              this.universityFormArray.at(this.editIndex).get('university').setValue({
                universityId: i.universityId,
                universityName: i.universityName
              });
              this.universityFormArray.at(this.editIndex).get('degree').setValue(
                i.degree
              );
            }
            for await (const i of result) {
              // this.onClickAddUniversityIntoFormArray();
              this.editIndex = await this.savedUniversityData.findIndex(temp => temp.id === i.id);
              this.countryIndex = await this.savedUniversityData.findIndex(temp => temp.id === i.id);
              this.degreeIndex = await this.savedUniversityData.findIndex(temp => temp.id === i.id);
              this.courseIndex = await this.savedUniversityData.findIndex(temp => temp.id === i.id);
              this.isSaved[this.editIndex] = true;
              this.universityFormArray.at(this.editIndex).get('country').setValue(i.country);
              this.universityFormArray.at(this.editIndex).get('id').setValue(i.id);
              await this.getDegreeList();
              // this.degreeObservable.unsubscribe();
              // this.store.dispatch(new GetDegreeListSuccess(null));
              await this.getCourseList();
              // this.courseObservable.unsubscribe();
              // this.store.dispatch(new GetCourseListSuccess(null));
              await this.getUniversityList();
              this.universityObservable.unsubscribe();
              this.store.dispatch(new GetUniversityListSuccess(null));
            }
          } else if (result.length === 0) {
            this.onClickAddUniversityIntoFormArray();
          }
        }
      }),

      this.studentService.isShortlistUniversityErrorObservable.subscribe((temp: boolean) => {
        this.isShorlistedInvalidCourse = temp;
      }),

      this.sharedService.isEditableUniversityDialogObservable.subscribe(temp => {
        if (temp) {
          const config: MatDialogConfig = {
            panelClass: 'dialog-responsive',
            data: {
              title: 'Confirmation',
              message: 'Are you sure you want to switch to another module?',
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
              type: 0
            }
          };
          this.dialog.closeAll();
          const dialogRef = this.dialog.open(ConfirmDialogComponent, config);
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.router.navigate(temp);
              this.sharedService.isEditableUniversityDialogBehaviorSubject.next(null);
              this.sharedService.isEditableUniversityBehaviorSubject.next(false);
            }
          });
        }
      }),

      this.store.pipe(select(studentManagementQuery.studentProfile))
        .subscribe((data: any) => {
          if (data) {
            if (data.englishexamType === 'TOEFL' || data.englishexamType === 'IELTS' || data.greExamDate || data.gmatExamDate) {
              if (data.englishexamType === 'TOEFL' || data.englishexamType === 'IELTS') {
                this.courseArray.forEach((temp, index) => {
                  if (temp.courseName.includes('TOEFL' || 'IELTS')) {
                    this.courseArray[index].required = true;
                  }
                });
              } else {
                this.courseArray.forEach((temp, index) => {
                  if (temp.courseName.includes('TOEFL' || 'IELTS')) {
                    this.courseArray[index].required = false;
                  }
                });
              }

              if (data.greExamDate || data.gmatExamDate) {
                this.courseArray.forEach((temp, index) => {
                  if ((data.greExamDate || data.gmatExamDate) && (temp.courseName.includes('GMAT') || temp.courseName.includes('GRE'))) {
                    this.courseArray[index].required = true;
                  }
                });
              } else {
                this.courseArray.forEach((temp, index) => {
                  if (temp.courseName.includes('GMAT') || temp.courseName.includes('GRE')) {
                    this.courseArray[index].required = false;
                  }
                });
              }
            } else {
              this.courseArray.forEach((temp, index) => {
                if (temp.courseName.includes('TOEFL' || 'IELTS')) {
                  this.courseArray[index].required = false;
                } else if (temp.courseName.includes('GMAT') || temp.courseName.includes('GRE')) {
                  this.courseArray[index].required = false;
                }
              });
            }
          }
        }),
      this.store.pipe(select(studentManagementQuery.exploreUniversity)).subscribe(data => {
        if (data) {
          this.copyUniversityData = data;
          // this.filteredUniversity = data;
        }
      }),
      this.store.pipe(select(studentManagementQuery.savedStudentFavoriteUniversity)).subscribe(data => {
        if (data) {
          this.favUniversityLists = data;
          this.universityData = _.cloneDeep(this.copyUniversityData);
          this.filteredUniversity = _.cloneDeep(this.copyUniversityData);
          this.filteredUniversity = this.filteredUniversity.filter(temp => this.favUniversityLists.includes(temp.universityId));
          console.log('🚀 ~ file: universities.component.ts:453 ~ UniversitiesComponent ~ this.store.pipe ~ this.filteredUniversity:', this.filteredUniversity);
        } else {
          this.favUniversityLists = [];
        }
      })
    );
    console.log(!localStorage.getItem('isSeperateExploreUniversities'), !localStorage.getItem('isRecruiterExploreUniversities'));
    if (!localStorage.getItem('isSeperateExploreUniversities') && !localStorage.getItem('isRecruiterExploreUniversities')) {
      this.store.dispatch(new GetStudentSavedFavoriteUniversityList(JSON.parse(localStorage.getItem('user')).salesforceId));
      this.store.dispatch(new GetExploreUniversity(this.user?.salesforceId));
    } else {
      this.store.dispatch(new GetStudentSavedFavoriteUniversityList(JSON.parse(localStorage.getItem('user')).salesforceId));
      this.store.dispatch(new GetExploreUniversity(this.user?.salesforceId));
    }
    setTimeout(() => {
      this.store.dispatch(new GetAppliedApplications(this.user?.salesforceId));
      // JSON.parse(localStorage.getItem('user')).salesforceId)
      this.store.dispatch(new GetShortlistedApplications(this.user?.salesforceId));
      this.store.dispatch(new UniversityProposedPaymentStatus(this.user?.salesforceId));
      this.store.dispatch(new GetSaveUniversityApplications(this.user?.salesforceId));
      this.store.dispatch(new GetStudentLoginDetail(this.user?.salesforceId));
      this.store.dispatch(new GetStudentProfile(this.user?.salesforceId));
    }, 300);
    this.getUploadedDocumentList();
  }

  // Edit university

  public async editUniversity() {
    this.isEdit = true;
    const shortListedData = this.shortlistedApplicationsDatasource.data;
    if (shortListedData) {
      this.universityFormArray = new FormArray([]);
      if (shortListedData.length > 0) {
        this.universityFormArray.removeAt(0);
        this.savedUniversityData = shortListedData;
        for await (const i of shortListedData) {
          this.onClickAddUniversityIntoFormArray();
          this.editIndex = await this.savedUniversityData.findIndex(temp => temp.order === i.order);
          this.universityFormArray.at(this.editIndex).get('paid').setValue(i.paid ? i.paid : false);
          this.universityFormArray.at(this.editIndex).get('country').setValue(i.country);
          this.universityFormArray.at(this.editIndex).get('id').setValue(i.id);
          this.universityFormArray.at(this.editIndex).get('course').setValue({
            courseId: i.courseId,
            courseName: i.courseName
          });
          this.universityFormArray.at(this.editIndex).get('university').setValue({
            universityId: i.universityId,
            universityName: i.universityName
          });
          this.universityFormArray.at(this.editIndex).get('degree').setValue(
            i.degree
          );
        }
        // for await (const i of shortListedData) {
        //   this.onClickAddUniversityIntoFormArray();
        //   this.editIndex = await this.savedUniversityData.findIndex(temp => temp.id === i.id);
        //   this.countryIndex = await this.savedUniversityData.findIndex(temp => temp.id === i.id);
        //   this.degreeIndex = await this.savedUniversityData.findIndex(temp => temp.id === i.id);
        //   this.courseIndex = await this.savedUniversityData.findIndex(temp => temp.id === i.id);
        //   this.isSaved[this.editIndex] = true;
        //   this.universityFormArray.at(this.editIndex).get('country').setValue(i.country);
        //   this.universityFormArray.at(this.editIndex).get('id').setValue(i.id);
        //   await this.getDegreeList();
        //   this.degreeObservable.unsubscribe();
        //   this.store.dispatch(new GetDegreeListSuccess(null));
        //   await this.getCourseList();
        //   this.courseObservable.unsubscribe();
        //   this.store.dispatch(new GetCourseListSuccess(null));
        //   await this.getUniversityList();
        //   this.universityObservable.unsubscribe();
        //   this.store.dispatch(new GetUniversityListSuccess(null));
        // }
      } else if (shortListedData.length === 0) {
        this.onClickAddUniversityIntoFormArray();
      }
    }
  }

  public getDegreeList(index?) {
    return new Promise((resolve) => {
      this.store.dispatch(new GetDegreeListSuccess(null));
      this.store.dispatch(new GetDegreeList(
        index >= 0 ? this.universityFormArray.at(index).get('country').value : this.universityFormArray.at(this.editIndex).get('country').value,
        this.user?.salesforceId,
      ));
      this.degreeObservable = this.store.pipe(select(studentManagementQuery.degreeList)).subscribe(response => {
        this.degreeList.splice(this.countryIndex, 1, []);
        if (response) {
          this.degreeList.splice(this.countryIndex, 1, response);
          this.manageDegreeAutocomplete(this.countryIndex);
          if (this.isSaved[this.editIndex]) {
            this.universityFormArray.at(this.editIndex).get('degree').setValue(
              this.savedUniversityData[this.editIndex].degree
            );
          }
          resolve(true);
        }
      });
    });
  }

  public getCourseList(index?) {
    return new Promise((resolve) => {
      this.store.dispatch(new GetCourseListSuccess(null));
      this.store.dispatch(new GetCourseList(
        index >= 0 ? this.universityFormArray.at(index).get('country').value : this.universityFormArray.at(this.editIndex).get('country').value,
        index >= 0 ? this.universityFormArray.at(index).get('degree').value : this.universityFormArray.at(this.editIndex).get('degree').value,
        this.user?.salesforceId,
      ));
      this.courseObservable = this.store.pipe(select(studentManagementQuery.courseList)).subscribe(response => {
        this.courseList.splice(this.degreeIndex, 1, []);
        if (response) {
          this.courseList.splice(this.degreeIndex, 1, response);
          this.manageCourseAutocomplete(this.degreeIndex);
          if (this.isSaved[this.editIndex]) {
            this.universityFormArray.at(this.editIndex).get('course').setValue({
              courseId: this.savedUniversityData[this.editIndex].courseId,
              courseName: this.savedUniversityData[this.editIndex].courseName
            });
          }
          resolve(true);
        }
      });
    });
  }

  public getUniversityList(index?) {
    return new Promise((resolve) => {
      this.store.dispatch(new GetUniversityListSuccess(null));
      this.store.dispatch(new GetUniversityList(
        index >= 0 ? this.universityFormArray.at(index).get('country').value : this.universityFormArray.at(this.editIndex).get('country').value,
        index >= 0 ? this.universityFormArray.at(index).get('degree').value : this.universityFormArray.at(this.editIndex).get('degree').value,
        // eslint-disable-next-line prefer-template
        index >= 0 ? this.universityFormArray.at(index).get('course').value?.courseName : this.universityFormArray.value[this.editIndex].course.courseName,
        this.user?.salesforceId,
      ));

      this.universityObservable = this.store.pipe(select(studentManagementQuery.universityList)).subscribe((response) => {
        this.universityList.splice(this.courseIndex, 1, []);
        if (response) {
          this.universityList.splice(this.courseIndex, 1, response);
          this.manageUniversityAutocomplete(this.courseIndex);
          if (this.isSaved[this.editIndex]) {
            this.universityFormArray.at(this.editIndex).get('university').setValue({
              universityId: this.savedUniversityData[this.editIndex].universityId,
              universityName: this.savedUniversityData[this.editIndex].universityName
            });
          }
          resolve(true);
        }
      });
    });
  }

  /**
   * return FormGroup (each) to push inside universityForm
   */
  public universityFormGroup(): FormGroup {
    return this.fb.group({
      country: [null, Validators.required],
      degree: [null, Validators.required],
      university: [null, Validators.required],
      course: [null, Validators.required],
      id: [null],
      paid: [null]

    });
  }

  /* --------------------------- Country Autocomplete -------------------------- */
  /**
   * Manage Country Autocomplete
   */
  private manageCountryAutocomplete(index: number) {
    this.courseObservable?.unsubscribe();
    this.countriesObservable[index] = this.universityFormArray.at(index).get('country')
      .valueChanges.pipe(
        startWith(''),
        map(value => ((typeof value === 'string') ? value : value?.country)),
        map(country => (country ? this._filterCountry(country, index) : this.countriesDegrees[index]?.slice()))
      );
  }

  // * Filter Country
  private _filterCountry(country: string, index: number) {
    const filterValue = country.toLowerCase();
    const filteredArray = _.cloneDeep(this.countriesDegrees[index]);

    return filteredArray.filter(option => option.country.toLowerCase().includes(filterValue));
  }
  /* -------------------------------------------------------------------------- */

  /* --------------------------- Degree Autocomplete -------------------------- */
  /**
   * Manage Degree Autocomplete
   */
  private manageDegreeAutocomplete(index: number) {
    this.degreeObservable.unsubscribe();
    this.degreesObservable[index] = this.universityFormArray.at(index).get('degree')
      .valueChanges.pipe(
        startWith(''),
        map(value => ((typeof value === 'string') ? value : value)),
        map(degree => (degree ? this._filterDegree(degree, index) : this.degreeList[index]?.slice()))
      );
  }

  /**
   * Filter Degree
   */
  private _filterDegree(degree: string, index: number) {
    this.sharedService.isEditableUniversityBehaviorSubject.next(true);
    const filterValue = degree.toLowerCase();
    return this.degreeList[index].filter(option => option.toLowerCase().includes(filterValue));
  }
  /* -------------------------------------------------------------------------- */

  /* --------------------------- University Autocomplete -------------------------- */
  /**
   * Manage University Autocomplete
   */
  private manageUniversityAutocomplete(index: number) {
    this.universityListObservable[index] = this.universityFormArray.at(index).get('university')
      .valueChanges.pipe(
        startWith(''),
        map(value => ((typeof value === 'string') ? value : value?.universityName)),
        map(university => (university ? this._filterUniversity(university, index) : this.universityList[index]?.slice()))
      );
  }

  /**
   * Filter University
   */
  private _filterUniversity(university: string, index: number) {
    this.sharedService.isEditableUniversityBehaviorSubject.next(true);
    const filterValue = university.toLowerCase();
    return this.universityList[index].filter(option => option.universityName.toLowerCase().includes(filterValue));
  }

  // * DisplayWith University
  public universityDisplayFn(university: IUniversities): string | undefined {
    return university ? university.universityName : undefined;
  }
  /* -------------------------------------------------------------------------- */

  /* --------------------------- Course Autocomplete -------------------------- */
  /**
   * Manage Course Autocomplete
   */
  private manageCourseAutocomplete(index: number) {
    this.courseListObservable[index] = this.universityFormArray.at(index).get('course')
      .valueChanges.pipe(
        startWith(''),
        map(value => ((typeof value === 'string') ? value : value?.courseName)),
        map(course => (course ? this._filterCourse(course, index) : this.courseList[index]?.slice()))
      );
  }

  /**
   * Filter Course
   */
  private _filterCourse(course: string, index: number) {
    this.sharedService.isEditableUniversityBehaviorSubject.next(true);
    const filterValue = course.toLowerCase();
    return this.courseList[index].filter(option => option.courseName.toLowerCase().includes(filterValue));
  }

  /**
   * DisplayWith Course
   */
  public courseDisplayFn(course: ICourses): string | undefined {
    return course ? course.courseName : undefined;
  }
  /* -------------------------------------------------------------------------- */
  /**
   * On Click Add universityFormGroup into universityFormArray
   */
  public onClickAddUniversity() {
    this.sharedService.isEditableUniversityBehaviorSubject.next(true);
    this.onClickAddUniversityIntoFormArray();
  }

  /**
   * Push universityFormGroup into universityFormArray
   */
  public onClickAddUniversityIntoFormArray() {
    this.universityFormArray.push(this.universityFormGroup());
    this.countriesDegrees.push(countriesDegrees[0]);
    this.manageCountryAutocomplete(this.universityFormArray.length - 1);
  }

  /**
   * Remove university from universityFormArray
   */
  public onClickRemoveUniversity(i: number) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      data: {
        title: 'Confirmation',
        message: 'Are you sure, you want to remove this university?',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 0
      }
    };
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, config);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isDuplicateCourse[i] = false;
        this.sharedService.isEditableUniversityBehaviorSubject.next(true);
        if (this.universityFormArray.length === 1) {
          this.universityFormArray.at(i).reset();
        } else {
          this.universityFormArray.removeAt(i);
          this.countriesDegrees.splice(i, 1);
          this.degreeList.splice(i, 1);
          this.courseList.splice(i, 1);
          this.universityList.splice(i, 1);
        }
      }
    });
  }

  public onClickClearTheForm(i: number) {
    this.universityFormArray.at(i).reset();
  }

  /* -------------------------------------------------------------------------------- */
  /* Check if input value exists in given options, if not input value will be removed */
  /* -------------------------------------------------------------------------------- */

  public onBlurCountry(value: any, index: number) {
    this.variousCountry[index] = value;
  }

  public onChangeCountry(event: any, index: number) {
    const selectedCountry = this.variousCountry[index];
    setTimeout(() => {
      const changedValue = event.target.value;
      if (selectedCountry !== changedValue) {
        this.isDuplicateCourse[index] = false;
        this.sharedService.isEditableUniversityBehaviorSubject.next(true);
        this.universityFormArray.at(index).get('degree').reset();
        this.universityFormArray.at(index).get('course').reset();
        this.universityFormArray.at(index).get('university').reset();
      }
    }, 250);
  }

  /**
   * FOCUS OUT COUNTRY
   */
  public onFocusOutCountry(event: any, index: number) {
    this.countryIndex = index;
    this.countriesObservable[index]
      .subscribe(result => {
        if (!result) { return; }
        if (!result.some(temp => temp.country === event.target.value)) {
          event.target.value = '';
        } else {
          this.editIndex = null;
          if (this.isSavedAsDraft) {
            this.isDuplicateCourse[index] = false;
            this.universityFormArray.at(0).get('degree').reset();
            this.universityFormArray.at(0).get('course').reset();
            this.universityFormArray.at(0).get('university').reset();
          } else {
            // this.universityFormArray.at(index).get('degree').reset();
            // this.universityFormArray.at(index).get('course').reset();
            // this.universityFormArray.at(index).get('university').reset();
          }
          this.getDegreeList(index);
          // this.store.dispatch(new GetDegreeList(
          //   this.universityFormArray.at(index).get('country').value,
          // ));
        }
      })
      .unsubscribe();
  }

  public onBlurDegree(value: any, index: number) {
    this.variousDegree[index] = value;
  }

  public onChangeDegree(event: any, index: number) {
    const selectedDegree = this.variousDegree[index];
    setTimeout(() => {
      const changedValue = event.target.value;
      if (selectedDegree !== changedValue) {
        this.isDuplicateCourse[index] = false;
        this.universityFormArray.at(index).get('course').reset();
        this.universityFormArray.at(index).get('university').reset();
      }
    }, 100);
  }

  /**
   * FOCUS OUT DEGREE
   * - Send country and degree to get corresponding courses
   */
  public onFocusOutDegree(event: any, index: number) {
    this.degreeIndex = index;
    this.degreesObservable[index]
      .subscribe(result => {
        if (!result) { return; }
        if (!result.includes(event.target.value)) {
          event.target.value = '';
        } else {
          this.editIndex = null;
          if (this.isSavedAsDraft) {
            this.isDuplicateCourse[index] = false;
            this.universityFormArray.at(0).get('course').reset();
            this.universityFormArray.at(0).get('university').reset();
          } else {
            // this.universityFormArray.at(index).get('course').reset();
            // this.universityFormArray.at(index).get('university').reset();
          }
          // this.store.dispatch(new GetCourseList(
          //   this.universityFormArray.at(index).get('country').value,
          //   event.target.value,
          // ));
          this.getCourseList(index);
        }
      })
      .unsubscribe();
  }

  public onBlurCourse(value: any, index: number) {
    this.variousCourse[index] = value;
  }

  public onChangeCourse(event: any, index: number) {
    const selectedCourse = this.variousCourse[index];
    setTimeout(() => {
      const changedValue = event.target.value;
      if (selectedCourse !== changedValue) {
        this.isDuplicateCourse[index] = false;
        this.universityFormArray.at(index).get('university').reset();
      }
    }, 250);
  }

  /**
   * FOCUS OUT COURSE
   * - Send country, degree and courseName to get corresponding Universities
   */
  public onFocusOutCourse(event: any, index: number) {
    this.courseIndex = index;
    this.courseListObservable[index]
      .subscribe(result => {
        if (!result) { return; }
        if (!result.some(temp => temp.courseName === event.target.value)) {
          event.target.value = '';
        } else {
          this.editIndex = null;
          if (this.isSavedAsDraft) {
            this.isDuplicateCourse[index] = false;
            this.universityFormArray.at(0).get('university').reset();
          } else {
            // this.universityFormArray.at(index).get('university').reset();
          }
          // this.store.dispatch(new GetUniversityList(
          //   this.universityFormArray.at(index).get('country').value,
          //   this.universityFormArray.at(index).get('degree').value,
          //   this.universityFormArray.at(index).get('course').value?.courseName
          // ));
          this.getUniversityList(index);
        }
      })
      .unsubscribe();
  }

  /**
   * FOCUS OUT UNIVERSITY
   */
  public onFocusOutUniversity(event: any, index: number) {
    this.universityListObservable[index]
      .subscribe(result => {
        if (!result) { return; }
        if (!result.some(temp => temp.universityName === event.target.value)) {
          event.target.value = '';
        }
      })
      .unsubscribe();
  }
  /* -------------------------------------------------------------------------- */

  /**
   * Submit Selected Universities
   */
  public onClickSubmit(isDraft: boolean) {
    if (isDraft) {
      // this.sendSelectedUniversities(isDraft);
      this.saveUniversityApplication(isDraft);
    } else {
      const config: MatDialogConfig = {
        panelClass: 'dialog-responsive',
        data: {
          title: 'Confirmation',
          message: 'Are you sure, you want to submit these universities?',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 0
        }
      };
      this.dialog.closeAll();
      const dialogRef = this.dialog.open(ConfirmDialogComponent, config);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.sendSelectedUniversities(isDraft);
        }
      });
    }
  }

  private saveUniversityApplication(isDraft) {
    const applications = [];
    if (this.universityFormArray.length > 0 && this.universityFormArray.status === 'VALID') {
      for (let i = 0; i < this.universityFormArray.length; i++) {
        applications.push({
          id: this.universityFormArray.value[i].id,
          order: i + 1,
          universityId: this.universityFormArray.value[i].university.universityId,
          universityName: this.universityFormArray.value[i].university.universityName,
          courseId: this.universityFormArray.value[i].course.courseId,
          courseName: this.universityFormArray.value[i].course.courseName,
          country: this.universityFormArray.value[i].country,
          degree: this.universityFormArray.value[i].degree,
        });
      }
    }
    const requestBody: IApplyUniversityApplications = {
      contactId: this.user?.salesforceId,
      isDraft,
      applications,
      deleteUniversity: this.deleteUniversityList
    };
    // this.store.dispatch(new SaveUniversityApplications(requestBody));
  }

  private sendSelectedUniversities(isDraft: boolean) {
    const applications = [];
    this.universityFormArray?.value?.forEach((temp) => temp.paid = temp.paid ? temp.paid : false);
    const withoutPaidUniversity = this.universityFormArray?.value?.filter((temp) => temp.paid === false);
    for (let i = 0; i < withoutPaidUniversity.length; i++) {
      applications.push({
        order: i + 1,
        universityId: withoutPaidUniversity[i].university.universityId,
        universityName: withoutPaidUniversity[i].university.universityName,
        courseId: withoutPaidUniversity[i].course.courseId,
        courseName: withoutPaidUniversity[i].course.courseName,
        country: withoutPaidUniversity[i].country,
        degree: withoutPaidUniversity[i].degree,
      });
    }

    const requestBody: IApplyUniversityApplications = {
      contactId: this.user?.salesforceId,
      // isDraft,
      applications,
      deleteUniversity: this.deleteUniversityList

    };
    this.store.dispatch(new ApplyUniversityApplications(requestBody));
  }

  /**
   * Add University In Table
   */
  public onClickAddUniversityIntoTable() {
    const applications: IApplications[] = [];
    for (let i = 0; i < this.universityFormArray.length; i++) {
      applications.push({
        order: this.isSavedAsDraft ? (this.appliedApplicationsDatasource.data.length + 1) : (i + 1),
        universityId: this.universityFormArray.value[i].university.universityId,
        universityName: this.universityFormArray.value[i].university.universityName,
        courseId: this.universityFormArray.value[i].course.courseId,
        courseName: this.universityFormArray.value[i].course.courseName,
        country: this.universityFormArray.value[i].country,
        degree: this.universityFormArray.value[i].degree,
      });
    }

    this.appliedApplicationsDatasource.data.push(...applications);
    this.appliedApplicationsTableRef.renderRows();
  }

  /**
   * Delete Saved University in Table
   */
  public onClickDeleteAppliedUniversity(index: number) {
    this.appliedApplicationsDatasource.data.splice(index, 1);
    this.appliedApplicationsTableRef.renderRows();
  }

  // Change Event for Checking the Proposed University
  public checkCheckBoxvalue(event, i) {
    this.finalCheckListed[i] = event.checked;
    this.isCourseSelected = false;
    for (let index = 0; index < this.finalCheckListed.length; index++) {
      if (this.finalCheckListed[index]) {
        this.isCourseSelected = true;
      }
    }
    if (this.finalCheckListed.filter((temp) => temp).length >= (3 - this.shortlistedApplicationsDatasource.data.filter(temp => temp.accepted).length)) {
      this.isProposedChecklistDisabled = true;
    } else {
      this.isProposedChecklistDisabled = false;
    }
  }

  /**
   * Accept Admin Proposed Universities
   */
  public onClickAcceptShortlistedUniversities() {
    if (this.isDocumentUploadCompleted) {
      this.finalCheckListToSubmit = [];
      this.finalCheckListed.forEach((temp, index) => {
        if (temp) {
          this.finalCheckListToSubmit.push(this.shortlistedApplicationsDatasource.data[index]);
        }
      });
      const config: MatDialogConfig = {
        panelClass: 'dialog-responsive',
        data: {
          title: 'Confirmation',
          message: 'Are you sure you want to pay?',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 0
        }
      };
      this.dialog.closeAll();
      const dialogRef = this.dialog.open(ConfirmDialogComponent, config);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isRecruiterNavigate = true;
          const requestBody: IApplyUniversityApplications = {
            contactId: this.user?.salesforceId,
            applications: this.finalCheckListToSubmit
          };
          localStorage.setItem('proceed_To_Pay', JSON.stringify(requestBody));
          // this.store.dispatch(new AcceptShortlistedApplications(requestBody));
          // eslint-disable-next-line no-constant-condition
          if (localStorage.getItem('role') === 'RECRUITER_ORGANIZATION'
            || localStorage.getItem('role') === 'ASSOCIATE') {
            this.router.navigateByUrl('/recruiter-student-payment');
          } else {
            this.router.navigateByUrl('/payment-student');
          }
        }
      });
    } else {
      const config: MatDialogConfig = {
        minWidth: '250px',
        maxWidth: '450px',
        data: {
          header: '',
          message: 'Please upload documents to accept the proposed universities and to proceed ahead with the payment of application fees.',
          type: 4,
          routerLink: this.isRecruiterPart ? '/recruiter-student-document' : '/documents'
        },
        disableClose: true,
      };
      this.dialog.closeAll();
      this.dialog.open(ValidationAlertComponent, config);
    }
  }

  /**
   * Drag FormArray and other fields array
   */
  public drop(event: CdkDragDrop<string[]>) {
    moveItemInFormArray(this.universityFormArray, event.previousIndex, event.currentIndex);
    moveItemInArray(this.courseList, event.previousIndex, event.currentIndex);
    moveItemInArray(this.countriesDegrees, event.previousIndex, event.currentIndex);
  }

  /**
   * Drag and Drop Table
   */
  public drapDropTable(event: CdkDragDrop<IApplications[]>) {
    const prevIndex = this.appliedApplicationsDatasource.data.findIndex((d) => d === event.item.data);
    moveItemInArray(this.appliedApplicationsDatasource.data, prevIndex, event.currentIndex);
    this.appliedApplicationsTableRef.renderRows();
  }

  /**
   * Edit Saved University
   */
  public onClickEditSavedUniversity(i: number) {
    this.editIndex = i;
    this.countryIndex = 0;
    this.degreeIndex = 0;
    this.courseIndex = 0;

    this.universityFormArray.at(0).get('country').setValue(this.appliedApplicationsDatasource.data[i].country);

    this.store.dispatch(new GetDegreeList(
      this.appliedApplicationsDatasource.data[i].country,
      this.user?.salesforceId,
    ));

    this.store.dispatch(new GetCourseList(
      this.appliedApplicationsDatasource.data[i].country,
      this.appliedApplicationsDatasource.data[i].degree,
      this.user?.salesforceId,

    ));

    this.store.dispatch(new GetUniversityList(
      this.appliedApplicationsDatasource.data[i].country,
      this.appliedApplicationsDatasource.data[i].degree,
      this.appliedApplicationsDatasource.data[i].courseName,
      this.user?.salesforceId

    ));
  }

  private getUploadedDocumentList() {
    this.subscriptions.push(
      this.fileUploadService.getUploadedDocumentListFromService(this.user?.salesforceId).subscribe(async response => {
        if (response) {
          const rejectedDocuments: Array<string> = [];
          for await (const i of response.documentList) {
            const courseIndex = this.courseArray.findIndex(temp => temp.id === i.id);
            if (courseIndex > -1) {
              this.courseArray[courseIndex].status = i.status;
              this.courseArray[courseIndex].statusIcon = statusArrayFake.filter(
                obj => obj.toolTip === i.status
              )[0]?.icon;
              this.courseArray[courseIndex].statusIconColor = statusArrayFake.filter(
                obj => obj.toolTip === i.status
              )[0]?.bgColor;
              this.courseArray[courseIndex].downloadUrl = (i.status !== 'Reupload') ? i.downloadUrl : null;

              if (i.status === 'Reupload') {
                this.courseArray[courseIndex].uploadFile = [];
                rejectedDocuments.push(i.courseName);
              }
            }
          }

          const isFilled = this.courseArray.filter(temp => (temp.required))
            .every(temp => temp.downloadUrl);
          this.isDocumentUploadCompleted = isFilled;
          this.sharedService.allDocumentsUploadedBehaviorSubject.next(isFilled);
        } else {
          this.isDocumentUploadCompleted = false;
        }
      })
    );
  }

  public onClickLocalStorage() {
    localStorage.setItem('toThrowErrors', 'true');
  }

  public onClickProceedToPay() {
    if (this.isDocumentUploadCompleted) {
      if (this.isRecruiterPart) {
        this.isRecruiterNavigate = true;
        this.router.navigate(['/recruiter-student-payment']);
      } else {
        this.router.navigate(['/payment-student']);
      }
    } else {
      const config: MatDialogConfig = {
        minWidth: '250px',
        maxWidth: '450px',
        data: {
          header: '',
          message: 'Please upload documents to accept the proposed universities and to proceed ahead with the payment of application fees.',
          type: 4,
          routerLink: this.isRecruiterPart ? '/recruiter-student-document' : '/documents'
        },
        disableClose: true,
      };
      this.dialog.closeAll();
      this.dialog.open(ValidationAlertComponent, config);
    }
  }

  public loadStudentDocuments() {
    let path = null;
    const university = localStorage.getItem('university') || 'HUB';
    if (university === 'HUB') {
      if (this.user?.fromUS) {
        path = 'assets/data/transferStudent-document.json';
      } else {
        path = 'assets/data/outside-country-hub.json';
      }
    } else if (university === 'UWLA') {
      if (this.user.fromUS) {
        path = 'assets/data/transferStudent-document.json';
      } else {
        path = 'assets/data/outside-country-uwla.json';
      }
    }

    this.readFileFromService(path).subscribe(result => {
      this.store.dispatch(new StudentDocuments(result));
    });
  }

  public readFileFromService(path: string): Observable<any> {
    return this.http.get<any>(path);
  }

  public getSelectedCount() {
    return this.finalCheckListed.filter(temp => temp).length;
  }

  public checkDuplicateCourse(index) {
    const country = _.countBy(this.universityFormArray.value, 'country');
    const degree = _.countBy(this.universityFormArray.value, 'degree');
    const course = _.countBy(this.universityFormArray.value, 'course.courseId');
    const university = _.countBy(this.universityFormArray.value, 'university.universityId');
    // console.log(country, degree, course, university);
    if (_.filter(this.universityFormArray.value, x => country[x.country] > 1
      && degree[x.degree] > 1
      && course[x.course?.courseId] > 1
      && university[x.university?.universityId] > 1).length > 0) {
      this.isDuplicateCourse[index] = true;
      this.store.dispatch(new ShowSuccessMsg('You have already selected same course in respective Institute'));
      setTimeout(() => {
        this.universityFormArray.removeAt(index);
        this.isDuplicateCourse[index] = false;
      }, 2000);
    } else {
      this.isDuplicateCourse[index] = false;
    }
  }

  public onUniversityOptionSelected(index: number) {
    this.checkDuplicateCourse(index);
  }

  ngOnDestroy() {
    if (!this.isRecruiterNavigate) {
      localStorage.removeItem('recruiter-student-user');
    }
    this.sharedService.isEditableUniversityDialogBehaviorSubject.next(null);
    // unsubscribe all subscriptions
    this.store.dispatch(new GetSaveUniversityApplicationsSuccess(null));
    this.store.dispatch(new GetAppliedApplicationsSuccess(null));
    this.store.dispatch(new GetShortlistedApplicationsSuccess(null));
    this.store.dispatch(new UniversityProposedPaymentStatusSuccess(null));
    for (const i of this.subscriptions) {
      i.unsubscribe();
    }
  }
  public onUniversityLogoLoaded(university) {
    this.isImageLoading[university] = true;
  }
  public removeTag(iconUrl) {
    if (iconUrl) {
      return iconUrl.replace('<p>', '').replace('</p>', '').replace('amp;', '');
    }
  }
}
