import { Action } from '@ngrx/store';

import {
  IUser, ILogout, ILogin
} from '../model/user.model';
import { IRecruiterSession } from '../model/recruiter.model';

export enum EUserActions {
  AddUser = '[User] Add User',
  AddUserSuccess = '[User] Add User Success',
  AddUserFailure = '[User] Add User Failure',
  ChangeRole = '[User] Change Role',
  EmailIdValidator = '[User] ValidateEmailId',
  EmailIdValidatorFailure = '[User] InvalidEmailId',
  Login = '[User] Login',
  LoginSuccess = '[User] Login Success',
  LoginFailure = '[User] Login Failure',
  Logout = '[User] Logout',
  LoggedOut = '[User] Logout Success',
  LoggedInUser = '[User] Logged In User',
}

export class UserAction implements Action {
  readonly type: string;
  role?: string;
  user?: IUser;
  logout?: ILogout;
  loggedInUser?: IUser | IRecruiterSession;
}

export class AddUser implements UserAction {
  public readonly type = EUserActions.AddUser;
  constructor(public user: IUser) { }
}

export class AddUserSuccess implements UserAction {
  public readonly type = EUserActions.AddUserSuccess;
  constructor(public user: IUser) { }
}

export class AddUserFailure implements UserAction {
  readonly type = EUserActions.AddUserFailure;
  constructor(public error: any) { }
}

export class ChangeRole implements UserAction {
  public readonly type = EUserActions.ChangeRole;
  constructor(public role: string) { }
}
export class EmailIdValidator implements UserAction {
  public readonly type = EUserActions.EmailIdValidator;
  constructor(public data: any) { }
}

export class EmailIdValidatorFailure implements UserAction {
  public readonly type = EUserActions.EmailIdValidatorFailure;
  constructor(public error: any) { }
}

export class Login implements UserAction {
  public readonly type = EUserActions.Login;
  constructor(public user: ILogin) { }
}

export class LoginSuccess implements UserAction {
  public readonly type = EUserActions.LoginSuccess;
  constructor(public role: string, public user: IUser) { }
}

export class LoginFailure implements UserAction {
  public readonly type = EUserActions.LoginFailure;
  constructor(public error: any) { }
}

export class Logout implements UserAction {
  public readonly type = EUserActions.Logout;
  constructor(public role: string, public payload: ILogout) { }
}

export class LoggedOut implements UserAction {
  public readonly type = EUserActions.LoggedOut;
  constructor() { }
}

export class LoggedInUser implements UserAction {
  public readonly type = EUserActions.LoggedInUser;
  constructor(public loggedInUser: IUser | IRecruiterSession) { }
}

export type UserActions =
  | AddUser
  | AddUserSuccess
  | AddUserFailure
  | EmailIdValidator
  | EmailIdValidatorFailure
  | ChangeRole
  | Login
  | LoginSuccess
  | LoginFailure
  | Logout
  | LoggedOut
  | LoggedInUser;
