import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IDocument } from 'src/app/pages/student/student-application/student-application.model';
import { EReducers } from '../app.state';
import { IInviteStudent } from '../model/recruiter.model';
import {
  IDashboardMetrics,
  IApplyCourseSuccess,
  IApplicationStatus,
  IUniversities,
  ICourses,
  IApplications,
  IShortlistedApplications,
  IPayNowLink,
  IProposedPaymentStatus
} from '../model/student-management.model';

export interface IStudentManagementState {
  inviteStudent: IInviteStudent;
  studentList: any;
  failedList : any;
  deleteFailedList: any;
  studentProfile: any;
  dashboardMetrics: IDashboardMetrics[];
  leadsbyCard: any[];
  applyCourseDetails: IApplyCourseSuccess;
  applicationStatus: IApplicationStatus[];
  degreeList: Array<string>,
  universityList: IUniversities[];
  universityListV2: any;
  courseList: ICourses[];
  appliedApplications: IApplications[];
  shortlistedApplications: IShortlistedApplications;
  documents: IDocument[];
  payNow: IPayNowLink;
  proposedPaymentStatus: IProposedPaymentStatus;
  exploreUniversity:any;
  saveUniversity: any;
  saveUniversityApplications:any;
  documentCompletion:any;
  studentApplicationStatus: any;
  studentNotification: any;
  savedStudentFavoriteUniversity: any;
  createStudentInfo: any;
}

export const initialStudentMangementState: IStudentManagementState = {
  inviteStudent: null,
  studentList: null,
  failedList: null,
  deleteFailedList: null,
  studentProfile: null,
  dashboardMetrics: null,
  leadsbyCard: null,
  applyCourseDetails: null,
  applicationStatus: null,
  degreeList: null,
  universityList: null,
  universityListV2: null,
  courseList: null,
  appliedApplications: null,
  shortlistedApplications: null,
  documents: null,
  payNow: null,
  proposedPaymentStatus: null,
  exploreUniversity: null,
  saveUniversity: null,
  saveUniversityApplications: null,
  documentCompletion: null,
  studentApplicationStatus: null,
  studentNotification: null,
  savedStudentFavoriteUniversity: null,
  createStudentInfo: null
};

const studentMangementSelectorFeature = createFeatureSelector<IStudentManagementState>(
  EReducers[EReducers.STUDENT_MANAGEMENT]
);

const studentList = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.studentList
);

const failedList = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.failedList
);

const deleteFailedList = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.deleteFailedList
);

const studentProfile = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.studentProfile
);

const exploreUniversity = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.exploreUniversity
);

const dashboardMetrics = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.dashboardMetrics
);
const leadsbyCard = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.leadsbyCard
);

const applyCourseDetails = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.applyCourseDetails
);

const applicationStatus = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.applicationStatus
);

const degreeList = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.degreeList
);

const createStudentInfo = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.createStudentInfo
);

const universityList = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.universityList
);
const universityListV2 = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.universityListV2
);

const courseList = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.courseList
);

const appliedApplications = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.appliedApplications
);

const shortlistedApplications = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.shortlistedApplications
);

const studentDocuments = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.documents
);

const payNow = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.payNow
);

const proposedPaymentStatus = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.proposedPaymentStatus
);

const saveUniversityApplications = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.saveUniversityApplications
);

const updateDocumentData = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.documentCompletion
);

const studentApplicationStatus = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.studentApplicationStatus
);

const studentNotification = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.studentNotification
);

const savedStudentFavoriteUniversity = createSelector(
  studentMangementSelectorFeature,
  (state: IStudentManagementState) => state?.savedStudentFavoriteUniversity
);

export const studentManagementQuery = {
  studentList,
  failedList,
  deleteFailedList,
  studentProfile,
  dashboardMetrics,
  leadsbyCard,
  applyCourseDetails,
  applicationStatus,
  degreeList,
  createStudentInfo,
  universityList,
  universityListV2,
  courseList,
  appliedApplications,
  shortlistedApplications,
  studentDocuments,
  payNow,
  proposedPaymentStatus,
  exploreUniversity,
  saveUniversityApplications,
  updateDocumentData,
  studentApplicationStatus,
  studentNotification,
  savedStudentFavoriteUniversity
};
