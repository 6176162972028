import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, tap } from 'rxjs/operators';

import {
  AppActionTypes,
  HandleError,
  ShortlistedShowErrorMsg,
  ShowErrorMessage,
  ShowSuccessMsg,
} from './app.actions';
import { RecruiterEffects } from './recruiter/recruiter.effects';
import { UserEffects } from './users/users.effects';
import { StudentManagementEffects } from './student-management/student-management.effects';
import { StaffEffects } from './staff/staff.effects';
import { StudentManagementService } from './student-management/student-management.service';

@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private router: Router,
    private studentMangementService: StudentManagementService
  ) { }

  handleError$ = createEffect(() => this.actions$
    .pipe(ofType(AppActionTypes.HandleError),
      map((action: HandleError) => {
        let {
          message = 'An Unknown Error Occured. Please Contact Support.',
        } = action.error.error;
        message = message
          .split(':')
          .slice(-1)[0]
          .trim();
        return new ShowErrorMessage(message);
      })));

  showSuccessMsg$ = createEffect(() => this.actions$
    .pipe(ofType(AppActionTypes.ShowSuccessMsg),
      map((action: ShowSuccessMsg) => action.message),
      tap(msg => this.showSuccessMsg(msg))), { dispatch: false });

  showErrorMsg$ = createEffect(() => this.actions$
    .pipe(ofType(AppActionTypes.ShowErrorMsg),
      map((action: ShowErrorMessage) => action.errorMsg),
      tap(msg => this.showErrorMsg(msg))), { dispatch: false });

  shortlistedShowErrorMsg$ = createEffect(() => this.actions$
    .pipe(ofType(AppActionTypes.ShortlistedShowErrorMsg),
      map((action: ShortlistedShowErrorMsg) => action.errorMsg),
      tap(msg => this.shortlistedShowErrorMsg(msg))), { dispatch: false });

  redirectLogin$ = createEffect(() => this.actions$
    .pipe(ofType(AppActionTypes.RedirectLogin),
      tap(() => this.redirectLogin())), { dispatch: false });

  showErrorMsg = message => {
    this.snackBar.open(message, '', {
      duration: 2000
    });
  };

  shortlistedShowErrorMsg = message => {
    this.studentMangementService.isShortlistUniversityErrorBehaviorSubject.next(message);
  };

  showSuccessMsg = message => {
    this.snackBar.open(message, '', {
      duration: 2000
    });
  };

  redirectLogin() {
    // this.router.navigateByUrl('/authentication/login');
    window.location.reload();
  }
}

export const AppEffects = [
  UserEffects,
  RecruiterEffects,
  NotificationEffects,
  StudentManagementEffects,
  StaffEffects
];
