<div class="ctr">
    <div class="d-flex">
        <div class="fl mr-100"><img src="../../../assets/images/us-flag.png"></div>
        <div>
            <h3> MADE FOR INTERNATIONAL STUDENTS</h3>
            <h2>Simplify Your Move to America</h2>
        </div>
        <div class="fr ml-100"><img src="../../../assets/images/us-flag.png"></div>
    </div>
    <div class="clr"></div>
    <div class="ctr-left">
        <div><img src="../../../assets/images/ellis-money.png"></div>
        <ul>
            <li>
                <div class="img-ctr"><img src="../../../assets/images/us-bank.png"></div>
                <div class="txt"> Open a US bank account with <b>no monthly fees and no minimum balance</b></div>
            </li>
            <li>
                <div class="img-ctr"><img src="../../../assets/images/us-card.png"></div>
                <div class="txt">Receive an <b>international Visa debit card</b> with no foreign transaction fees, before you arrive in America</div>
            </li>
            <li>
                <div class="img-ctr"><img src="../../../assets/images/zero-tax.png"></div>
                <div class="txt">Zero international wire transfer fees</div>
            </li>
        </ul>
        <p>*Ellis is not responsible for non-Ellis incurred banks fees.</p>
    </div>
    <div class="ctr-right">
        <div><img src="../../../assets/images/ellis-money-graphic.png"></div>
    </div>
    <div class="clr"></div>
</div>

<div class="b20"></div>

<div class="ctr">
    
    <div class="clr"></div>
    <div class="ctr-left">
        <div><img src="../../../assets/images/ellis-mobile.png"></div>
        <ul>
            <li>
                <div class="img-ctr"><img src="../../../assets/images/fast-5g.png"></div>
                <div class="txt">Get a US phone number with ultra-fast 5G
                    and unlimited talk and text</div>
            </li>
            <li>
                <div class="img-ctr"><img src="../../../assets/images/us-card.png"></div>
                <div class="txt">Start paying when the SIM card is activated
                    and pause anytime </div>
            </li>
        </ul>
        <div class="plans-ctr">
            <div class="c-plan">
                <p>6 GB</p>
                <div class="plan-price">
                    $35/month
                </div>
            </div>
            <div class="c-plan">
                <p>10 GB</p>
                <div class="plan-price">
                    $45/month
                </div>
            </div>
            <div class="c-plan">
                <p>Unlimited Data</p>
                <div class="plan-price">
                    $50/month
                </div>
            </div>
        </div>
        <div class="clr"></div>
        <p>* Plus local taxes and fees</p>
    </div>
    <div class="ctr-right">
        <div><img src="../../../assets/images/ellis-mobile-graphic.png"></div>
    </div>
    <div class="clr"></div>
</div>
<div class="clr"></div>
    <div class="getstarted-block">
       <a href="https://signup.joinellis.com/?ref=UNIHUB" target="_blank" class="my-btn">Get Started</a>
    </div>
 

