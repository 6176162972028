import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder, FormControl, FormGroup, ValidatorFn, Validators
} from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import * as _moment from 'moment';
import { Moment, default as _rollupMoment } from 'moment';
import { Observable, Subscription, of } from 'rxjs';
import {
  delay, map, startWith, switchMap
} from 'rxjs/operators';
import { REGEX } from 'src/app/shared/helpers/form.validator';
import { CountryStateCityService } from 'src/app/shared/services/country-state-city.service';
import { NotificationServiceService } from 'src/app/shared/services/notification-service.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { IRecruiterSession } from 'src/app/store/model/recruiter.model';
import { recruiterQuery } from 'src/app/store/recruiter/recruiter.selectors';
import { CreateStudent, ResetCreateStudentSuccess } from 'src/app/store/student-management/student-management.actions';
import { studentManagementQuery } from 'src/app/store/student-management/student-management.selectors';
import { StudentManagementService } from 'src/app/store/student-management/student-management.service';
import { UploadConfirmComponent } from './upload-confirm/upload-confirm.component';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const MY_ACADEMIC_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-create-student-recruiter',
  templateUrl: './create-student-recruiter.component.html',
  styleUrls: ['./create-student-recruiter.component.scss'],
  providers: [
  {
  provide: DateAdapter,
  useClass: MomentDateAdapter,
  deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  })
export class CreateStudentRecruiterComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public studentInfoform: FormGroup;
  public programInfoform: FormGroup;
  public universityInfoform: FormGroup;
  public academicInfoform: FormGroup;
  public university: string;
  public ifFromUs: any;
  public requiredfieldApplyAs: boolean;
  public requiredfieldApplyFor: boolean;
  public UniversityListArray: any = [];
  public intakeListArray: any = [];
  public transferDobMaxDate = moment().subtract(11, 'years').format();
  public DobMaxDate = moment().subtract(16, 'years').format();
  public testDateMaxDate = moment();
  public uniDateMaxDate = moment().add(1, 'years').format();
  public uniDateMinDate = moment();
  options: any[] = [];
  phoneCountryCode: any[] = [];
  public fullCountryArray: any[] = [];
  public fullProgramArray: any[] = [];
  public fullTermArray: any[] = [];
  public fullProgramArraySearch: any[] = [];
  public countryOrganizationAutocompleteObservable: Observable<any[]>;
  public countryOrganizationAutocompleteObservableProgram: Observable<any[]>;
  public phonecountryOrganizationAutocompleteObservable: Observable<any[]>;
  public emergencycountryOrganizationAutocompleteObservable: Observable<any[]>;
  public programAutocompleteObservable: Observable<any[]>[] = [];
  public termsAutocompleteObservable: Observable<any[]>[] = [];
  public universityAutocompleteObservable: Observable<any[]>[] = [];
  public programLevel = ['Bachelors', 'Masters', 'Doctorate', 'Associate', 'Certificate', 'Online', 'High School'];
  public terms = ['Spring', 'Spring-1', 'Spring-2', 'Summer', 'Summer-1', 'Summer-2', 'Fall', 'Fall-1', 'Fall-2'];
  /* *****************************************************
      ****** DATE: 18 Oct 2023
      ****** REASON: Emergency phone is not mandatory
      ****** OLD CODE:
      {
      name: '12th Grade',
      value: '12th'
    },
    {
      name: '10+3 Diploma',
      value: '10+3diploma'
    },
    {
      name: 'Highschool Diploma',
      value: 'highschool'
    },

  ***************************************************** */

  public academicType = [
    {
      name: '10+2',
      value: '10+2'
    },
    {
      name: 'Polytechnic/High School Diploma (10+3)',
      value: 'Polytechnic/High School Diploma (10+3)'
    },
    {
      name: "Undergraduate/Bachelor's Degree",
      value: "Undergraduate/Bachelor's Degree"
    },
    {
      name: "Masters's Degree",
      value: "Masters's Degree"
    },
    {
      name: 'Levels',
      value: 'levels'
    },
    {
      name: 'GED',
      value: 'GED'
    }
  ];
  filteredOptions: any;
  recruiter: IRecruiterSession;
  submitClicked = false;
  maxUniversityCount = 3;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private actions: Actions,
    private dialog: MatDialog,
    private studentManagementService: StudentManagementService,
    private sharedService: SharedService,
    private countryStateCityService: CountryStateCityService,
    private snackBar: MatSnackBar,
    private router: Router,
    public notificationService: NotificationServiceService
  ) { }

  ngOnInit(): void {
    this.university = localStorage.getItem('university') || 'HUB';
    this.initForm();
    this.getCountryList();
    this.getProgramList();
    this.getUniversityList();
    this.getUniProgramList();
    this.searchObservables();
    this.getRecruiterDetails();
    this.getProgramByUniversity();
    this.getTermsList();
    this.subscriptions.push(
      // Get Student List and bind in table
      this.store.pipe(select(studentManagementQuery.createStudentInfo)).subscribe(response => {
        if (response) {
          const createdLeadInfo = response.data;
          // this.router.navigate([`/student-management/student_list/${createdLeadInfo.salesforceId}`]);
          this.store.dispatch(new ResetCreateStudentSuccess());
          this.openUploadFileDialog(createdLeadInfo.salesforceId);
        }
      })
    );
  }
  openUploadFileDialog(salesforceId) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      data: {
        salesforceId
      }
    };
    const dialogRef = this.dialog.open(UploadConfirmComponent, config);
    dialogRef.afterClosed().subscribe(() => {
    });
  }
  searchObservables() {
    // =====================
    // $ Autocomplete - Country
    this.countryOrganizationAutocompleteObservable = this.studentInfoform.get('country')
      .valueChanges.pipe(
        startWith(null),
        switchMap(name => {
          if (typeof name === 'string') {
            const countryName = of(this.fullCountryArray).pipe(
              delay(800),
              map(response => response.filter(p => p.name.toUpperCase().includes(name)))
            );
            return countryName;
          }
          return of([]);
        })
      );
    // $ Autocomplete - Country - program
    this.phonecountryOrganizationAutocompleteObservable = this.studentInfoform.get('phoneCountryCode')
      .valueChanges.pipe(
        startWith(null),
        switchMap(name => {
          if (typeof name === 'string') {
            return of(this.fullCountryArray).pipe(
              delay(800),
              map(response => response.filter(p => p.name.toUpperCase().includes(name)))
            );
          }
          return of([]);
        })
      );
    // $ Autocomplete - emergency Country
    this.emergencycountryOrganizationAutocompleteObservable = this.studentInfoform.get('emergencyPhoneCountryCode')
      .valueChanges.pipe(
        startWith(null),
        switchMap(name => {
          if (typeof name === 'string') {
            return of(this.fullCountryArray).pipe(
              delay(800),
              map(response => response.filter(p => p.name.toUpperCase().includes(name)))
            );
          }
          return of([]);
        })
      );
  }
  getRecruiterDetails() {
    this.store.pipe(select(recruiterQuery.recruiterSessionSelect)).subscribe((recruiter: IRecruiterSession) => {
      this.recruiter = recruiter;
    });
  }
  async initForm() {
    this.studentInfoform = this.fb.group({
      firstName: new FormControl('', [Validators.required, Validators.pattern(REGEX.NAME), Validators.minLength(3), Validators.maxLength(50)]),
      lastName: new FormControl('', [Validators.required, Validators.pattern(REGEX.NAME), Validators.minLength(3), Validators.maxLength(50)]),
      emailId: [null, Validators.compose([
        Validators.required,
        Validators.pattern(REGEX.EMAIL),
      ])],
      phoneCountryCode: new FormControl('', Validators.required),
      phoneWithoutCode: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(17), Validators.pattern(REGEX.NUMBER)]),
      emergencyPhoneCountryCode: new FormControl(''),
      /*
      *********************************************************************
      ****** DATE: 18 Oct 2023
      ****** REASON: Emergency phone is not mandatory
      ****** OLD CODE:
      emergencyPhoneWithoutCode: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(17), Validators.pattern(REGEX.NUMBER)]),
      *********************************************************************
      */
      emergencyPhoneWithoutCode: new FormControl('', [Validators.minLength(4), Validators.maxLength(17), Validators.pattern(REGEX.NUMBER)]),
      dateOfBirth: new FormControl('', [Validators.required]),
      country: new FormControl('', Validators.required),
      /** *******************************************************************
      ****** DATE: 18 Oct 2023
      ****** REASON: Student Type: International is to be default
      ****** OLD CODE:

      await this.typeChange('transfer');

    ******************************************************************** */
      type: new FormControl('international', Validators.required),
      /*
      *********************************************************************
      ****** DATE: 18 Oct 2023
      ****** REASON: Client comment : Password Field: Remove or Hide
      ****** OLD CODE:
      password: new FormControl('', [Validators.required]),
      *********************************************************************
      */
      password: new FormControl(''),
      levelOfStudy: new FormControl('', [Validators.required]),
      universityInfo: this.fb.array([]),
      languageTestInfo: this.fb.array([]),
      academicTypeInfo: this.fb.array([]),
      academicsStandardisedTestInfo: this.fb.array([]),
      // academicsMastersOrPGDiploma: this.fb.array([]),
    });
    /** *******************************************************************
      ****** DATE: 18 Oct 2023
      ****** REASON: Student Type: International is to be default
      ****** OLD CODE:

      await this.typeChange('transfer');

    ******************************************************************** */
    await this.typeChange('international');
    setTimeout(async () => {
      await this.addUniversityInfo();
      // await this.addacademicsBachelors();
    }, 1000);
  }
  getCountryList() {
    this.fullCountryArray = this.countryStateCityService.getAllCountriesNew();
  }
  getProgramList() {
    this.fullProgramArray = this.sharedService.allUniPrograms();
    this.fullProgramArraySearch = this.sharedService.allUniPrograms();
  }
  getTermsList() {
    this.fullTermArray = this.sharedService.allUniTerms();
  }
  getUniversityList() {
    this.UniversityListArray = this.sharedService.allUniversity().sort((a, b) => a.name.localeCompare(b.name));
  }
  // Language-Test-Form-Array
  get languageTestInfo(): FormArray {
    return this.studentInfoform.get('languageTestInfo') as FormArray;
  }
  newlanguageTestInfo(type): FormGroup {
    return this.fb.group({
      type: new FormControl(type, [Validators.required]),
      date: new FormControl('', [Validators.required]),
      score: new FormControl('', [Validators.required, Validators.pattern(REGEX.NUMBER), Validators.maxLength(3), Validators.minLength(1)]),
    });
  }
  addlanguageTestInfo(type) {
    const index = this.languageTestInfo.controls.findIndex(x => x.value.type === type);
    if (index > -1) {
      this.removelanguageTestInfo(index);
    } else {
      this.languageTestInfo.push(this.newlanguageTestInfo(type));
    }
  }
  removelanguageTestInfo(i: number) {
    this.languageTestInfo.removeAt(i);
  }
  // Language-Test-Form-Array
  get academicTypeInfo(): FormArray {
    return this.studentInfoform.get('academicTypeInfo') as FormArray;
  }
  newacademicTypeInfo(type): FormGroup {
    return this.fb.group({
      type: new FormControl(type, []),
      name: new FormControl('', []),
      yearFrom: new FormControl('', []),
      yearTo: new FormControl('', []),
      /*
        ****** DATE: 18 Oct 2023
        ****** REASON: Emergency phone is not mandatory
        ****** OLD CODE:
        gradeOrPercent: new FormControl('', [Validators.required, Validators.pattern(REGEX.GRADE), Validators.maxLength(3), Validators.minLength(1)]),
      */
      gradeOrPercent: new FormControl('', [Validators.pattern(REGEX.GRADE), Validators.maxLength(3), Validators.minLength(1)]),
      fails: new FormControl('', []),
    });
  }
  addacademicTypeInfo(type) {
    const index = this.academicTypeInfo.controls.findIndex(x => x.value.type === type);
    if (index > -1) {
      this.removeacademicTypeInfo(index);
    } else {
      this.academicTypeInfo.push(this.newacademicTypeInfo(type));
    }
  }
  removeacademicTypeInfo(i: number) {
    this.academicTypeInfo.removeAt(i);
  }
  // Academic-Test-Form-Array
  get academicsStandardisedTestInfo(): FormArray {
    return this.studentInfoform.get('academicsStandardisedTestInfo') as FormArray;
  }
  newacademicsStandardisedTestInfo(type): FormGroup {
    return this.fb.group({
      type: new FormControl(type, [Validators.required]),
      date: new FormControl('', [Validators.required]),
      score: new FormControl('', [Validators.required, Validators.pattern(REGEX.NUMBER), Validators.maxLength(3), Validators.minLength(1)]),
    });
  }
  addacademicsStandardisedTestInfo(type) {
    const index = this.academicsStandardisedTestInfo.controls.findIndex(x => x.value.type === type);
    if (index > -1) {
      this.removeacademicsStandardisedTestInfo(index);
    } else {
      this.academicsStandardisedTestInfo.push(this.newacademicsStandardisedTestInfo(type));
    }
  }
  removeacademicsStandardisedTestInfo(i: number) {
    this.academicsStandardisedTestInfo.removeAt(i);
  }
  // University-info-form-array
  get universityInfo(): FormArray {
    return this.studentInfoform.get('universityInfo') as FormArray;
  }
  newUniversityInfo(): FormGroup {
    return this.fb.group({
      name: new FormControl('', [Validators.required]),
      program: new FormControl('', [Validators.required]),
      year: new FormControl('', [Validators.required]),
      term: new FormControl('', [Validators.required]),
      uuid: new FormControl(''),
    }, {
      validators: [this.isUniDuplicate('name', 'program')]
    });
  }
  addUniversityInfo() {
    if (this.universityInfo.controls.length >= this.maxUniversityCount) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      if (this.studentInfoform.get('type').value == 'international') {
        this.snackBar.open('Allowed 3 universities for International Students', '', {
          duration: 2000
        });
        // eslint-disable-next-line @typescript-eslint/dot-notation
      } else if (this.studentInfoform.get('type').value == 'transfer') {
        this.snackBar.open('Allowed 2 universities for Transfer Students', '', {
          duration: 2000
        });
      }
    } else {
      let blankEntry = false;
      this.universityInfo.value.forEach(element => {
        if (element.name === '') {
          blankEntry = true;
        }
      });
      if (blankEntry) {
        this.snackBar.open('Please choose the university from the list.', '', {
          duration: 2000
        });
      } else {
        this.universityInfo.push(this.newUniversityInfo());
        this.ManageNameControl(this.universityInfo.controls.length - 1);
      }
    }
  }
  removeUniversityInfo(i: number) {
    this.universityInfo.removeAt(i);
  }

  get academicsMastersOrPGDiploma(): FormArray {
    return this.studentInfoform.get('academicsMastersOrPGDiploma') as FormArray;
  }
  newacademicsMastersOrPGDiploma(type): FormGroup {
    return this.fb.group({
      academicsMastersOrPGDiplomaName: new FormControl('', [Validators.required]),
      academicsMastersOrPGDiplomaInstitution: new FormControl('', [Validators.required]),
      academicsMastersOrPGDiplomaNameYearFrom: new FormControl('', Validators.required),
      academicsMastersOrPGDiplomaNameYearTo: new FormControl('', Validators.required),
      /**
       ***************************************************
       ****** DATE: 18 Oct 2023
      ****** REASON: Client asked to not make it mandatory
      ****** OLD CODE:
      academicsMastersOrPGDiplomaGradeOrPercent: new FormControl('', [Validators.required, Validators.pattern(REGEX.GRADE), Validators.maxLength(3), Validators.minLength(1)]),
       ***************************************************
       */
      academicsMastersOrPGDiplomaGradeOrPercent: new FormControl('', [Validators.pattern(REGEX.GRADE), Validators.maxLength(3), Validators.minLength(1)]),
      academicsMastersOrPGDiplomaFails: new FormControl(''),
      academicsMastersOrPGDiplomaType: new FormControl(type, Validators.required),
    });
  }
  addacademicsBachelors() {
    let blankEntryBachelors = false;
    this.academicsMastersOrPGDiploma.value.forEach(element => {
      if (element.academicsMastersOrPGDiplomaName === '') {
        blankEntryBachelors = true;
      }
    });
    if (blankEntryBachelors) {
      this.snackBar.open('Please add academic information.', '', {
        duration: 2000
      });
    } else {
      const bachelors = this.academicsMastersOrPGDiploma.value.filter(x => x.academicsMastersOrPGDiplomaType === 'bachelors');
      if (bachelors.length < 3) {
        this.academicsMastersOrPGDiploma.push(this.newacademicsMastersOrPGDiploma('bachelors'));
      } else {
        this.snackBar.open('You cannot add no more than 3 bachelors', '', {
          duration: 2000
        });
      }
    }
  }
  addacademicsMasters() {
    let blankEntrymasters = false;
    this.academicsMastersOrPGDiploma.value.forEach(element => {
      if (element.academicsMastersOrPGDiplomaName === '') {
        blankEntrymasters = true;
      }
    });
    if (blankEntrymasters) {
      this.snackBar.open('Please add academic information.', '', {
        duration: 2000
      });
    } else {
      const masters = this.academicsMastersOrPGDiploma.value.filter(x => x.academicsMastersOrPGDiplomaType === 'masters');
      if (masters.length < 3) {
        this.academicsMastersOrPGDiploma.push(this.newacademicsMastersOrPGDiploma('masters'));
      } else {
        this.snackBar.open('You cannot add no more than 3 masters', '', {
          duration: 2000
        });
      }
    }
  }
  addacademicsPGDiploma() {
    let blankEntrypgdiploma = false;
    this.academicsMastersOrPGDiploma.value.forEach(element => {
      if (element.academicsMastersOrPGDiplomaName === '') {
        blankEntrypgdiploma = true;
      }
    });
    if (blankEntrypgdiploma) {
      this.snackBar.open('Please add academic information.', '', {
        duration: 2000
      });
    } else {
      const pgdiploma = this.academicsMastersOrPGDiploma.value.filter(x => x.academicsMastersOrPGDiplomaType === 'pgdiploma');
      if (pgdiploma.length < 3) {
        this.academicsMastersOrPGDiploma.push(this.newacademicsMastersOrPGDiploma('pgdiploma'));
      } else {
        this.snackBar.open('You cannot add no more than 3 pgdiploma', '', {
          duration: 2000
        });
      }
    }
    // this.academicsMastersOrPGDiploma.push(this.newacademicsMastersOrPGDiploma('pgdiploma'));
  }
  removeacademicsMastersOrPGDiploma(i: number) {
    this.academicsMastersOrPGDiploma.removeAt(i);
  }
  getAcademicMasterOrDiploma(type) {
    switch (type) {
      case 'masters':
        return 'Masters';
      case 'pgdiploma':
        return 'Pg Diploma';
      case 'bachelors':
        return 'Bachelors';

      default:
        return 'Academic';
    }
  }
  getUniProgramList() {

  }

  // * Common Autocomplete open full options
  public onClickSelectAutocompleteField() {
    this.sharedService.isFullArrayOptionBehaviorSubject.next(true);
  }
  public onClickSelectAutocompleteFieldProgram(i) {
    this.fullProgramArray = [];
    this.sharedService.universitySelectedBehaviorSubject.next(i.value.name);
    this.sharedService.isFullArrayOptionBehaviorSubject.next(true);
  }
  public onClickSelectAutocompleteFieldTerm(i) {
    this.terms = [];
    this.sharedService.universitySelectedBehaviorSubject.next(i.value.name);
    this.sharedService.isFullArrayOptionBehaviorSubject.next(true);
  }
  public onSelectAutocomplete(event) {
    const { value } = event.option;
    this.studentInfoform.get('country').patchValue(value);
    setTimeout(() => {
      console.log(this.studentInfoform.get('country').value);
    }, 2000);
  }
  public onSelectAutocompleteProgram(event) {
    const { value } = event.option;
    this.programInfoform.get('country').patchValue(value);
  }
  public onSelectAutocompleteCode(event, controller) {
    const { value } = event.option;
    this.studentInfoform.get(controller).patchValue(value);
  }
  public onSelectAutocompleteCodeProgram(event, index) {
    const { value } = event.option;
    this.universityInfo.at(index).get('program').patchValue(value);
  }
  public onSelectAutocompleteCodeTerm(event, index) {
    const { value } = event.option;
    this.universityInfo.at(index).get('term').patchValue(value);
  }
  public onSelectAutocompleteUniversity(event, index) {
    const { value } = event.option;
    this.universityInfo.at(index).get('program').patchValue('');
    this.universityInfo.at(index).get('term').patchValue('');
    this.universityInfo.at(index).get('year').patchValue('');
    this.universityInfo.at(index).get('name').patchValue(value);
    this.sharedService.universitySelectedBehaviorSubject.next(value);
  }
  ManageNameControl(index: number) {
    const arrayControl = this.universityInfo;
    this.universityAutocompleteObservable[index] = arrayControl.at(index).get('name').valueChanges.pipe(
      startWith(null),
      switchMap(name => {
        if (typeof name === 'string') {
          return of(this.UniversityListArray).pipe(
            delay(800),
            map(response => response.filter(p => p.name.toUpperCase().includes(name)))
          );
        }
        return of([]);
      })
    );
    this.programAutocompleteObservable[index] = arrayControl.at(index).get('program').valueChanges.pipe(
      startWith(null),
      switchMap(name => {
        if (typeof name === 'string') {
          return of(this.fullProgramArray).pipe(
            delay(800),
            map(response => response.filter(p => p.name.toUpperCase().includes(name)))
          );
        }
        return of([]);
      })
    );
    this.termsAutocompleteObservable[index] = arrayControl.at(index).get('term').valueChanges.pipe(
      startWith(null),
      switchMap(name => {
        if (typeof name === 'string') {
          return of(this.fullTermArray).pipe(
            delay(800),
            map(response => response.filter(p => p.toUpperCase().includes(name)))
          );
        }
        return of([]);
      })
    );
  }
  getAcademicTypeInfo(value: string) {
    const index = this.academicType.findIndex(x => x.value === value);
    if (index > -1) {
      return this.academicType[index].name;
    }
  }
  addStudent() {
    console.log(this.studentInfoform.value);
    if (this.studentInfoform.invalid) {
      this.studentInfoform.markAllAsTouched();
      this.snackBar.open('Please check your form entries again.', '', {
        duration: 2000
      });
      this.submitClicked = true;
    } else {
      if (this.studentInfoform.value.universityInfo.length <= 0) {
        this.snackBar.open('Please add atleast one university', '', {
          duration: 2000
        });
        return;
      }
      // if (this.studentInfoform.value.academicsMastersOrPGDiploma.length <= 0) {
      //   this.snackBar.open('Please add atleast one record for either bachelors or master or pg diploma', '', {
      //     duration: 2000
      //   });
      //   return;
      // }
      if (this.studentInfoform.value.type === 'transfer' && this.universityInfo.controls.length > 2) {
        this.snackBar.open('Allowed 2 universities for Transfer Students', '', {
          duration: 2000
        });
        return;
      }
      if (this.studentInfoform.value.type === 'international' && this.universityInfo.controls.length > 3) {
        this.snackBar.open('Allowed 3 universities for International Students', '', {
          duration: 2000
        });
        return;
      }
      this.submitClicked = false;
      this.studentInfoform.value.universityInfo.forEach(element => {
        element.uuid = this.getUUID(4);
        element.name = this.sharedService.filteredUniversity(element.name)[0].name;
        element.program = this.sharedService.filteredProgram(element.program)[0].name;
        element.term = this.sharedService.filteredTerms(element.term)[0];
      });
      this.studentInfoform.value.country = this.countryStateCityService.filterCountryByName(this.studentInfoform.value.country)[0].name;
      let obj = this.studentInfoform.value;
      // replace uppercase with original string
      // country
      obj = Object.assign(obj, {
        recruiterId: this.recruiter.userId,
        roleId: this.recruiter.roleId,
        recruiterSalesforceId: this.recruiter.salesforceId
      });
      this.store.dispatch(new CreateStudent(obj));
    }
  }
  getProgramByUniversity() {
    const prgrams = [...this.fullProgramArraySearch];
    this.terms = []; this.fullProgramArray = [];
    this.sharedService.universitySelectedDialogObservable.subscribe((data) => {
      if (data != null && data != undefined && data != '') {
        const uniIndex = this.sharedService.universitiesList.findIndex(x => x.name.toLowerCase() === data.toLowerCase());
        const uniInfo = this.sharedService.universitiesList[uniIndex];
        // this.terms = uniInfo.terms;
        this.fullTermArray = uniInfo.terms;
        if (uniIndex > -1) {
          const prgs = prgrams.filter(x => x.universityId === uniInfo.id);
          if (prgs.length > 0) {
            this.fullProgramArray = prgs;
          } else {
            this.fullProgramArray = this.fullProgramArraySearch;
          }
        }
      } else {
        // this.snackBar.open('Please select university first', '', {
        //   duration: 2000
        // });
        this.fullProgramArray = [];
      }
    });
  }
  typeChange(value) {
    switch (value) {
      case 'transfer':
        this.maxUniversityCount = 2;
        if (this.universityInfo.controls.length > 2) {
          this.snackBar.open('Allowed 2 universities for Transfer Students.', '', {
            duration: 5000
          });
        }
        break;
      case 'international':
        this.maxUniversityCount = 3;
        break;

      default:
        break;
    }
  }
  onSelectYearFrom(value, index, formgroup, controller) {
    switch (formgroup) {
      case 'academicsMastersOrPGDiploma':
        this.academicsMastersOrPGDiploma.at(index).get(controller).patchValue(value);
        break;
      case 'academicTypeInfo':
        this.academicTypeInfo.at(index).get(controller).patchValue(value);
        break;
      case 'universityInfo':
        this.universityInfo.at(index).get(controller).patchValue(value);
        break;

      default:
        break;
    }
  }
  isUniDuplicate(nameController: string, programController: string): ValidatorFn {
    return (controls: AbstractControl) => {
      this.universityInfo.setErrors(null);
      const uniInfo = this.universityInfo.value;

      if (uniInfo.length > 1) {
        let blankNames = false;
        uniInfo.forEach(element => {
          if (element.name === '' && element.program === '' && element.term === '') {
            blankNames = true;
          }
        });
        if (!blankNames) {
          const output = uniInfo.reduce((acc, curr) => {
            curr.count = 1;
            const exists = acc.find(o => o.name === curr.name && o.program === curr.program);

            exists ? exists.count++ : acc.push({
              name: curr.name,
              program: curr.program,
              count: curr.count
            });

            return acc;
          }, []);
          output.map(x => {
            if (x.count > 1) {
              this.snackBar.open('Duplicate Program with same university is not allowed', '', {
                duration: 2000
              });

              controls.get(nameController)?.setErrors({ duplicate: true });
              controls.get(programController)?.setErrors({ duplicate: true });

              return ({ duplicate: true });
            }
            return null;
          });
        }
        return null;
      }
      return null;
    };
  }
  ngOnDestroy() {
    for (const i of this.subscriptions) {
      i.unsubscribe();
    }
  }
  getUUID(parts: number): string {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      // eslint-disable-next-line no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }
}
