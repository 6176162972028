<div fxFlex="100"  style=" width: 60px;">

	<div fxLayoutAlign="space-between center">
		<h4 class="m-0"><b>Select Currency</b></h4>

		<button mat-icon-button color="primary" mat-dialog-close>
			<mat-icon>close</mat-icon>
		  </button>
	</div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class='select' fxLayout="row ">
            <mat-form-field fxFlex="1 2 auto">
                <mat-label>Select </mat-label>
                <mat-select  #select multiple   formControlName="country" 
                
                disableOptionCentering panelClass="explorePanelClass">
                
                <div class="select-all">
                  <mat-checkbox [(ngModel)]="allSelected"
                                  [ngModelOptions]="{standalone: true}"
                                  (change)="toggleAllSelection()"><b>Select All</b></mat-checkbox>
              </div>
                   
                  <mat-option (click)="optionClick()" *ngFor="let option of currencyFilter " [value]="option.countries"
                  [matTooltip]="option.currency"> 
                    {{option.currency}}
                  </mat-option>
                </mat-select>
              </mat-form-field> 
           
           
             <div fxLayout="row " class="buttton">
              <button mat-raised-button color="primary"  class="btn-block btn-sm " type="submit"
              [disabled]="!form.valid">Select</button>

             </div>
            
        </div>
    </form>
</div>

