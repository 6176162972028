<mat-toolbar color="primary" class="topbar telative" *ngIf="isSeperateExploreUniversities"
  style="position: fixed;top: 0;">
  <!-- ============================================================== -->
  <!-- Logo - style you can find in header.scss -->
  <!-- ============================================================== -->
  <div class="navbar-header">
    <a class="navbar-brand" href="javascript:void(0)">
      <b>
        <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo">
        <img src="assets/images/univ-hub-logo-only.png" alt="homepage" class="light-logo app-logo">
      </b>
      <!--  fxShow="false" fxShow.gt-xs -->
      <span>
        <span style="color: white; padding-left: 2px;">University HUB</span>
      </span>
    </a>
  </div>
</mat-toolbar>
<div [ngClass]="{'content-bg': isSeperateExploreUniversities}">
  <mat-card appearance="outlined" style="border:none;">
    <mat-card-header>
      <mat-card-title>
        Explore Institute
      </mat-card-title>
    </mat-card-header>

    <mat-card-content class="p-t-0 p-b-0">


      <div class="example-list app-content">
        <div>
          <form fxLayout.gt-md="row wrap" fxLayout="column" fxLayoutGap="12px" [formGroup]="universityPreferenceForm">

            <!-- Country of Study -->
            <!-- <mat-form-field fxFlex="32">
              <input type="text" placeholder="Select Country of Study" matInput [matAutocomplete]="countryAutocomplete"
                formControlName="country" (keyup)="keyPressCountryInput($event.target.value)">
              <mat-autocomplete #countryAutocomplete="matAutocomplete"
                (optionSelected)="onChangeCountrySelected($event)">
                
                <mat-option *ngFor="let option of countryAutocompleteObservable | async" [value]="option.value"
                  [matTooltip]="option.value">
                  {{option.value}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field> -->

            <mat-form-field class="matForm-field" fxFlex="32">
              <mat-label style="font-size:14px; font-weight: 400;">Select Country of Study</mat-label>
              <mat-select #selectCountry formControlName="country"
                (closed)="onCloseMatSelectPanel();onChangeCountrySelected($event)" disableOptionCentering
                panelClass="explorePanelClass">
                <!-- <div class="select-all">
                <mat-checkbox [(ngModel)]="countryAllSelected"
                              [ngModelOptions]="{standalone: true}"
                              (change)="multiSelection('country')">
                              Select All
                </mat-checkbox>
              </div> -->
                <mat-option (click)="optionClick('country')"
                  *ngFor="let option of countryAutocompleteObservable | async" [value]="option.value"
                  [matTooltip]="option.value">
                  {{option.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <mat-form-field fxFlex="32">
              <mat-label>Select Country of Study</mat-label>
              <mat-select multiple disableOptionCentering panelClass="explorePanelClass"
                fxFlex="100" formControlName="country">
                <mat-form-field floatLabel="never" class="search-filter">
                  <input matInput  type="text" name="filter-options"
                    id="filter-options" placeholder="Search">
                </mat-form-field>
                  <mat-option *ngFor="let option of countryAutocompleteObservable | async" [value]="option"
                    [matTooltip]="option">
                    {{option}}
                  </mat-option>
              </mat-select>
            </mat-form-field> -->

            <!-- Country Multi select -->
            <!-- <mat-form-field fxFlex="32">
              <mat-label>Select Country of Study</mat-label>
              <mat-select formControlName="country" (closed)="onCloseMatSelectPanel()" multiple
                disableOptionCentering panelClass="explorePanelClass"
                (selectionChange)="onChangeCountrySelection()"
                [matTooltip]="universityPreferenceForm.get('country').value">
                <mat-form-field floatLabel="never" class="search-filter">
                    <input matInput 
                    formControlName="countryFilter"
                    type="text" 
                    name="filter-options" 
                    id="filter-options" 
                    placeholder="Search"
                    (keydown)="$event.stopPropagation()">
                </mat-form-field>
                <mat-option *ngFor="let option of countryAutocompleteObservable | async" [value]="option.value"
                  [ngClass]="{'hide': !option.selected}" [matTooltip]="option.value">
                  {{option.value}}
                </mat-option>
              </mat-select>
            </mat-form-field> -->

            <!-- Degree Type -->
            <!-- <mat-form-field fxFlex="32">
              <input type="text" placeholder="Select Degree Type" matInput [matAutocomplete]="degreeAutocomplete"
                formControlName="degree" (keyup)="keyPressDegreeInput($event.target.value)">
              <mat-autocomplete #degreeAutocomplete="matAutocomplete" (optionSelected)="onChangeDegreeSelected($event)">
                <mat-option *ngFor="let option of degreeAutocompleteObservable | async" [value]="option"
                  [matTooltip]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field> -->

            <mat-form-field fxFlex="32">
              <mat-label style="font-size:14px; font-weight: 400;">Select Degree Type</mat-label>
              <mat-select formControlName="degree" (closed)="onCloseMatSelectPanel() ; courseTuitionFeeFilter()"
                (selectionChange)="resetCourse()" multiple disableOptionCentering panelClass="explorePanelClass"
                [matTooltip]="universityPreferenceForm.get('degree').value">
                <mat-form-field floatLabel="never" class="search-filter">
                  <input matInput formControlName="degreeFilter" type="text" name="filter-options" id="filter-options"
                    placeholder="Search" (keydown)="$event.stopPropagation()">
                </mat-form-field>
                <mat-option *ngFor="let option of degreeAutocompleteObservable | async" [value]="option.value"
                  [ngClass]="{'hide': !option.selected}" [matTooltip]="option.value">
                  {{option.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Course/Program -->
            <!-- <mat-form-field fxFlex="32">
              <input type="text" placeholder="Select Course/Program" matInput [matAutocomplete]="courseAutocomplete"
                formControlName="course" (keyup)="keyPressCourseInput($event.target.value)">
              <mat-autocomplete #courseAutocomplete="matAutocomplete" (optionSelected)="onChangeCourseSelected($event)">
                <mat-option *ngFor="let option of courseAutocompleteObservable | async" [value]="option"
                  [matTooltip]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field> -->
            <mat-form-field fxFlex="32">
              <mat-label style="font-size:14px; font-weight: 400;">Select Course/Program</mat-label>
              <mat-select #selectCourse formControlName="course" (closed)="onCloseMatSelectPanel()" multiple
                disableOptionCentering panelClass="explorePanelClass"
                [matTooltip]="universityPreferenceForm.get('course').value">
                <mat-form-field floatLabel="never" class="search-filter">
                  <input matInput formControlName="courseFilter" type="text" name="filter-options" id="filter-options">
                  <!-- placeholder="Search( Press Enter to Select/Deselect All )"
                    (keydown)="$event.stopPropagation()"
                   (keyup)="courseSelection($event)"-->
                </mat-form-field>
                <div class="select-all">
                  <mat-checkbox [(ngModel)]="courseAllSelected" [ngModelOptions]="{standalone: true}"
                    (change)="multiSelection('course')">
                    Select All
                  </mat-checkbox>
                </div>
                <mat-option (click)="optionClick('course')" *ngFor="let option of courseAutocompleteObservable | async"
                  [value]="option.value" [ngClass]="{'hide': !option.selected}" [matTooltip]="option.value">
                  {{option.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Application Fees -->

            <mat-form-field *ngIf="!isMoreFilter" fxFlex="32">
              <mat-label>Select Application Fees</mat-label>
              <!-- [disabled]="universityPreferenceForm.get('country').value?.length > 1" -->
              <mat-select multiple (click)="filterDialog()" formControlName="applicationFees"
                (closed)="onCloseMatSelectPanel()" disableOptionCentering panelClass="explorePanelClass"
                [matTooltip]="universityPreferenceForm.get('applicationFees').value">
                <!-- [disabled]="!universityPreferenceForm.get('country').value" -->
                <mat-option *ngFor="let option of FeesDataFiltered" [value]="option" [matTooltip]="option">
                  {{option}}
                </mat-option>
              </mat-select>
              <!-- <mat-hint class="text-danger" *ngIf="!universityPreferenceForm.get('country').value">
                Please select a Country in order to use this filter
              </mat-hint> -->
            </mat-form-field>

            <!-- Tuition Fees -->
            <mat-form-field *ngIf="!isMoreFilter" fxFlex="32">
              <mat-label>Select Tuition Fees</mat-label>
              <!-- [disabled]="universityPreferenceForm.get('country').value?.length > 1" -->
              <mat-select multiple (click)="filterDialog()" formControlName="tuitionFees"
                (closed)="onCloseMatSelectPanel()" disableOptionCentering panelClass="explorePanelClass"
                [matTooltip]="universityPreferenceForm.get('tuitionFees').value">
                <!-- [disabled]="!universityPreferenceForm.get('country').value" -->
                <mat-option *ngFor="let option of tuitionDataFiltered" [value]="option" [matTooltip]="option">
                  {{option}}
                </mat-option>
              </mat-select>
              <!-- <mat-hint class="text-danger" *ngIf="!universityPreferenceForm.get('country').value">
                Please select a Country in order to use this filter
              </mat-hint> -->
            </mat-form-field>

            <!-- Living Cost -->
            <mat-form-field *ngIf="!isMoreFilter" fxFlex="32">
              <mat-label>Select Living Cost</mat-label>
              <!-- [disabled]="universityPreferenceForm.get('country').value?.length > 1" -->
              <mat-select multiple (click)="filterDialog()" formControlName="livingCost"
                (closed)="onCloseMatSelectPanel()" disableOptionCentering panelClass="explorePanelClass"
                [matTooltip]="universityPreferenceForm.get('livingCost').value">
                <!-- [disabled]="!universityPreferenceForm.get('country').value" -->
                <mat-option *ngFor="let option of livingCostDataFiltered" [value]="option" [matTooltip]="option">
                  {{option}}
                </mat-option>
              </mat-select>
              <!-- <mat-hint class="text-danger" *ngIf="!universityPreferenceForm.get('country').value">
                Please select a Country in order to use this filter
              </mat-hint> -->
            </mat-form-field>

            <!-- TO SEARCH -->
            <!-- <mat-form-field floatLabel="never" class="search-filter">
              <input matInput 
              formControlName="englishProficiencyFilter"
              type="text" 
              name="filter-options" 
              id="filter-options" 
              placeholder="Search"
              (keydown)="$event.stopPropagation()">
          </mat-form-field> -->

            <!-- English Proficiency Requirement -->
            <mat-form-field *ngIf="!isMoreFilter" fxFlex="32">
              <mat-label>Select English Proficiency Requirement</mat-label>
              <mat-select formControlName="englishProficiency" (closed)="onCloseMatSelectPanel()" multiple
                disableOptionCentering panelClass="explorePanelClass"
                [matTooltip]="universityPreferenceForm.get('englishProficiency').value">
                <mat-option *ngFor="let option of englishProficiencyObservable | async" [value]="option.value"
                  [ngClass]="{'hide': !option.selected}" [matTooltip]="option.value">
                  {{option.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Intake -->
            <mat-form-field *ngIf="!isMoreFilter" fxFlex="32">
              <mat-label>Select Intake</mat-label>
              <mat-select formControlName="intake" (closed)="onCloseMatSelectPanel()" multiple disableOptionCentering
                panelClass="explorePanelClass" [matTooltip]="universityPreferenceForm.get('intake').value">
                <mat-form-field floatLabel="never" class="search-filter">
                  <input matInput formControlName="intakeFilter" type="text" name="filter-options" id="filter-options"
                    placeholder="Search" (keydown)="$event.stopPropagation()">
                </mat-form-field>
                <mat-option *ngFor="let option of intakeObservable | async" [value]="option.value"
                  [ngClass]="{'hide': !option.selected}" [matTooltip]="option.value">
                  {{option.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Minimum GPA -->
            <mat-form-field *ngIf="!isMoreFilter" fxFlex="32">
              <mat-label>Select Minimum GPA</mat-label>
              <mat-select multiple formControlName="minimumGPA" (closed)="onCloseMatSelectPanel()"
                disableOptionCentering panelClass="explorePanelClass"
                [matTooltip]="universityPreferenceForm.get('minimumGPA').value">
                <mat-option *ngFor="let option of minimumGPAData" [value]="option" [matTooltip]="option">
                  {{option}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div *ngIf="!isMoreFilter" fxFlex="100" fxLayout.gt-xs="row" fxLayout="column" fxLayoutGap="10px"
              class="m-t-20" fxLayoutAlign="center start" fxLayoutAlign.gt-xs="start center">
              <div fxFlex.gt-xs="20" fxFlex="100" class="advanced-filter">
                GRE/GMAT Requirement
              </div>
              <div fxLayout="row" fxLayoutGap="20px" class="cursor-pointer">
                <div [ngClass]="greGmatRequirement === 'Yes' ? 'rounded-primary' : 'rounded-gray'"
                  (click)="onClickGreGmatRequirement('Yes')">
                  Yes
                </div>
                <div fxLayout="row" fxLayoutGap="5px" class="cursor-pointer">
                  <div [ngClass]="greGmatRequirement === 'No' ? 'rounded-primary' : 'rounded-gray'"
                    (click)="onClickGreGmatRequirement('No')">
                    No
                  </div>
                  <mat-icon *ngIf="greGmatRequirement === 'No'"
                    matTooltip="GRE requirement depends on GPA acheived and course taken. Please contact admissions team for more information."
                    class="color-black">
                    info
                  </mat-icon>
                </div>
              </div>
            </div>

            <div *ngIf="!isMoreFilter" fxFlex="100" fxLayout.gt-xs="row" fxLayout="column" fxLayoutGap="10px"
              class="m-t-20" fxLayoutAlign="center start" fxLayoutAlign.gt-xs="start center">
              <div fxFlex.gt-xs="20" fxFlex="100" class="advanced-filter">
                STEM PROGRAM
              </div>
              <div fxLayout="row" fxLayoutGap="20px" class="cursor-pointer">
                <div [ngClass]="isStemProgram === 'Yes' ? 'rounded-primary' : 'rounded-gray'"
                  (click)="onClickStemProgram('Yes')">
                  Yes
                </div>
                <div [ngClass]="isStemProgram === 'No' ? 'rounded-primary' : 'rounded-gray'"
                  (click)="onClickStemProgram('No')">
                  No
                </div>
              </div>
            </div>

            <div *ngIf="!isMoreFilter" fxFlex="100" fxLayout.gt-xs="row" fxLayout="column" fxLayoutGap="10px"
              class="m-t-20" fxLayoutAlign="center start" fxLayoutAlign.gt-xs="start center">
              <div fxFlex.gt-xs="20" fxFlex="100" class="advanced-filter">
                Mode of Learning
              </div>
              <div fxLayout="row wrap" fxLayoutGap="20px" class="cursor-pointer">
                <div class="mr-b-10" (click)="onClickModeOfLearning('Online')"
                  [ngClass]="isModeOfLearning === 'Online' ? 'rounded-primary' : 'rounded-gray'">
                  Online
                </div>
                <div class="mr-b-10" (click)="onClickModeOfLearning('Classroom')"
                  [ngClass]="isModeOfLearning === 'Classroom' ? 'rounded-primary' : 'rounded-gray'">
                  Classroom
                </div>
                <div class="mr-b-10" (click)="onClickModeOfLearning('Hybrid')"
                  [ngClass]="isModeOfLearning === 'Hybrid' ? 'rounded-primary' : 'rounded-gray'">
                  Hybrid
                </div>
              </div>
            </div>

            <div *ngIf="!isMoreFilter" fxFlex="100" fxLayout.gt-xs="row" fxLayout="column" fxLayoutGap="10px"
              class="m-t-20" fxLayoutAlign="center start" fxLayoutAlign.gt-xs="start center">
              <div fxFlex.gt-xs="20" fxFlex="100" class="advanced-filter">
                No. of Campus
              </div>
              <div fxLayout="row" fxLayoutGap="20px" class="cursor-pointer">
                <div (click)="onClickNoOfCampus('One')"
                  [ngClass]="isNoOfCampus === 'One' ? 'rounded-primary' : 'rounded-gray'">
                  One
                </div>
                <div (click)="onClickNoOfCampus('Multiple')"
                  [ngClass]="isNoOfCampus === 'Multiple' ? 'rounded-primary' : 'rounded-gray'">
                  Multiple
                </div>
              </div>
            </div>

            <div fxLayout="row" fxFlex="100" class="m-t-20 cursor-pointer">
              <div class="filter-text-clr" (click)="onClickLessAndMoreFilters()">
                {{ isMoreFilter ? 'More filters' : 'Less filters' }}
              </div>
              <mat-icon class="filter-text-clr" (click)="onClickLessAndMoreFilters()">
                {{ isMoreFilter ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}
              </mat-icon>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" class="p-b-15 m-t-20" fxFlex="100">
              <div fxLayout="row" fxLayoutGap="12px">
                <button mat-flat-button class="bg-success text-white" (click)="onClickSearchUniversity()">
                  Search
                </button>
                <button mat-flat-button class="bg-primary text-white" (click)="onClickResetUniversity()"
                  [disabled]="getFormValue()">
                  Reset
                </button>
              </div>
              <div fxLayout="row" fxLayoutGap="12px">
                <button mat-flat-button class="bg-success text-white" (click)="onClickShowFavoriteUniversity()"
                  [disabled]="isFavoriteResult && filteredUniversity.length != 0"
                  *ngIf="!isSeperateExploreUniversities">
                  Show Favorites
                </button>
              </div>
            </div>
            <!-- <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start">
            <button mat-flat-button class="bg-success text-white" (click)="onClickSearchUniversity()">
              Search
            </button>
            <button mat-flat-button class="bg-danger text-white" (click)="onClickResetUniversity()">
              Reset
            </button>
          </div> -->
          </form>
        </div>
      </div>

    </mat-card-content>

  </mat-card>

  <!-- <mat-card>
    <mat-card-header>
      <mat-card-title>
        Search Result
      </mat-card-title>
    </mat-card-header>

    <mat-card-content class="p-t-0">
      <div class="example-list" *ngIf="filteredUniversity.length > 0">
        <div fxFlex="100" fxLayout="column" fxLayout.gt-md="row wrap">
          <div fxFlex.gt-sm="50" fxFlex="100" fxLayout="column" fxLayoutGap="7px"
            *ngFor="let temp of filteredUniversity; let i=index" class="section-height">
            <mat-card fxFlex="100" fxFlex.gt-xs class="university-box-shadow cursor-pointer m-t-0 m-l-0 m-r-0 m-b-15"
              (click)="onClickViewUniversities(temp)" fxLayout="column" fxLayout.gt-xs="row">
              <div style="padding: 15px 15px 0px 15px;" fxLayout="column" fxLayoutAlign="center" fxShow="true"
                fxShow.gt-xs="false">
                <div class="loader" *ngIf="!isImageLoading[i]">Loading...</div>
                <div style="width: 125px;height: 85px;">
                  <img class="university-logo" [src]="removeTag(temp.iconUrl)" [title]="temp.university"
                    alt="University Logo" (load)="onUniversityLogoLoaded(i)" />
                </div>
              </div>
              <div fxLayout="column" fxFlex="70" fxLayoutAlign="start" class="university-section">
                <div fxFlex fxLayoutAlign="start center">
                  <div class="m-0 app-main-heading"><b>{{temp.university}}</b></div>
                </div>
                <div fxFlex class="app-content" fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon fxLayout="column" fxLayoutAlign="end">
                    location_on
                  </mat-icon>
                  {{temp.country}}
                </div>
              </div>
              <div class="p-10" fxLayout="column" fxLayoutAlign="center" fxShow="false" fxShow.gt-xs>
                <div style="width: 125px;height: 85px;">
                  <div class="loader" *ngIf="!isImageLoading[temp.university]">Loading...</div>
                  <img class="university-logo" [src]="removeTag(temp.iconUrl)" [title]="temp.university"
                    alt="University Logo" (load)="onUniversityLogoLoaded(temp.university)" />
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>

      <div *ngIf="filteredUniversity.length === 0" fxLayoutAlign="center center">
        Please choose at-least one search criteria to search result
      </div>
    </mat-card-content>

  </mat-card> -->

  <mat-card appearance="outlined" class="example-card" *ngIf="filteredUniversity.length > 0">
    <mat-card-header fxLayout="column">
      <div fxFlex="100" *ngIf="filteredUniversity.length > 0">
        <small style="font-size: 13px;" *ngIf="role === 'STUDENT'"><span class="semi-bold">*
            Note:</span><span>Application fee may Vary. Please write to us at
            'applications@universityhub.com'</span></small>
        <small style="font-size: 13px;" *ngIf="role !== 'STUDENT'"><span class="semi-bold">* Note:</span> Application
          fee may Vary. Please reach us at 'recruiters@universityhub.com'</small>
      </div>
      <mat-card-title fxLayoutAlign="center center">
        {{isFavoriteResult ? 'Favorites' : 'Search Result'}}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout="column" fxLayout.gt-xs="row wrap">
      <ng-container *ngFor="let temp of filteredUniversity; let i=index">
        <div fxFlex.gt-md="50" fxFlex="100" fxLayoutGap="7px">
          <mat-card appearance="outlined" class="fancy-card m-10 cursor-pointer card-outline" fxFlex="100">
            <mat-card-header fxLayout.gt-xs="row" fxLayout="column" class="bg-w br-l-b" fxLayoutAlign="center start"
              fxLayoutAlign.gt-xs="space-between center">
              <mat-card-title class="header-lg" fxFlex>{{temp.university}}</mat-card-title>
              <div fxFlex.gt-xs="150px" fxFlex>
                <div style="width: 125px;">
                  <div class="loader" *ngIf="!isImageLoading[i]">Loading...</div>
                  <img class="university-logo" [src]="removeTag(temp.iconUrl)" [matTooltip]="temp.university"
                    alt="University Logo" (load)="onUniversityLogoLoaded(i)" />
                </div>
              </div>
            </mat-card-header>
            <mat-card-content class="p-12 bodyfont">
              <div fxLayout="column" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px">
                <!-- <div fxShow="true" fxShow.gt-xs="false">
                  <div style="width: 125px;height: 85px;">
                    <div class="loader" *ngIf="!isImageLoading[i]">Loading...</div>
                    <img class="university-logo" [src]="removeTag(temp.iconUrl)" [title]="temp.university"
                      alt="University Logo" (load)="onUniversityLogoLoaded(i)" />
                  </div>
                </div> -->
                <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign.gt-xs="space-between start"
                  fxLayoutAlign="center start" class="app-content" fxLayoutGap="20px">
                  <!-- <div fxLayout="row" fxLayoutGap="20px" fxFlex="50">
                    <div fxLayout="column" fxLayoutGap="20px">
                      <div fxLayout="row" fxLayoutGap="5px">
                        <mat-icon>location_on</mat-icon>
                        <div matTooltip="Location">
                          {{temp.universityAddress.city + ' ,' + temp.universityAddress.state + ' ,' + temp.universityAddress.country}}
                        </div>
                      </div>
                      <div fxLayout="row" fxLayoutGap="5px">
                        <mat-icon>school</mat-icon>
                        <div matTooltip="Intake">
                          Intake: {{temp.tileLevelIntake ? temp.tileLevelIntake : 'N/A'}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div fxLayout="row" fxFlex="50" fxLayout="row">
                    <div fxLayout="column" fxLayoutGap="20px">
                      <div fxLayout="row" fxLayoutGap="5px">
                        <mat-icon>account_balance</mat-icon>
                        <div matTooltip="Number of campus">
                          {{temp.tileLevelNumberOfCampus ? temp.tileLevelNumberOfCampus : 'N/A'}}
                        </div>
                      </div>
                      <div fxLayout="row" fxLayoutGap="5px">
                        <mat-icon>home</mat-icon>
                        <div matTooltip="Living cost">
                          {{temp.tileLevelLivingCost ? temp.tileLevelLivingCost : 'N/A'}}
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div fxLayout="row" fxLayoutGap="5px" fxFlex="50">
                    <mat-icon matTooltip="Location">location_on</mat-icon>
                    <div matTooltip="Location" style="word-break: break-all;">
                      <span class="semi-bold">Location: </span>
                      {{temp?.universityAddress?.city ? temp?.universityAddress?.city : 'N/A' + ' ,'
                      + temp?.universityAddress?.state ? temp?.universityAddress?.state : 'N/A' + ' ,'
                      + temp?.universityAddress?.country ? temp?.universityAddress?.country : 'N/A'}}
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutGap="5px" fxFlex="50">
                    <mat-icon matTooltip="Number of campus">account_balance</mat-icon>
                    <div matTooltip="Number of campus" style="word-break: break-all;">
                      <span class="semi-bold">Number of campus: </span>
                      {{temp.tileLevelNumberOfCampus ? temp.tileLevelNumberOfCampus : 'N/A'}}
                    </div>
                  </div>
                </div>
                <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign.gt-xs="space-between start"
                  fxLayoutAlign="center start" class="app-content" fxLayoutGap="20px">
                  <div fxLayout="row" fxLayoutGap="5px" fxFlex="50">
                    <mat-icon matTooltip="Intake">school</mat-icon>
                    <div matTooltip="Intake" style="word-break: break-all;">
                      <span class="semi-bold">Intake: </span>{{temp.tileLevelIntake ? temp.tileLevelIntake : 'N/A'}}
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutGap="5px" fxFlex="50">
                    <mat-icon matTooltip="Living cost">home</mat-icon>
                    <div matTooltip="Living cost" style="word-break: break-all;">
                      <span class="semi-bold">Living cost: </span>{{temp.tileLevelLivingCost ? temp.tileLevelLivingCost
                      : 'N/A'}}
                    </div>
                  </div>
                </div>
                <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign.gt-xs="space-between start"
                  fxLayoutAlign="center start" class="app-content" fxLayoutGap="20px">
                  <div fxLayout="row" fxLayoutGap="5px" fxFlex="50">
                    <mat-icon matTooltip="WES Evaluation or Others">assignment</mat-icon>
                    <div fxLayout="row">
                      <div matTooltip="WES Evaluation or Others">
                        <span class="semi-bold"> WES Evaluation or Others </span>
                        : {{temp.wesEvaluation ? temp.wesEvaluation : 'N/A'}}
                      </div>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutGap="5px" fxFlex="50">
                    <div class="fontawesome-div" fxLayoutAlign="center center">
                      <i matTooltip="English Proficiency Required" class="fa fa-book fa-lg"
                        fxLayoutAlign="center center"></i>
                    </div>
                    <div fxLayout="row">
                      <div matTooltip="English Proficiency Required">
                        <span class="semi-bold">English Proficiency Required</span>
                        :{{temp.tileLevelEnglishProficiency ? temp.tileLevelEnglishProficiency : 'N/A'}}
                      </div>
                      <!-- <div>
                      &nbsp;:{{temp.tileLevelEnglishProficiency ? temp.tileLevelEnglishProficiency : 'N/A'}}
                      </div> -->
                    </div>
                  </div>
                </div>
                <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign.gt-xs="space-between start"
                  fxLayoutAlign="center start" class="app-content" fxLayoutGap="20px">
                  <div fxLayout="row" fxLayoutGap="5px">
                    <mat-icon matTooltip="Application fee">
                      monetization_on
                    </mat-icon>
                    <div matTooltip="Application fee">
                      <span class="semi-bold">Application fee :</span> {{temp.tileLevelApplicationFees ?
                      temp.tileLevelApplicationFees : 'N/A'}}
                    </div>
                  </div>
                </div>
                <!-- <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign.gt-xs="center center"
                  fxLayoutAlign.gt-xs="center start" class="app-content" fxLayoutGap="20px">
                    <div fxLayout="column" fxLayoutGap.gt-xs="10px" fxLayoutGap="20px" fxFlex="50">
                      <div fxLayout="row" fxLayoutGap="5px">
                        <mat-icon>assignment</mat-icon>
                        <div fxLayout="row">
                          <div fxFlex.gt-md="65" matTooltip="WES Evaluation or Others">
                            WES Evaluation or Others
                          </div>
                          <div>
                           &nbsp;: {{temp.wesEvaluation ? temp.wesEvaluation : 'N/A'}}
                          </div>
                        </div>
                      </div>
                      <div fxLayout="row" fxLayoutGap="5px">
                        <mat-icon>
                          monetization_on
                        </mat-icon>
                        <div matTooltip="Application fee">
                          *Application fee - {{temp.tileLevelApplicationFees ? temp.tileLevelApplicationFees : 'N/A'}}
                        </div>
                      </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="10px" fxFlex="100">
                      <div fxLayout="row" fxLayoutGap="5px">
                        <div class="fontawesome-div" fxLayoutAlign="center center">
                          <i class="fa fa-book fa-lg"></i>
                        </div>
                        <div fxLayout="row">
                          <div fxFlex.gt-md="65" matTooltip="English Proficiency Required">
                            English Proficiency Required
                          </div>
                          <div>
                           &nbsp;:{{temp.tileLevelEnglishProficiency ? temp.tileLevelEnglishProficiency : 'N/A'}}
                          </div>
                        </div>
                      </div>
                    </div>
                </div> -->
                <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="end" class="app-content">
                  <!-- <div class="ta-justify">
                    <small><span class="semi-bold">*Note:</span> Application fee may vary. For any queries, all recruiter/partners please reach us on 'recruiters@universityhub.com'; and students, please write to us on 'applications@universityhub.com'</small>
                  </div> -->
                  <div fxLayout="row" fxLayoutAlign.gt-xs="center center" fxLayoutAlign="end end">
                    <button mat-icon-button color="primary" aria-label="Example icon button with a home icon"
                      (click)="onClickSaveAsFavorite(temp)" *ngIf="!isSeperateExploreUniversities">
                      <mat-icon
                        [ngClass]="isFavoriteUniversityOrNot(temp.universityId) ? 'fav-mat-icon' : 'unfav-mat-icon'">
                        star
                      </mat-icon>
                    </button>
                    <button mat-raised-button class="bg-success text-white" (click)="onClickViewUniversities(temp)">
                      Show More
                    </button>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
      <div class="p-text" *ngIf="filteredUniversity.length === 0" fxLayoutAlign="center center" fxFlex="100">
        {{isSearchResult ? 'No result found' : isFavoriteResult ? 'No favorite(s) found' : 'Please choose at-least one search criteria to search result'}}
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div  class="m-b-10">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h1>Exclusive Universities</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="card-container">
        <!-- campbellsville -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/images/campbellsville.png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
              margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 6
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Computer Science (MSCS) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in IT Management (MSITM) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Cloud Computing (MSCC) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Science in Data Science and Artificial Intelligence (MSDSAI) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Masters in Management & Leadership (MSML) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Professional Master Of Business Administration (P.MBA) - Hybrid
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>
        <!-- cumberland -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/universities/Cumberland Uni.png" />
            </div>
            <div>
              <mat-card-content style="padding:0!important;
            margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 3
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master Of Science in IT Management (MSITM) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master Of Science in Engineering Management (MSEM) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master Of Science in Computer Science (MSCS) - Hybrid
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>
        <!-- fransis -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/images/fransis.png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
            margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 5
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master Of Science in Professional Accountancy (MSPA) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master Of Science in Management (MSPM) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master Of Science in Exercise & Sport Science (MSESS) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master of Arts in Applied Behavioral Psychology (MAABP) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master Of Science in Information Technology (MSIT) - Hybrid
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>
        <!-- uwla -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/images/uwla.png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
            margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 1
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li><img src="../../../../assets/images/ticker.svg" class="img12">
                      Master Of Science in Leadership,Management and Technology (MSLMT) - Hybrid</li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>
        <!-- faulkner -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/images/faulkner.png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
            margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 2
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master Of Science in Management in Information Technology (MSITM) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master Of Science in Management in Business Analytics Emphasis (MEBA) - Hybrid
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>
        <!-- adelphi -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="./assets/universities/Adelphi Panther-Wordmark-FullColor-RGB.png" width="423" height="119">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
            margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 4
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master of Science in Supply Chain Management (MSSCM) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master of Business in Business Analytics (MBA) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master Of Science in Health Informatics (MSHI) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master Of Science in Computer Science (MSCS) - Hybrid
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>
        <!-- anderson -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="./assets/universities/Anderson.png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
            margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 3
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master Of Science in Business Analytics (MSBA) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master Of Science in Cyber Security Management (MSCS) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master Of Science in Computer Science (MSCS) - Hybrid
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>
        <!-- bsc -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/universities/BSC_logo.png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
            margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 1
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Master Of Science in Data Science (MSDS) - Hybrid
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>
        <!-- goldem gate -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/universities/golden-gate-university.png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
            margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 5
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Professional Master Of Science in IT Management (P.MSITM) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Professional Master Of Science in Data Science (P.MSDS) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Professional Master Of Science in Engineering Management (P.MSEM) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Professional Master Of Business Administration (P.MBA) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Professional Master Of Science in Computer Science (P.MSCS) - Hybrid
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>    
        <!-- university of the cumberland -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/universities/University-of-the-Cumberlands-logo.png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
            margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 13
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Executive Master of Science in Global Business With Blockchain Technology (E.MSBBT) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Executive Master of Science in Information Systems Security (E.MSIS) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Executive Master of Science in Information Technology (E.MSIT) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Executive Master of Science in Project Management (E.MSPM) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Executive Master of Science in Strategic Management (E.MSSM) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Executive Master of Science in Artificial Intelligence (E.MSAI) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Executive Master of Science in Cyber Engineering (E.MSCE)- Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Executive Master of Science in Digital Forensics (E.MSDF) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Executive Master Of Data Science (E.MDS) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Executive Master Of Business Administration (E.MBA) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Executive Ph.D. in Information Technology (E.PHDIT) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Executive Ph.D. in Leadership (E.PHDL) - Hybrid
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12">
                      Executive Ph.D. in Business (E.PHDB) - Hybrid
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>    
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Preferred Universities</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="card-container">
        <!-- Marshall University -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/universities/Marshall-University-LOGO.png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
              margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 6
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Computer Science (MSCS) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Cyber Security (MSCS) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Data Science (MSDS) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Business Administration (MBA) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Healthcare Administration (MSHA) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Health Informatics (MSHI) - On Campus 
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>    
        <!-- University Of Toledo -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/universities/U Toledo.png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
              margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 2
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Cyber Security (MCS) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Business Administration (MBA)  - On Campus 
                      <ul>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Finance
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Human Resource Management
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Information Systems
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Leadership
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Marketing Management or Professional Sales
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Operations and Supply Chain Management
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Public Health Management
                        </li>
                      </ul>
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>    
        <!-- Murray State University -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets//universities/Murray.png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
              margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 3
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Cyber Security Management (MSCSM) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Information System  (MSIS)  - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Business Administration (MBA)- Online 
                      <ul>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Accounting
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Business Analytics
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Cyber Security
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Economic Development
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Finance
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Global Communications
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Healthcare Administration
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Marketing
                        </li>
                      </ul>
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>    
        <!-- University Of South Alabama -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/universities/USA_Logo_Left_Red_Blue.jpg">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
              margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 7
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Computer Science (MSCS) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Information Systems (MSIS) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Business Administration (MBA) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Electrical & Computer Engineering (MSECE) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Mechanical Engineering (MSME) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Systems Engineering (MSSE) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Civil Coastal & Environment Engineering (MSCCEE) - On Campus 
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>    
        <!-- Purdue University Northwest -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/universities/Purdue_University_Northwest.png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
              margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 8
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Computer Science (MSCS) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Business Administration (MBA) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Excecutive Master of Business Administration (E.MBA) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Masters in Civil Engineering (MCE) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Masters in Computer Engineering (MSCE) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Masters in Electrical Computer Engineering (MSECE) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Masters in Mechanical Engineering (MME) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Biology (MSB) - On Campus 
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>    
        <!-- Hult International Bussiness School -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/universities/HULT_IBS_Logo_Outline_Black_(cropped).png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
              margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 5
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Masters in International Business (MIB) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Masters in Business Analytics (MBAN) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Masters in Finance (MFIN) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Masters in Entrepreneurship & Innovation (MEI) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Masters in Marketing & Analytics (MMAN) - On Campus 
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>    
        <!-- Westcliff  University -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/universities/westclif_logo.png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
              margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 4
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Masters In Business and Management (MBA) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Computer Science (MSCS) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Information Technology (MSIT) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Engineering Management (MSEM) - On Campus 
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>    
        <!-- University Of Louisville -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/universities/UofL-logo-cardinal-head.png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
              margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 11
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Computer Science (MSCS) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Electrical Engineering (MSEE) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Health Administration (MSHA) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Business Analytics (MSBA) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Mechanical Engineering (MSME) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Business Adminstration (MBA) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Computer Science & Engg (MCSE) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Electrical Engineering (MEE) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Engineering Management (MEM) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Public Health (MPH) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Public Administration (MPA) - On Campus 
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>    
        <!-- Liberty University -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/universities/Liberty_University_logo.svg.png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
              margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 10
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Cyber Security (MSCS) - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Information Technology (MSIT) - On Campus 
                      <ul>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                         Network Design And Security 
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Software Design And Management 
                        </li>
                      </ul>
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Business Administration (MBA) - On Campus 
                      <ul>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Finance
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Healthcare Management
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Human Resources
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Leadership
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Marketing
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Non-Profit Leadership & Management 
                        </li>
                        <li>
                          <img src="../../../../assets/images/ticker-green.svg" class="img12 mr-2">
                          Project Management
                        </li>
                      </ul>
                    </li>
                    
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>    
        <!-- Morehead University -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/universities/Morehead_State_University_logo.svg.png">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
              margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 2
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Business Administration (MBA)  - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Engineering Technology Management (MSETM)  - On Campus 
                    </li>                    
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>    
        <!-- keiser University -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/universities/keiser_fb.jpg">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
              margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 8
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Business Administration (MBA)  - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Business Administration in Technology Management (MBATM)  - On Campus 
                    </li>                    
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Business Administration in Health Services Administration (MBAHSA)  - On Campus 
                    </li>                    
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Financial Technology (MSFT)  - On Campus 
                    </li>                    
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Information Security (MSIS)  - On Campus 
                    </li>                    
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Information Technology Management (MSITM)  - On Campus 
                    </li>                    
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Information Technology Leadership (MSITL)  - On Campus 
                    </li>                    
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Industrial & Organization Psychology (MSIOP)  - On Campus 
                    </li>                    
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>    
        <!-- Florida Polytechnic University -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/universities/Florida.jpg">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
              margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 5
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Computer Science (MSCS)  - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Data Science (MSDS)  - On Campus 
                    </li>                    
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Electrical and Computer Engineering (MSECE)  - On Campus 
                    </li>                    
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Mechanical Engineering (MSME)  - On Campus 
                    </li>                    
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master Of Science in Engineering Management (MSEM)  - On Campus 
                    </li>                 
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>    
        <!-- Portland State University -->
        <mat-card appearance="outlined" class="m-l-10 u-card card" style="float:left;">
          <mat-accordion>
            <div class="p-10 text-align-center image-container">
              <img mat-card-image src="../../../../assets/universities/Portland.jpg">
            </div>
            <div>
              <mat-card-content style="padding:0!important;
              margin-bottom: 0!important;">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <img src="../../../../assets/images/courses.svg" class="img18 mr-2"> Courses Offered - 5
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ul class="course-list">
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Data Science for Business (MSDSB)  - On Campus 
                    </li>
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Public Administration (MPA)  - On Campus 
                    </li>                    
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Computer Science (MSCS)  - On Campus 
                    </li>                    
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Master of Engineering & Technology Management (MSETM)  - On Campus 
                    </li>                    
                    <li>
                      <img src="../../../../assets/images/ticker.svg" class="img12 mr-2">
                      Masters of Electrical and Computer Engineering (MECE)  - On Campus 
                    </li>                 
                  </ul>
                </mat-expansion-panel>
              </mat-card-content>
            </div>
          </mat-accordion>
        </mat-card>    
      </div>
    </mat-card-content>
  </mat-card>
</div>