import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Store, select } from '@ngrx/store';
import moment from 'moment';

import { Logout } from 'src/app/store/users/users.actions';
import {
  ILogout, ERole, Recruiter
} from 'src/app/store/model/user.model';
import { userQuery } from 'src/app/store/users/users.selectors';
import { AskComponent } from 'src/app/shared/components/ask-question/ask-question.component';
import { StudentManagementService } from 'src/app/store/student-management/student-management.service';
import { RecruiterService } from 'src/app/store/recruiter/recruiter.service';
import { ShowSuccessMsg } from 'src/app/store/app.actions';
import { SocketService } from 'src/app/shared/services/socket.service';
import { GetStudentNotification } from 'src/app/store/student-management/student-management.actions';
import { studentManagementQuery } from 'src/app/store/student-management/student-management.selectors';
import { Subscription } from 'rxjs';
import { GetAllSound, GetRecruiterAssociateNotification, GetRecruiterNotification } from 'src/app/store/recruiter/recruiter.actions';
import { recruiterQuery } from 'src/app/store/recruiter/recruiter.selectors';
import { getMessaging, onMessage } from 'firebase/messaging';
import { NotificationServiceService } from 'src/app/shared/services/notification-service.service';
import { mymessages, INotifications } from '../../layout.model';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  providers: [SocketService]
  })
export class AppHeaderComponent implements OnInit {
  public role: string;
  public university: string;
  public switchNotification = true;
  public user: any;

  public notifications: INotifications[] = [];
  public copyNotifications: INotifications[] = [];
  public mymessages = mymessages;

  public notificationCount: number;
  public isNotificationViewed: boolean;

  public isMore: boolean;
  public notificationHeight: number;

  private subscriptions: Subscription[] = [];
  private socketSubscriptions: Subscription[] = [];
  public isNotification: boolean;
  userData: any;

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private store: Store,
    private studentManagementService: StudentManagementService,
    private recruiterService: RecruiterService,
    private socketService: SocketService,
    private snackBar: MatSnackBar,
    private NotificationService: NotificationServiceService
  ) {
    this.role = localStorage.getItem('role');
    if (this.role) {
      this.isNotificationShow();
      this.socketConnection();
    }

    // this.store.pipe(select(userQuery.roleSelector)).subscribe(role => {
    //   console.log('role', role);
    //   this.role = role;
    //   this.isNotificationShow();
    //   this.socketConnection();
    // });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // this.getNotification();
      }
    });
  }

  ngOnInit(): void {
    this.notificationHeight = window.innerHeight;
    this.university = localStorage.getItem('university') || 'HUB';
    this.user = JSON.parse(localStorage.getItem('user'));
    this.store.dispatch(new GetAllSound());
    this.subscriptions.push(
      this.store.pipe(select(studentManagementQuery.studentNotification)).subscribe(response => {
        if (response) {
          this.notificationFuntion(response);
        }
      }),
      this.store.pipe(select(recruiterQuery.recruiterNotification)).subscribe(response => {
        if (response) {
          this.notificationFuntion(response);
        }
      }),
      this.store.pipe(select(recruiterQuery.recruiterAssociateNotification)).subscribe(response => {
        if (response) {
          this.notificationFuntion(response);
        }
      }),
      this.store.pipe(select(recruiterQuery.soundList)).subscribe(async response => {
        if (response) {
          const soundsList = response;
          soundsList.forEach(element => {
            if (element.id === this.user.soundId) {
              this.user.soundPath = element.soundPath;
              localStorage.setItem('user', JSON.stringify(this.user));
            }
          });
        }
      }),
    );
    this.getNotification();
    this.socketConnection();
    this.listen();
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize(event) {
    this.notificationHeight = window.innerHeight;
  }

  private socketConnection() {
    this.socketSubscriptions.forEach(temp => {
      temp.unsubscribe();
    });
    if (this.role === ERole[ERole.STUDENT]) {
      this.socketSubscriptions.push(this.socketService.getStudentNotification().subscribe((data: any) => {
        if (data) {
          this.getNotification();
        }
      }));
    } else if (this.isRecruiterRole(this.role)) {
      this.socketSubscriptions.push(this.socketService.getRecruiterNotification().subscribe((data: any) => {
        if (data) {
          this.getNotification();
        }
      }));
    } else if (this.role === ERole[ERole.ASSOCIATE]) {
      this.socketSubscriptions.push(this.socketService.getRecruiterAssociateNotification().subscribe((data: any) => {
        if (data) {
          this.getNotification();
        }
      }));
    }
  }

  private getNotification() {
    if (this.role === ERole[ERole.STUDENT]) {
      this.store.dispatch(new GetStudentNotification());
    } else if (this.isRecruiterRole(this.role) === 'RECRUITER') {
      this.store.dispatch(new GetRecruiterNotification());
    } else if (this.role === ERole[ERole.ASSOCIATE]) {
      this.store.dispatch(new GetRecruiterAssociateNotification());
    }
  }

  public showPopup() {
    const dialogRef = this.dialog.open(AskComponent, {
      width: '50%',
      height: '82%'
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  public onSwitchNotification(event: MatSlideToggleChange) {
    if (this.role === ERole[ERole.STUDENT]) {
      this.studentManagementService.switchNotificationFromService(event.checked).subscribe((response) => {
        this.store.dispatch(new ShowSuccessMsg(response?.message));
      });
    } else if (Recruiter(this.role) === 'RECRUITER') {
      this.recruiterService.switchNotificationFromService(event.checked).subscribe((response) => {
        this.store.dispatch(new ShowSuccessMsg(response?.message));
      });
    } else if (this.role === ERole[ERole.ASSOCIATE]) {
      this.recruiterService.switchAssociateNotificationFromService(event.checked).subscribe((response) => {
        this.store.dispatch(new ShowSuccessMsg(response?.message));
      });
    }
  }

  public onClickLogout() {
    const userStored = JSON.parse(localStorage.getItem('user'));
    if (userStored) {
      const logoutRequest: ILogout = {
        refreshToken: userStored.refreshToken,
        userId: userStored.userId,
        roleId: userStored.roleId
      };
      this.store.dispatch(new Logout(localStorage.getItem('role'), logoutRequest));
      if (localStorage.getItem('temp_role') && (localStorage.getItem('temp_role') === 'STUDENT')) {
        this.router.navigateByUrl('/authentication/login');
      } else if (localStorage.getItem('temp_role') && (localStorage.getItem('temp_role') === 'RECRUITER' || localStorage.getItem('temp_role') === 'RECRUITER_FREELANCER' || localStorage.getItem('temp_role') === 'RECRUITER_ORGANIZATION')) {
        this.router.navigateByUrl('/authentication/recruiter/login');
      }
    } else {
      // this.router.navigateByUrl('/authentication/login');
      // eslint-disable-next-line no-lonely-if
      if (localStorage.getItem('loginRole') && (localStorage.getItem('loginRole') === 'STUDENT')) {
        this.router.navigateByUrl('/authentication/login');
      } else if (localStorage.getItem('loginRole') && (localStorage.getItem('loginRole') === 'RECRUITER')) {
        this.router.navigateByUrl('/recruiter');
      }
    }
  }

  public onClickViewNotification() {
    if (!this.isNotificationViewed) {
      if (this.role === ERole[ERole.STUDENT]) {
        const requestBody = {
          studentSalesforceId: this.user.salesforceId
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.studentManagementService.updateNotificaionFromService(requestBody).subscribe((response) => {
          this.getNotification();
        });
      } else if (this.isRecruiterRole(this.role)) {
        const requestBody = {
          recruiterSalesforceId: this.user.salesforceId
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.recruiterService.updateNotificaionFromService(requestBody).subscribe((response) => {
          this.getNotification();
        });
      } else if (this.role === ERole[ERole.ASSOCIATE]) {
        const requestBody = {
          staffSalesforceId: this.user.salesforceId
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.recruiterService.updateNotificaionFromService(requestBody).subscribe((response) => {
          this.getNotification();
        });
      }
    }
  }

  public getNotificationIcon(key) {
    switch (key) {
      case 'proposedUniversity':
        return 'school';

      case 'Modification':
        return 'school';

      case 'application':
        return 'school';

      case 'payment':
        return 'payment';

      case 'document':
        return 'description';

      case 'profile':
        return 'account_box';

      case 'additionalDocument':
        return 'description';

      case 'Commission':
        return 'policy';

      case 'Terms & Conditions':
        return 'question_answer';

      case 'Comment Added':
        return 'forum';

      case 'CRM Lead Assignment':
        return 'how_to_reg';

      case 'CRM Lead Status':
        return 'cached';

      case 'Document Upload':
        return 'book';

      default:
        break;
    }
  }

  public onClickShowMore() {
    this.isMore = !this.isMore;
    if (this.isMore) {
      this.notifications = this.copyNotifications.slice(0);
    } else {
      this.notifications = this.copyNotifications.slice(0, 4);
    }
  }

  public onClosedNotificationMenu() {
    this.isMore = false;
    this.notifications = this.copyNotifications.slice(0, 4);
  }

  public isRecruiterRole(role: string) {
    if (
      role === ERole[ERole.RECRUITER_FREELANCER]
      || role === ERole[ERole.RECRUITER_ORGANIZATION]
      || role === ERole[ERole.RECRUITER_MASTER]
      || role === 'RECRUITER'
    ) {
      return 'RECRUITER';
    }
    if (role === 'STUDENT') {
      return 'STUDENT';
    }
    return null;
  }

  private notificationFuntion(response) {
    if (response) {
      this.notificationCount = response.notifications.filter(temp => temp.isViewed === 0).length;
      if (this.notificationCount === 0) {
        this.isNotificationViewed = true;
      } else {
        if (response.profile.enableNotification) {
          this.snackBar.open(response.notifications.filter(temp => temp.isViewed === 0)[0].message, '', {
            duration: 2000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
          });
        }
        this.isNotificationViewed = false;
      }
    }
    this.switchNotification = response.profile.enableNotification;
    this.isMore = false;
    this.notifications = [];
    this.copyNotifications = [];
    if (response && response.notifications.length > 0) {
      for (const i of response.notifications) {
        this.copyNotifications.push({
          subject: i.subject === 'proposedUniversity' ? 'Proposed University' : i.subject,
          message: i.message,
          time: moment.utc(i.createdDate).fromNow(),
          icon: this.getNotificationIcon(i.subject),
          round: 'round-primary',
          redirectUrl: i.redirectUrl,
          studentSalesforceId: i.studentSalesforceId || ''
        });
      }
      this.notifications = this.copyNotifications.slice(0, 4);
    }
  }

  public isNotificationShow() {
    if (
      this.role === ERole[ERole.RECRUITER_FREELANCER]
      || this.role === ERole[ERole.RECRUITER_ORGANIZATION]
      || this.role === ERole[ERole.RECRUITER_MASTER]
      || this.role === ERole[ERole.STUDENT]
      || this.role === 'RECRUITER'
      || this.role === ERole[ERole.ASSOCIATE]
    ) {
      this.isNotification = true;
      return true;
    }
    this.isNotification = false;
    return false;
  }
  public listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload: any) => {
      this.userData = JSON.parse(localStorage.getItem('user'));
      this.getNotification();
      if (this.userData.notification_subscribe === true) {
        console.log('FCM PAYLOAD 2');
        console.log(payload.data);
        this.NotificationService.success(payload.data.Title, payload.data);
      }
    });
  }

  notificationSettings() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive'
    };
    const dialogRef = this.dialog.open(NotificationSettingsComponent, config);
    dialogRef.afterClosed().subscribe(() => {
    });
  }
}
