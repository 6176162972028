export interface IStaffSession {
  accessToken?: string;
  refreshToken: string;
  role: string;
  roleId: number;
  tokenId: number;
  userId: number;
}

export enum EStaffEndPoint {
  LOGIN_STAFF_ASSOCIATE = 'session/loginRecruiterOrAssociate',
}
