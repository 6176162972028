/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SharedService } from '../../services/shared.service';
import { CountryStateCityService } from '../../services/country-state-city.service';

export interface Identifiable {
  id: string | number;
  name: string;
}

@Component({
  selector: 'app-auto-complete-program',
  templateUrl: './auto-complete-program.component.html',
  styleUrls: ['./auto-complete-program.component.scss']
  })
export class AutoCompleteProgramComponent implements OnInit, ControlValueAccessor, OnChanges, OnDestroy {
  @Input() placeholder: string;
  @Input() requiredMessage: string;
  @Input() programMessage: string;
  @Input() options: Identifiable[];
  @Input() fullArrayOption: any[];
  @Input() disabled: string;
  @Input() submitClicked: boolean;
  @Input() currentForm: any;
  @Output() optionSelected = new EventEmitter();
  fullProgramArray = this.sharedService.allUniPrograms();
  fullProgramArrayOth = this.sharedService.allUniPrograms();
  fullUniversityArray = this.sharedService.allUniversity();
  programVal: ValidatorFn = (control: FormControl) => {
    if (this.programMessage !== 'null') {
      const programArray: unknown[] = [];
      for (let i = 0; i < this.fullProgramArray.length; i++) {
        const inputValue = this.fullProgramArray[i].name.toLowerCase();
        programArray.push(inputValue);
      }
      if (control.value !== null) {
        const indexOf = programArray.indexOf(control.value.toLowerCase());
        if (indexOf === -1) {
          return {
            program: true
          };
        }
      }
      return null;
    }
    return null;
  };

  // Inner form control to link input text changes to mat autocomplete
  inputControl = new FormControl('', [this.validators, this.programVal]);
  searchResults: Observable<any>;
  noResults = false;
  isSearching = false;
  public isFullArrayOption = false;
  public onTouched = () => { };

  private _lengthToTriggerSearch = 0;

  @Input()
  set lengthToTriggerSearch(value: number) {
    this._lengthToTriggerSearch = coerceNumberProperty(value, 0);
  }

  constructor(
    @Optional() @Self() private controlDir: NgControl,
    private changeDetectorRef: ChangeDetectorRef,
    private sharedService: SharedService,
    private countryStateCityService: CountryStateCityService
  ) {
    if (this.controlDir) {
      this.controlDir.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.sharedService.isFullArrayOptionObservable.subscribe(result => {
      this.isFullArrayOption = result;
    });
    if (this.controlDir) {
      // Set validators for the outer ngControl equals to the inner
      if (this.requiredMessage !== 'null') {
        const { control } = this.controlDir;
        const validators = control.validator
          ? [control.validator, this.inputControl.validator]
          : this.inputControl.validator;
        control.setValidators(validators);
        control.updateOn;
        // Update outer ngControl status
        control.updateValueAndValidity({ emitEvent: false });
      }
    }
    if (this.requiredMessage === 'null') {
      this.inputControl.clearValidators();
      this.inputControl.updateValueAndValidity();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      if (this.isSearching) {
        this.isSearching = false;
        this.isFullArrayOption = false;
        if (!changes.options.firstChange && !changes.options.currentValue.length) {
          this.noResults = true;
        } else {
          this.noResults = false;
        }
      }
    }
    if (changes.submitClicked) {
      this.submitClicked = changes.submitClicked.currentValue;
      if (this.submitClicked == true) {
        this.inputControl.markAllAsTouched();
      }
    }
    if (changes.fullArrayOption) {
      this.fullArrayOption.forEach(x => delete x.disable);
      const currentUniversity = this.fullUniversityArray.findIndex(x => x.id === this.fullArrayOption[0]?.universityId);
      this.currentForm.map(x => {
        if (x.value.program !== '') {
          // currentPrograms.push(x);
          const index = this.fullArrayOption.findIndex((y: any) => y.name === x.value.program && x.value.name.toLowerCase() === this.fullUniversityArray[currentUniversity].name.toLowerCase());
          if (index > -1) {
            this.fullArrayOption[index].disable = true;
          }
        }
      });
    }
  }

  /**
   * Allows Angular to update the inputControl.
   * Update the model and changes needed for the view here.
   */
  writeValue(obj: any): void {
    obj ? obj && this.inputControl.setValue(obj) : this.inputControl.reset();
  }

  /**
   * Allows Angular to register a function to call when the inputControl changes.
   */
  registerOnChange(fn: any): void {
    // Pass the value to the outer ngControl if it has an id otherwise pass null
    this.inputControl.valueChanges.pipe(debounceTime(300)).subscribe({
      next: value => {
        if (typeof value === 'string') {
          if (this.isMinLength(value)) {
            this.isSearching = true;
            /**
             * Fire change detection to display the searching status option
             */
            this.changeDetectorRef.detectChanges();
            fn(value.toUpperCase());
          } else {
            this.isSearching = false;
            this.noResults = false;
            fn(null);
          }
        } else {
          fn(value);
        }
      },
    });
  }

  /**
   * Allows Angular to register a function to call when the input has been touched.
   * Save the function as a property to call later here.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onFocusOut() {
    if (!this.options.some(temp => temp.name.includes(this.inputControl.value))) {
      this.inputControl.reset();
    }
  }

  /**
   * Allows Angular to disable the input.
   */
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  /**
   * Method linked to the mat-autocomplete `[displayWith]` input.
   * This is how result name is printed in the input box.
   */
  displayFn(result: string): string | undefined {
    return result || undefined;
  }

  onSelectOption(event: any) {
    this.optionSelected.emit(event);
  }

  isMinLength(value: string) {
    return value.length >= this._lengthToTriggerSearch;
  }

  private get validators(): ValidatorFn {
    return Validators.required;
  }

  private get countryValidators(): ValidatorFn {
    return Validators.requiredTrue;
  }

  ngOnDestroy() {
    if (this.optionSelected) {
      this.optionSelected.unsubscribe();
    }
  }
}
