import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upload-confirm',
  templateUrl: './upload-confirm.component.html',
  styleUrls: ['./upload-confirm.component.scss']
  })
export class UploadConfirmComponent implements OnInit {
  salesforceId: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,) {

  }
  ngOnInit(): void {
    this.salesforceId = this.data.salesforceId;
  }
  goToOverview() {
    this.router.navigate([`/student-management/student_list/${this.salesforceId}`]);
  }
}
